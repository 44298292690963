import React, { Fragment } from "react";

const AntdLoader = () => {
    return (
        <Fragment>
            <div className="spinner-border" role="status">
                {/* <span className="visually-hidden">Loading...</span> */}
            </div>
        </Fragment>
    );
};

export default AntdLoader;

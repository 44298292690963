import { getRequest } from '../../../utils/apiUtils';

export const getNotificationData = (id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`/admin/schedule/${id}`);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

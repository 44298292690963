import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  deleteFinancialYearRentSummary,
  getFinancialYearEntryData,
  getFinancialYearRentSummaryCompletedList,
  getFinancialYearRentSummaryPendingList,
  saveAndUpdateFinancialYearRentSummary,
} from '../../../actions/FYRentSummary/FYRentSummaryAPi';

const initialState = {
  error: false,
  isLoading: true,
  pendingList: [],
  completedList: [],
  data: {},
};

export const saveAndUpdateFinancialYearRentSummaryAsync = createAsyncThunk('FYRentSummary/saveAndUpdateFinancialYearRentSummary', async ({id, body}) => {
  const response = await saveAndUpdateFinancialYearRentSummary(id, body);
  return response;
});

export const getFinancialYearRentSummaryPendingListAsync = createAsyncThunk('FYRentSummary/getFinancialYearRentSummaryPendingList', async (filters) => {
  const response = await getFinancialYearRentSummaryPendingList(filters);
  return response;
});

export const getFinancialYearEntryDataAsync = createAsyncThunk('FYRentSummary/getFinancialYearEntryData', async (id) => {
  const response = await getFinancialYearEntryData(id);
  return response;
});

export const getFinancialYearRentSummaryCompletedListAsync = createAsyncThunk('FYRentSummary/getFinancialYearRentSummaryCompletedList', async (filters) => {
  const response = await getFinancialYearRentSummaryCompletedList(filters);
  return response;
});

export const deleteFinancialYearRentSummaryAsync = createAsyncThunk('FYRentSummary/deleteFinancialYearRentSummary', async (id) => {
  const response = await deleteFinancialYearRentSummary(id);
  return response;
});

export const FinancialYearRentSummarySlice = createSlice({
  name: 'FinancialYearRentSummary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveAndUpdateFinancialYearRentSummaryAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveAndUpdateFinancialYearRentSummaryAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(saveAndUpdateFinancialYearRentSummaryAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getFinancialYearEntryDataAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFinancialYearEntryDataAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.error = false;
        state.isLoading = false;
      })
      .addCase(getFinancialYearEntryDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getFinancialYearRentSummaryPendingListAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFinancialYearRentSummaryPendingListAsync.fulfilled, (state, action) => {
        state.pendingList = action.payload.data;
        state.error = false;
        state.isLoading = false;
      })
      .addCase(getFinancialYearRentSummaryPendingListAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getFinancialYearRentSummaryCompletedListAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFinancialYearRentSummaryCompletedListAsync.fulfilled, (state, action) => {
        state.completedList = action.payload.data;
        state.error = false;
        state.isLoading = false;
      })
      .addCase(getFinancialYearRentSummaryCompletedListAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(deleteFinancialYearRentSummaryAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFinancialYearRentSummaryAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(deleteFinancialYearRentSummaryAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectFYPendingList = (state) => state.fyRentSummary.pendingList;
export const selectFYCompletedList = (state) => state.fyRentSummary.completedList;
export const selectFYLoading = (state) => state.fyRentSummary.isLoading;
export const selectFYEntryData = (state) => state.fyRentSummary.data;

export default FinancialYearRentSummarySlice.reducer;

import { putRequest } from '../../../utils/apiUtils';
import { updatePropertyValuationFailure, updatePropertyValuationSuccess } from '../../reducers/slices/Valuation/updatePropertySlice';

export const UpdatePropertyValuation = ({ id, data }) => {
  return async (dispatch) => {
    const { result, error } = await putRequest(`/admin/propertyvalue/${id}`, data);

    if (!error) {
      return dispatch(updatePropertyValuationSuccess(result));
    }
    return dispatch(updatePropertyValuationFailure(error));
  };
};

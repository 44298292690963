import { getRequest } from '../../../utils/apiUtils';
import { getRentalSummaryByIdFailure, getRentalSummaryByIdRequest, getRentalSummaryByIdSuccess, getRentalSummaryByIdReset } from '../../reducers/slices/RentSummary/getRentalSummaryById.slice';

export const GetRentalSummaryById = (id, id1) => {
  return async (dispatch) => {
    let tempURl = ``;
    if (!id1) {
      tempURl = `user/rent-summary/${id}`;
    } else {
      tempURl = `user/rent-summary/${id}?userId=${id1}`;
    }
    await dispatch(getRentalSummaryByIdRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getRentalSummaryByIdSuccess(result));
    }
    return await dispatch(getRentalSummaryByIdFailure(result));
  };
};

export const ResetRentalSummaryTransection = () => {
  return async (dispatch) => {
    return await dispatch(getRentalSummaryByIdReset());
  };
};

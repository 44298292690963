import { createSlice } from '@reduxjs/toolkit';

const initialState = { settlementDatacomplete: [], error: false, isLoading: true };

export const CompleteRentSummarySlice = createSlice({
  name: 'CompleteRentSummary',
  initialState,
  reducers: {
    CompleteRentSummarySuccess: (state, action) => {
      state.settlementDatacomplete = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    CompleteRentSummaryFailure: (state, action) => {
      state.settlementDatacomplete = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { CompleteRentSummarySuccess, CompleteRentSummaryFailure } = CompleteRentSummarySlice.actions;

export default CompleteRentSummarySlice.reducer;

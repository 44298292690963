import { createSlice } from '@reduxjs/toolkit';

const initialState = { settlementDatacomplete: [], error: false, isLoading: true };

export const getUsersListSlice = createSlice({
  name: 'UsersList',
  initialState,
  reducers: {
    getUsersListSuccess: (state, action) => {
      state.settlementDatacomplete = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getUsersListFailure: (state, action) => {
      state.settlementDatacomplete = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getUsersListSuccess, getUsersListFailure } = getUsersListSlice.actions;

export default getUsersListSlice.reducer;

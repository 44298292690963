import { postRequest } from '../../../utils/apiUtils';

export const fetchMortgageBrokers = (filters) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`admin/mortgage-broker`, filters);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

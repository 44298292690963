import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getUserActivitiesListSlice = createSlice({
  name: 'UserActivitiesList',
  initialState,
  reducers: {
    getUserActivitiesListSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getUserActivitiesListFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getUserActivitiesListSuccess, getUserActivitiesListFailure } = getUserActivitiesListSlice.actions;

export default getUserActivitiesListSlice.reducer;

import { Button, DatePicker, Form, Input, Spin, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { ReloadOutlined } from '@ant-design/icons';
import { getSubscriptionHistoryAsync, selectSubscriptionHistory, selectSubscriptionHistoryLoading } from '../../container/reducers/slices/Subscription/SubscriptionHistorySlice';
import { GetInvoiceReceipt } from '../../container/actions/Subscription/SubscriptionHistoryApi';
import { debounce } from 'lodash';
import { getUserTypeLabel } from '../../utils/ConstLabel';

const SubscriptionHistory = () => {
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
    userSearchSlug: null,
  });

  const [dataLoading, setDataLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });

  const [isLoaded, setIsLoaded] = useState(false); // Flag to indicate states are loaded from localStorage

  const loading = useSelector(selectSubscriptionHistoryLoading);
  const data = useSelector(selectSubscriptionHistory);

  // Load filter and pagination from localStorage on mount
  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem('subscriptionHistoryFilter'));
    const storedPagination = JSON.parse(localStorage.getItem('subscriptionHistoryPagination'));

    if (storedFilter) {
      filterForm.setFieldsValue({
        startDate: storedFilter.startDate ? dayjs(storedFilter.startDate) : null,
        endDate: storedFilter.endDate ? dayjs(storedFilter.endDate) : null,
        searchUser: storedFilter.userSearchSlug || '',
      });
      setFilter(storedFilter);
    }

    if (storedPagination) {
      setPagination(storedPagination);
    }

    // After states are loaded from localStorage, set isLoaded to true
    setIsLoaded(true);
  }, [filterForm]);

  // Save filter to localStorage whenever it changes
  useEffect(() => {
    if (filter !== null) {
      localStorage.setItem('subscriptionHistoryFilter', JSON.stringify(filter));
    }
  }, [filter]);

  // Save pagination state to localStorage whenever it changes
  useEffect(() => {
    if (pagination !== null) {
      localStorage.setItem('subscriptionHistoryPagination', JSON.stringify(pagination));
    }
  }, [pagination]);

  const startDateChange = (value) => {
    if (value === null) {
      setPagination({ ...pagination, current: 1 });
      setFilter({ ...filter, startDate: null });
      return;
    }

    if (value && dayjs(value).isValid() && filter.endDate && dayjs(filter.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be less than End Date.',
      });
      filterForm.setFieldsValue({ startDate: null });
      return;
    }

    const newStartDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, startDate: newStartDate });
  };

  const endDateChange = (value) => {
    if (value === null) {
      setPagination({ ...pagination, current: 1 });
      setFilter({ ...filter, endDate: null });
      return;
    }

    if (value && dayjs(value).isValid() && filter.startDate && dayjs(filter.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'End Date should be greater than Start Date.',
      });
      filterForm.setFieldsValue({ endDate: null });
      return;
    }

    const newEndDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, endDate: newEndDate });
  };

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({ startDate: null, endDate: null, userSearchSlug: null });
    setPagination({ ...pagination, current: 1 });
  };

  const columns = [
    {
      title: 'Purchased By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (text, record) => {
        return `${text} ( ${getUserTypeLabel(record?.created_by_user_type)} )`;
      },
    },
    {
      title: 'User Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Subscription Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => dayjs(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Total Properties',
      dataIndex: 'property_ids',
      key: 'property_ids',
      render: (text) => JSON?.parse(text)?.length,
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (text) => `$${text}`,
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice',
      key: 'invoice',
      render: (text, record) => {
        return (
          <Button type="primary" onClick={() => getInvoiceReceipt(record.stripe_subscription_id, record.user_id)}>
            View Invoice
          </Button>
        );
      },
    },
  ];

  // Call API only after isLoaded is true, ensuring localStorage states have been applied
  useEffect(() => {
    if (isLoaded) {
      const params = {
        ...filter,
        skip: (Number(pagination.current) - 1) * pagination.pageSize,
        limit: pagination.pageSize,
      };
      dispatch(getSubscriptionHistoryAsync(params));
    }
  }, [dispatch, filter, pagination, isLoaded]);

  const getInvoiceReceipt = async (id, userId) => {
    try {
      setDataLoading(true);
      const { result, error } = await GetInvoiceReceipt(id, userId);

      if (!error && result.success === true) {
        window.open(result.result.receipt_path, '_blank');
      } else {
        notification.error({
          message: 'Failure',
          description: 'Failed to get invoice receipt.',
        });
      }
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
      console.error('Error occurred while fetching signed URL:', error);
    }
  };

  const UserSearchSlug = debounce((e) => {
    const values = e.target.value.trim();
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, userSearchSlug: values });
  }, 300);

  return (
    <>
      <div className="d-flex gap-3 flex-sm-column loans-header-responsive flex-md-row flex-lg-row justify-content-between align-items-center">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Subscription History</h5>
          </div>
        </div>
        <div className="reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex loans-header-responsive align-items-center gap-2 justify-content-end">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="searchUser" className="m-0">
                    <Input size="large" allowClear onChange={(e) => UserSearchSlug(e)} style={{ width: '100%', background: 'transparent' }} placeholder="Search User Email" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Button type="primary" icon={<ReloadOutlined />} size="large" className="reset-width-100 d-flex align-items-center" onClick={() => resetFilter()}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={dataLoading}>
        <div className="mt-3">
          <div className="row mt-4">
            <div className="col-12">
              <div className="dashboard_list">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={data?.data || []}
                  pagination={{ ...pagination, total: data?.count }}
                  onChange={handleTableChange}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default SubscriptionHistory;

import { isNull, isString, isUndefined } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import localStore from 'store2';
import JSURL from 'jsurl';
import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

export const isValidArray = (arr) => {
  return arr && Array.isArray(arr) && arr.length > 0;
};

export const getValidDataFromResponse = (response) => {
  try {
    if (isValidObject(response, ['success', 'message', 'result'])) {
      const { success, message, result } = response;
      if (success) {
        SendNotification({ type: 'success', message: message });
        return { data: result, hasError: !success };
      }
      return { data: [], hasError: true };
    }
  } catch (error) {}
};

export function formatNumber(num) {
  if (num >= 1_000_000_000) {
    return '$' + (num / 1_000_000_000).toFixed(1) + ' B';
  } else if (num >= 1_000_000) {
    return '$' + (num / 1_000_000).toFixed(1) + ' M';
  } else if (num >= 1_000) {
    return '$' + (num / 1_000).toFixed(1) + ' K';
  } else {
    return '$' + num?.toString();
  }
}

export const isValidObject = (obj, keys) => {
  if (isValidArray(keys)) {
    const newObjKeys = isValidObject(obj, []) ? Object.keys(obj) : [];

    if (!isValidArray(newObjKeys)) {
      return false;
    }

    let isValid = keys.length;
    keys.forEach((key) => {
      if (newObjKeys.includes(key)) {
        isValid -= 1;
      }
    });
    return isValid === 0;
  }
  return obj && Object.keys(obj).length > 0;
};

export const getAllRequiredHeaders = () => {
  // const user = localStorage.getItem("is_admin")

  // const uid = localStore.get('uid') || "";

  return {
    // "Authorization": `Bearer ${token}`,
    user: 1,
    // "X-Authorization": uid
  };
};

export const setLocalStorageItems = ({ set = false, setAll = false, item = {}, items = {}, skipKey = [] }) => {
  if (set) {
    localStorage.setItem(item.key, item.value);
  } else if (setAll) {
    for (const key in items) {
      if (skipKey.includes(key)) continue;
      localStorage.setItem(key, JSON.stringify(items[key]));
    }
  }
};

export const getLocalStorageItems = ({ get = false, getAll = false, key = null, keys = {} }) => {
  if (get) {
    const value = localStore.get(key);
    return { [key]: value };
  } else if (getAll) {
    return localStore.getAll(keys);
  }
  return null;
};

export const SendNotification = ({ type, message, duration }) => {
  let options = {
    autoClose: 3000 || duration,
  };
  if (type === 'success') {
    toast.success(message, options);
  } else if (type === 'error') {
    toast.error(message, options);
  } else if (type === 'warning') {
    toast.warn(message, options);
  }
};

export const useQueryParam = (key) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let paramValue = searchParams.get(key);

  let value = useMemo(() => JSURL.parse(JSURL.stringify(paramValue)), [paramValue]);

  let setValue = useCallback(
    (newValue) => {
      let newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(key, JSURL.stringify(newValue));
      setSearchParams(newSearchParams);
    },
    [key, searchParams, setSearchParams],
  );

  return [value, setValue];
};

export const isValidStoredKey = (keys = []) => {
  let isValidKey = true;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (localStore.has(key)) {
      const value = localStore.get(key);
      if (isUndefined(value) && isNull(value)) {
        isValidKey = false;
        break;
      } else if (isString(value) && value.length === 0) {
        isValidKey = false;
        break;
      }
    } else {
      isValidKey = false;
      break;
    }
  }

  return isValidKey;
};

export const formateDate = (date) => {
  return dayjs(date).format('ddd MMM D YYYY');
};

export const onlyNumber = ({ e, max = 10 }) => {
  let validIntegers = [46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
  let decimal = e.target.value.split('.');

  if (!validIntegers.includes(e.charCode)) {
    e.preventDefault();
  }

  if (e.target.value.length >= max) {
    e.preventDefault();
  }

  if ((decimal.length >= 2 || e.target.value.length === 0) && e.charCode === 46) {
    e.preventDefault();
  }
};

export const formatNumberToCurrency = (number, currency = 'AUD') => {
  return new Intl.NumberFormat('en-AU', { style: 'currency', currency: currency }).format(number);
};

import { postRequest } from '../../../utils/apiUtils';
import { GetTaxAccountantListFailure, GetTaxAccountantListSuccess } from '../../reducers/slices/Users/getTaxaccountantSlice';

export const GetTaxAccountantList = (data) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`admin/tax-accountant`, data);
    if (!error) {
      return dispatch(GetTaxAccountantListSuccess(result));
    }
    return dispatch(GetTaxAccountantListFailure(result));
  };
};

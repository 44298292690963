import { getRequest } from '../../../utils/apiUtils';

// get lock list
export const getLockYearsList = (user_id) => {
  let tempURl = ``;
  if (!user_id) {
    tempURl = `user/audit-trail/lock/list`;
  } else {
    tempURl = `user/audit-trail/lock/list?userId=${user_id}`;
  }

  return async () => {
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return result;
    }
    return error;
  };
};

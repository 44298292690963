import { DatePicker, Form, Input, Spin, Table, notification, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetTaxAccountantList } from '../../container/actions/Users/getTaxaccountant.action';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const UserTaxAccountant = () => {
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultFilter = {
    startDate: '',
    endDate: '',
    searchSlug: '',
  };

  const [filters, setFilters] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });
  const [total, setTotal] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [taxAccountData, setTaxAccountData] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // Initialize filters and pagination from localStorage
  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem('userTaxAccountantFilter'));
    const storedPagination = JSON.parse(localStorage.getItem('userTaxAccountantPagination'));

    if (storedFilter) {
      filterForm.setFieldsValue({
        Search: storedFilter.searchSlug || '',
        startDate: storedFilter.startDate ? dayjs(storedFilter.startDate) : null,
        endDate: storedFilter.endDate ? dayjs(storedFilter.endDate) : null,
      });
      setFilters(storedFilter);
      setInputValue(storedFilter.searchSlug || '');
    } else {
      setFilters(defaultFilter);
    }

    if (storedPagination) {
      setPagination(storedPagination);
    }
  }, [filterForm]);

  // Fetch data when filters or pagination change
  useEffect(() => {
    if (filters !== null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination, dispatch]);

  // Update localStorage when filters change
  useEffect(() => {
    if (filters !== null) {
      localStorage.setItem('userTaxAccountantFilter', JSON.stringify(filters));
    }
  }, [filters]);

  // Update localStorage when pagination changes
  useEffect(() => {
    if (pagination !== null) {
      localStorage.setItem('userTaxAccountantPagination', JSON.stringify(pagination));
    }
  }, [pagination]);

  const fetchData = async () => {
    setLoading(true);
    const params = {
      ...filters,
      skip: (Number(pagination.current) - 1) * pagination.pageSize,
      limit: pagination.pageSize,
    };

    await dispatch(GetTaxAccountantList(params)).then((res) => {
      if (res?.payload?.success === true) {
        setTaxAccountData(res.payload.result.users);
        setTotal(res.payload.result.count);
      } else {
        notification.error({
          message: 'Failure',
          description: 'Something went wrong!',
        });
      }
      setLoading(false);
    });
  };

  const startDateChange = (value) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    if (value === null) {
      setFilters({ ...filters, startDate: '' });
      return;
    }

    if (value && dayjs(value).isValid() && filters.endDate && dayjs(filters.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be less than End Date.',
      });
      filterForm.setFieldsValue({ startDate: null });
      return;
    }

    const newStartDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setFilters({ ...filters, startDate: newStartDate });
  };

  const endDateChange = (value) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    if (value === null) {
      setFilters({ ...filters, endDate: '' });
      return;
    }

    if (value && dayjs(value).isValid() && filters.startDate && dayjs(filters.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'End Date should be greater than Start Date.',
      });
      filterForm.setFieldsValue({ endDate: null });
      return;
    }

    const newEndDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setFilters({ ...filters, endDate: newEndDate });
  };

  const SearchSlug = debounce((e) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setInputValue(e.target.value);
    const values = e.target.value.trim();
    setFilters({ ...filters, searchSlug: values });
  }, 300);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    // localStorage will be updated via useEffect
  };

  const handleView = (record) => {
    navigate(`/user/tax-accountant/detail/${record.id}`);
  };

  const TaxAccountantColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span className="userColumnName" onClick={() => handleView(record)}>
          {text}
        </span>
      ),
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (_, record) => {
        return record?.company_name ? record.company_name : '-';
      },
    },
    {
      title: 'Registered Clients',
      dataIndex: 'registered_clients_count',
      key: 'registered_clients_count',
    },
    {
      title: 'Managed Clients',
      dataIndex: 'managed_clients_count',
      key: 'managed_clients_count',
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilters(defaultFilter);
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setInputValue('');
    // localStorage will be updated via useEffect
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Tax Accountants</h5>
          </div>
        </div>
        <div className="date_picker reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex  loans-header-responsive justify-content-between align-items-center gap-3">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={SearchSlug} value={inputValue} style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={Loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table
                columns={TaxAccountantColumn}
                pagination={{ ...pagination, total: total, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
                dataSource={taxAccountData}
                scroll={{ x: 'max-content' }}
                onChange={handleTableChange}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default UserTaxAccountant;

import { getRequest } from '../../../utils/apiUtils';
import { depreciationCompleteByIdFailure, depreciationCompleteByIdSuccess, resetDepreciationCompleteById } from '../../reducers/slices/Depreciation/depreciationCompleteByIdSlice';

export const GetCompleteDepreciationById = (id) => {
  return async (dispatch) => {
    dispatch(resetDepreciationCompleteById());
    const { result, error } = await getRequest(`admin/fetch-depreciation/complete/${id}`);

    if (!error) {
      return dispatch(depreciationCompleteByIdSuccess(result));
    }
    return dispatch(depreciationCompleteByIdFailure(result));
  };
};

import { getRequest } from '../../../utils/apiUtils';
import { checkDepreciationFailure, checkDepreciationSuccess } from '../../reducers/slices/Depreciation/checkDepreciation.slice';

export const checkDepreciation = (id) => {
  return async (dispatch) => {
    const { result, error } = await getRequest(`fetch-depreciation-by-pid/${id}`);

    if (!error) {
      return dispatch(checkDepreciationSuccess(result));
    }
    return dispatch(checkDepreciationFailure(error));
  };
};

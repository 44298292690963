import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true}

export const updateDepreciationSlice = createSlice({
    name: 'updateDepreciation',
    initialState,
    reducers: {
        updateDepreciationSuccess: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        updateDepreciationFailure: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
            state.error = true;
        }
    }

})

export const { updateDepreciationSuccess, updateDepreciationFailure } = updateDepreciationSlice.actions;

export default updateDepreciationSlice.reducer;
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dropdown, Form, Input, Modal, Select, Spin, Switch, Upload, message, notification } from 'antd';
import { CloseCircleOutlined, DeleteOutlined, PlusCircleOutlined, SmileOutlined, UploadOutlined } from '@ant-design/icons';
import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import ImageResize from 'quill-image-resize-module-react';
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import 'react-quill/dist/quill.snow.css';
import { STSToken } from '../../container/actions/UploadFile/UploadFileAction';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationCategory, NotificationType } from '../../components/common/userTypes';
import { editTemplateAsync, getTemplateListAsync, saveTemplateAsync, selectEmailTemplates, selectTemplateLoading } from '../../container/reducers/slices/Notification/NotificationTemplateSlice';
import Picker from 'emoji-picker-react';
import PreviewTemplate from './PreviewTemplate';
import './Notification.css';
import DOMPurify from 'isomorphic-dompurify';

Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/imageResize', ImageResize);

const categoryOptions = [
  { value: '--Select Category--', label: '--Select Category--' },
  { value: 'Rental', label: 'Rental' },
  { value: 'Settlement', label: 'Settlement' },
  { value: 'Depreciation', label: 'Depreciation' },
  { value: 'Bank', label: 'Bank' },
  { value: 'General', label: 'General' },
  { value: 'Subscription', label: 'Subscription' },
];

const smsMaxLength = 320;
const pushNotificationMaxLength = 240;

const AddTemplate = ({ open, setOpen, record, setRecord, type, setType, isEdited, setIsEdited, notificationComponent }) => {
  const isEmail = type === 'email';
  const isSms = type === 'sms';
  const isPushNotification = type === 'pushNotification';

  const MAX_LENGTH = isSms ? smsMaxLength : pushNotificationMaxLength;

  const key = isEmail ? NotificationType.EMAIL : isPushNotification ? NotificationType.PUSH_NOTIFICATION : isSms ? NotificationType.SMS : null;

  const [fileList, setFileList] = useState([]);

  const [textareaValue, setTextareaValue] = useState('');

  const [id, setId] = useState(null);
  const [checked, setChecked] = useState(true);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [showPicker, setShowPicker] = useState(false);

  const emailTemplates = useSelector(selectEmailTemplates);
  const submitLoading = useSelector(selectTemplateLoading);

  const modalTitle = isEmail ? 'Email Template' : isPushNotification ? 'Push Notification Template' : 'SMS Template';

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const pickerRef = useRef(null);
  const reactQuillRef = useRef(null);
  const buttonRef = useRef(null);
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (isEmail) {
      setId(record?.id);
      form.setFieldsValue({
        name: record?.name,
        category: Object.keys(NotificationCategory).find((key) => NotificationCategory[key] === record?.category),
        is_active: record?.is_active === 0 ? false : true,
        subject: record?.subject,
        content: record?.content,
      });
      setChecked(record?.is_active !== 0);

      if (reactQuillRef?.current && typeof reactQuillRef?.current?.getEditor === 'function') {
        reactQuillRef?.current?.getEditor();
      }

      const attachments = record ? JSON.parse(record?.attachments) : [];
      const formattedAttachments =
        attachments?.length > 0
          ? attachments?.map((attachment) => {
              return {
                uid: crypto.randomUUID(),
                name: attachment.split('/').pop(),
                status: 'done',
                url: attachment,
              };
            })
          : [];
      setFileList(formattedAttachments);
    } else if (isSms) {
      setId(record?.id);

      form.setFieldsValue({
        name: record?.name,
        is_active: record?.is_active === 0 ? false : true,
        category: Object.keys(NotificationCategory).find((key) => NotificationCategory[key] === record?.category),
        content: record?.content,
      });
      setChecked(record?.is_active !== 0);

      setTextareaValue(record?.content);
    } else if (isPushNotification) {
      setId(record?.id);

      form.setFieldsValue({
        name: record?.name,
        category: Object.keys(NotificationCategory).find((key) => NotificationCategory[key] === record?.category),
        content: record?.content,
        subject: record?.subject,
      });
      setChecked(record?.is_active !== 0);

      setTextareaValue(record?.content);
    }
  }, [isEmail, emailTemplates, id, record, form, isSms, isPushNotification]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
        setShowPicker(false);
      }
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue({ content: textareaValue });
  }, [textareaValue, form]);

  const handleUploadChange = async ({ file, fileList }) => {
    setFileList(fileList);
  };

  const handleEmojiClick = (emojiObject, event) => {
    let message = textareaValue || '';
    message += emojiObject.emoji;
    if (message.length > MAX_LENGTH) {
      setShowPicker(false);
      return;
    }
    setTextareaValue(message);
    setShowPicker(false);
  };

  const togglePicker = () => {
    setShowPicker((prevState) => !prevState);
  };

  const uploadFile = useCallback(
    async (file = null) => {
      try {
        const isFilePresent = file !== null;
        const timestamp = new Date().valueOf();

        const url = await dispatch(STSToken()).then(async (res) => {
          if (res.payload.success) {
            const awsConfig = {
              region: res?.payload?.result?.token.Region,
              credentials: {
                accessKeyId: res?.payload?.result?.token.AccessKeyId,
                secretAccessKey: res?.payload?.result?.token.SecretAccessKey,
                sessionToken: res?.payload?.result?.token.SessionToken,
              },
            };
            const client = new S3Client(awsConfig);

            if (!isFilePresent && fileList && fileList.length > 0) {
              const links = [];
              const filteredFiles = fileList.filter((file) => file?.originFileObj);
              for (const file of filteredFiles) {
                const extension = file?.originFileObj?.name.split('.').pop();
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let result = '';
                const charactersLength = characters.length;

                for (let i = 0; i < 8; i++) {
                  result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }

                const key = `notification/emailAttachments/${timestamp}${result}${extension ? `.${extension}` : ''}`;

                const input = {
                  Body: file.originFileObj,
                  Bucket: res?.payload?.result?.token?.BucketName,
                  Key: key,
                  ContentType: file?.type,
                  ACL: 'public-read',
                };

                const command = new PutObjectCommand(input);
                const response = await client.send(command);

                if (response?.$metadata?.httpStatusCode === 200) {
                  const attachmentPath = `https://${res?.payload?.result?.token?.BucketName}.s3.amazonaws.com/${key}`;
                  links.push(attachmentPath);
                } else {
                  notification.error({
                    message: 'Failure',
                    description: 'Something went wrong with the file!',
                  });
                }
              }
              return links;
            } else if (isFilePresent) {
              const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
              let result = '';
              const charactersLength = characters.length;

              for (let i = 0; i < 8; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
              }

              const key = `notification/emailContentImages/${timestamp}${result}`;
              const input = {
                Body: file,
                Bucket: res?.payload?.result?.token?.BucketName,
                Key: key,
                ContentType: file?.type,
                ACL: 'public-read',
              };

              const command = new PutObjectCommand(input);
              const response = await client.send(command);

              if (response?.$metadata?.httpStatusCode === 200) {
                const attachmentPath = `https://${res?.payload?.result?.token?.BucketName}.s3.amazonaws.com/${key}`;
                return attachmentPath;
              } else {
                notification.error({
                  message: 'Failure',
                  description: 'Something went wrong with the file!',
                });
              }
            }
          }
        });

        return url;
      } catch (error) {
        console.log('Error while uploading image', error);
      }
    },
    [fileList, dispatch],
  );

  const insertImageToEditor = useCallback(
    async (file) => {
      try {
        const fileUrl = await uploadFile(file);
        const editor = reactQuillRef.current.getEditor();
        const range = editor.getSelection();
        const position = range ? range.index : 0;
        editor.insertEmbed(position, 'image', fileUrl);
      } catch (error) {
        console.log('Error while uploading image', error);
      }
    },
    [uploadFile],
  );

  const modulesCustom = useMemo(
    () => ({
      toolbar: [[{ header: [1, 2, 3, false] }], ['bold', 'italic', 'underline', 'link'], [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }], ['clean'], ['image']],
      imageUploader: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            insertImageToEditor(file)
              .then((imageUrl) => resolve(imageUrl))
              .catch((error) => reject(error));
          });
        },
      },
      imageResize: {
        parchment: Quill.import('parchment'), // Use this to ensure compatibility
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
        displayStyles: {
          backgroundColor: 'black',
          border: 'none',
          color: 'white',
        },
        handleStyles: {
          backgroundColor: 'black',
          border: 'none',
          color: 'white',
        },
      },
    }),
    [insertImageToEditor],
  );

  const variableOptions = [
    { label: <span onClick={() => insertVariable('User Name')}>User Name</span>, key: 'userName' },
    { label: <span onClick={() => insertVariable('Email')}>Email</span>, key: 'email' },
    { label: <span onClick={() => insertVariable('Phone Number')}>Phone Number</span>, key: 'phone_number' },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
        setShowPicker(false);
      }
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue({ content: textareaValue });
  }, [textareaValue, form]);

  function handleCheck(checked) {
    setChecked(checked);
    form.setFieldsValue({ is_active: checked ? 1 : 0 });
  }

  const handlePreviewClick = () => {
    setPreviewOpen(true);
  };

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 25;
    if (!isLt2M) {
      message.error('File must be smaller than 25MB!');
      return Upload.LIST_IGNORE;
    }
    return isLt2M;
  };

  const insertVariable = (variable) => {
    const text = `||${variable}||`;
    if (isEmail) {
      reactQuillRef.current.focus();
      const editor = reactQuillRef.current.getEditor();
      const range = editor.getSelection();
      if (range) {
        const position = range.index;
        editor.insertText(position, text);
        editor.setSelection(position + text.length, 0);
      } else {
        const position = editor.getLength();
        editor.insertText(position, text);
        editor.setSelection(position + text.length, 0);
      }
    } else {
      if (textAreaRef.current) {
        const { selectionStart, selectionEnd } = textAreaRef.current.resizableTextArea.textArea;

        let newText = (textareaValue?.substring(0, selectionStart) || '') + text + (textareaValue?.substring(selectionEnd, textareaValue?.length) || '');

        if (newText.length > MAX_LENGTH) {
          newText = newText.substring(0, MAX_LENGTH);
        }

        setTextareaValue(newText);

        setTimeout(() => {
          textAreaRef.current.resizableTextArea.textArea.selectionStart = selectionStart + text.length;
          textAreaRef.current.resizableTextArea.textArea.selectionEnd = selectionStart + text.length;
          textAreaRef.current.resizableTextArea.textArea.focus();
        }, 0);
      }
    }
  };

  const handleSubmit = async (values) => {
    setFormSubmitting(true);
    const categoryType = Object.keys(NotificationCategory)
      .filter((category) => category === values.category)
      .map((category) => NotificationCategory[category])[0];

    const newValues = {
      ...values,
      is_active: checked ? 1 : 0,
      type: key,
      category: categoryType,
    };

    if (isPushNotification) {
      newValues.content = textareaValue;
    }

    if (isEmail && fileList && fileList.length > 0) {
      const beforeFiles = fileList.filter((file) => file?.url).map((file) => file.url);
      const newLinks = await uploadFile();
      newValues.attachments = [...beforeFiles, ...newLinks];
    }

    if (!isEdited) {
      try {
        const res = await dispatch(saveTemplateAsync(newValues)); // Ensure dispatch is awaited
        if (res?.payload?.data?.success === true) {
          notification.success({
            message: 'Success',
            description: res?.payload?.data?.message,
          });
          form.resetFields();
          setFormSubmitting(false);
          setOpen(false);
          setRecord(null);
          if (!notificationComponent) {
            await dispatch(getTemplateListAsync(key));
          } else {
            await dispatch(getTemplateListAsync());
          }
        } else {
          notification.error({
            message: 'Failure',
            description: res?.payload?.data?.message,
          });
        }
      } catch (error) {
        notification.error({
          message: 'Failure',
          description: error.message || 'Something went wrong!',
        });
      }
    }

    if (isEdited) {
      const res = await dispatch(editTemplateAsync({ id, newValues }));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        if (!notificationComponent) {
          await dispatch(getTemplateListAsync(key));
        } else {
          await dispatch(getTemplateListAsync());
        }
      } else {
        notification.error({
          message: 'Failure',
          description: 'Something went wrong!',
        });
      }
      form.resetFields();
      setFormSubmitting(false);
      setIsEdited(false);
      setId(null);
      setOpen(false);
      setRecord(null);
    }
  };

  const handleModalClose = async () => {
    setOpen(false);
    setRecord(null);
    setIsEdited(false);
    setFileList([]);
    setType('');
    setTextareaValue('');
    setIsEdited(false);
    setChecked(true);
    form.resetFields();
  };

  const uploadProps = {
    onChange: handleUploadChange,
    fileList,
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <DeleteOutlined />,
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleTextAreaChange = (e) => {
    const clean = DOMPurify.sanitize(e.target.value);

    setTextareaValue(clean);
  };

  return (
    <>
      <Modal destroyOnClose footer={null} title={modalTitle} closeIcon={<CloseCircleOutlined className="modalCloseIcon" />} centered open={open} onCancel={handleModalClose} width={1000}>
        <Spin size="large" spinning={formSubmitting || submitLoading}>
          <div className="row mt-3 emailTemplateForm">
            <Form form={form} layout="vertical" requiredMark="default" onFinish={handleSubmit}>
              <div className="row justify-content-start">
                <div className="col-xl-7">
                  <Form.Item
                    name="name"
                    label="Template Name"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please enter the template name!',
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.length >= 255) {
                            return Promise.reject(new Error('Content cannot exceed 255 characters.'));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input maxLength={255} placeholder="Template Name" />
                  </Form.Item>
                </div>
                <div className="col-xl-3">
                  <Form.Item
                    label="Category"
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: 'Please select the category!',
                      },
                    ]}
                  >
                    <Select defaultValue={'--Select Category--'} style={{ width: 120 }} options={categoryOptions} />
                  </Form.Item>
                </div>
                <div className="col-xl-2">
                  <Form.Item required label="Status" name="is_active">
                    <Switch checked={checked} onChange={handleCheck} checkedChildren="Active" unCheckedChildren="Inactive" />
                  </Form.Item>
                </div>
              </div>
              {(isEmail || isPushNotification) && (
                <div className="row">
                  <div className="col-12">
                    <Form.Item
                      name={`subject`}
                      label={`${isPushNotification ? 'Title' : 'Subject'}`}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Please enter the subject!',
                        },
                        {
                          validator: (_, value) => {
                            if (value && value.length >= 78) {
                              return Promise.reject(new Error('Content cannot exceed 78 characters.'));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input maxLength={78} placeholder={`${isPushNotification ? 'Enter Title' : 'Enter the subject Line'} `} />
                    </Form.Item>
                  </div>
                </div>
              )}
              <div className="row editorParent">
                <div className="col-xl-12 h-100 mt-2">
                  <Form.Item
                    name="content"
                    label={`${isPushNotification ? 'Description' : 'Message'}`}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please enter the content!',
                      },
                      {
                        validator: (_, value) => {
                          if (!isEmail && value && value.length >= MAX_LENGTH) {
                            return Promise.reject(new Error(`Content cannot exceed ${MAX_LENGTH} characters!`));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    {!isEmail ? (
                      <Input.TextArea
                        value={textareaValue}
                        placeholder="Enter Description"
                        maxLength={MAX_LENGTH}
                        ref={textAreaRef}
                        rows={4}
                        className="emojiParent"
                        showCount
                        onChange={handleTextAreaChange}
                      />
                    ) : (
                      <ReactQuill key={crypto.randomUUID()} ref={reactQuillRef} modules={modulesCustom} className="w-100" theme="snow" />
                    )}
                  </Form.Item>
                </div>

                <div className="emojiSelector">
                  {isPushNotification && !showPicker && (
                    <div ref={buttonRef} onClick={togglePicker}>
                      <Button icon={<SmileOutlined />} />
                    </div>
                  )}
                  {isPushNotification && showPicker && (
                    <div ref={pickerRef}>
                      <Picker disableAutoFocus onEmojiClick={handleEmojiClick} />
                    </div>
                  )}
                </div>

                <div className="variableParent d-flex justify-content-end w-25 mb-2">
                  <Dropdown menu={{ items: variableOptions }} trigger={['click']}>
                    <Button icon={<PlusCircleOutlined />}>Add Variable</Button>
                  </Dropdown>
                </div>
              </div>
              {isEmail && (
                <div className="w-100">
                  <Form.Item name="attachments" style={{ width: '100%', height: '100%' }}>
                    <Upload fileList={fileList} onChange={handleUploadChange} customRequest={dummyRequest} {...uploadProps} maxCount={5} beforeUpload={beforeUpload}>
                      <Button icon={<UploadOutlined />}>Attachment</Button>
                    </Upload>
                  </Form.Item>
                </div>
              )}
              <div className="d-flex justify-content-end align-items-center gap-3 mt-3">
                <Button onClick={handlePreviewClick} ghost size="medium" type="primary">
                  Preview
                </Button>

                <Button htmlType="submit" size="medium" type="primary">
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </Spin>
      </Modal>
      <PreviewTemplate open={previewOpen} type={type} setOpen={setPreviewOpen} previewContent={form.getFieldValue('content')} subject={form.getFieldValue('subject')} />
    </>
  );
};

export default AddTemplate;

AddTemplate.defaultProps = {
  open: false,
  setOpen: () => {},
  record: null,
  setRecord: () => {},
  type: '',
  setType: () => {},
  isEdited: false,
  setIsEdited: () => {},
  notificationComponent: false,
};

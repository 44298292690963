import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true}

export const getSettlementByIdSlice = createSlice({
    name: 'getSettlementById',
    initialState,
    reducers: {
        getSettlementByIdSuccess: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        getSettlementByIdFailure: (state, action) => { 
            state.data = action.payload;
            state.isLoading = false;
            state.error = true;
        },
        resetGetSettlementById: () => initialState

    }
})

export const { getSettlementByIdSuccess, getSettlementByIdFailure, resetGetSettlementById } = getSettlementByIdSlice.actions

export default getSettlementByIdSlice.reducer;
import { deleteRequest } from '../../../utils/apiUtils';
import { DeleteDepreciationListFailure, DeleteDepreciationListRequest, DeleteDepreciationListSuccess } from '../../reducers/slices/Depreciation/deleteDepreciationByIdSlice';

export const DeleteDepreciationList = (id, status) => {
  return async (dispatch) => {
    await dispatch(DeleteDepreciationListRequest());
    const { result, error } = await deleteRequest(`admin/depreciation/${status}/delete/${id}`);

    if (!error) {
      return await dispatch(DeleteDepreciationListSuccess(result));
    }
    return await dispatch(DeleteDepreciationListFailure(error));
  };
};

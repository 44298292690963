import { Button, DatePicker, Form, Input, notification, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { GetChannelPartnerList } from '../../container/actions/ChannelPartner/getChannelPartner.action';
import 'react-phone-input-2/lib/style.css';
import { debounce } from 'lodash';
import { ReloadOutlined } from '@ant-design/icons';
import { ChannelPartnerTypeOption } from '../../utils';
import ModalAddChannelPartner from '../../utils/ModalAddChannelPartner';

const defaultFilter = {
  startDate: '',
  endDate: '',
  searchSlug: '',
};

const ChannelPartner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterForm] = Form.useForm();
  const [filters, setFilters] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [taxAccountData, setTaxAccountData] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const dispatch = useDispatch();

  // Pagination state
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });
  const [total, setTotal] = useState(0);

  // Initialize filters and pagination from localStorage
  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem('channelPartnerFilter'));
    const storedPagination = JSON.parse(localStorage.getItem('channelPartnerPagination'));

    if (storedFilter) {
      filterForm.setFieldsValue({
        Search: storedFilter.searchSlug || '',
        startDate: storedFilter.startDate ? dayjs(storedFilter.startDate) : null,
        endDate: storedFilter.endDate ? dayjs(storedFilter.endDate) : null,
      });
      setFilters(storedFilter);
      setInputValue(storedFilter.searchSlug || '');
    } else {
      setFilters(defaultFilter);
    }

    if (storedPagination) {
      setPagination(storedPagination);
    }
  }, [filterForm]);

  // Fetch data whenever filters or pagination change
  useEffect(() => {
    if (filters !== null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination, dispatch]);

  // Update localStorage when filters change
  useEffect(() => {
    if (filters !== null) {
      localStorage.setItem('channelPartnerFilter', JSON.stringify(filters));
    }
  }, [filters]);

  // Update localStorage when pagination changes
  useEffect(() => {
    if (pagination !== null) {
      localStorage.setItem('channelPartnerPagination', JSON.stringify(pagination));
    }
  }, [pagination]);

  const fetchData = async () => {
    setLoading(true);
    const params = {
      ...filters,
      skip: (Number(pagination.current) - 1) * pagination.pageSize,
      limit: pagination.pageSize,
    };

    await dispatch(GetChannelPartnerList(params)).then((res) => {
      if (res?.payload?.success === true) {
        setTaxAccountData(res.payload.result.users || []);
        setTotal(res.payload.result.count || 0);
      } else {
        notification.error({
          message: 'Failure',
          description: 'Something went wrong!',
        });
      }
      setLoading(false);
    });
  };

  const startDateChange = (value) => {
    // Reset to first page
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    if (value && dayjs(value).isValid() && filters.endDate && dayjs(filters.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be less than End Date.',
      });
      filterForm.setFieldsValue({ startDate: null });
      return;
    }
    const newStartDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setFilters({ ...filters, startDate: newStartDate });
  };

  const endDateChange = (value) => {
    // Reset to first page
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    if (value && dayjs(value).isValid() && filters.startDate && dayjs(filters.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'End Date should be greater than Start Date.',
      });
      filterForm.setFieldsValue({ endDate: null });
      return;
    }
    const newEndDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setFilters({ ...filters, endDate: newEndDate });
  };

  const SearchSlug = debounce((e) => {
    // Reset to first page
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setInputValue(e.target.value);
    const values = e.target.value.trim();
    setFilters({ ...filters, searchSlug: values });
  }, 300);

  const resetFilter = () => {
    filterForm.resetFields();
    setFilters(defaultFilter);
    setInputValue('');
    // Reset pagination as well
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
  };

  const TaxAccountantColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      fixed: 'left',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (_, record) => (record?.company_name ? record.company_name : '-'),
    },
    {
      title: 'Type',
      dataIndex: 'channel_partner_type',
      key: 'channel_partner_type',
      render: (text) => ChannelPartnerTypeOption.find((item) => item.value === text)?.label || '-',
    },
    {
      title: 'Total Clients',
      dataIndex: 'client_count',
      key: 'client_count',
      render: (_, record) => (record?.client_count ? record.client_count : '0'),
    },
    {
      title: 'Referral Code',
      dataIndex: 'referral_code',
      key: 'referral_code',
      render: (text) => text || '-',
    },
  ];

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };

  const onSuccessAdd = () => {
    // Re-fetch data after adding new channel partner
    setFilters({ ...filters });
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Channel Partner</h5>
          </div>
        </div>
        <div className="reset-width-100 ">
          <Form form={filterForm}>
            <div className="d-flex loans-header-responsive align-items-center gap-3 justify-content-end">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={SearchSlug} value={inputValue} allowClear style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 d-flex gap-3  mb-lg-0 ">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={resetFilter}>
                    Reset
                  </Button>

                  <Button size="large" htmlType="submit" type="primary" className="reset-width-100" onClick={() => setIsModalOpen(true)}>
                    + Add New
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={Loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table
                columns={TaxAccountantColumn}
                dataSource={taxAccountData}
                rowKey={'id'}
                scroll={{ x: 'max-content' }}
                pagination={{ ...pagination, total: total, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </Spin>

      {/* ADD MODAL  */}
      <ModalAddChannelPartner isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} onSuccessAdd={onSuccessAdd} channelPartner={true} />
    </>
  );
};

export default ChannelPartner;

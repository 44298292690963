import React, { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import Header from './Header';
import Sidebar from './Sidebar';
const { Sider } = Layout;

const MainLayout = () => {
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  if (pathname === '/') {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className={`page-wrapper landrick-theme toggled ${collapsed ? 'sidebar-collapsed' : 'sidebar-opened'} `} id="side__bar">
      <Sider trigger={null} width={261} className="bg-white" collapsible collapsed={collapsed}>
        <Sidebar collapsed={collapsed} />
      </Sider>

      <main className="page-content bg-light">
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />

        <div className="container-fluid mt-5 pt-5 ps-4">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default MainLayout;

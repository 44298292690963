import dayjs from 'dayjs';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Popconfirm, Row, Select, Space, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import SmallLoader from '../../components/common/SmallLoader';

import { SendNotification } from '../../utils/utilities';
import * as yup from 'yup';

import { resetDepreciationById } from '../../container/reducers/slices/Depreciation/depreciationByIdSlice';

import GoBack from '../../components/common/GoBack';

import { GetAllDepreciation } from '../../container/actions/depreciation/getAllDepreciation.action';
import { DeleteDepreciationList } from '../../container/actions/depreciation/deleteDepreciation.action';
import { checkDepreciation } from '../../container/actions/depreciation/checkDepreciation.action';
import { GetCompleteDepreciationById } from '../../container/actions/depreciation/getDepreciationComplete.action';
import { DepreciationMethod } from '../../components/common/userTypes';
import { UpdateCompleteDepreciation } from '../../container/actions/depreciation/updateCompeleteDepreciation.action';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';
import { CopyOutlined } from '@ant-design/icons';

const text = 'Are you sure you want to discard?';

const DepreciationCompleteSchedule = () => {
  const [depreciationForm] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const depreciationData = [{}];
  const [checkDepre, setCheckDepre] = useState('');
  const [financialYearEndingData, setFinancialYearEndingData] = useState([]);
  const [total, setTotal] = useState(0);
  const [depMethod, setDepMethod] = useState();

  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const depreciationFormSchema = yup.object({
    property_id: yup.string().required('Please select property').nullable(),
    depreciable_amount: yup
      .string()
      .required('Depreciable amount is required')
      .test('depreciable_amount', `Enter valid amount`, function () {
        const { depreciable_amount } = this.parent;

        if (depreciable_amount === null || !Number.isFinite(1 / depreciable_amount)) {
          return false;
        }

        return true;
      })
      .nullable(),
    amount_claimable: yup
      .string()
      // .required("Claimable amount is required")
      // .test("amount_claimable", `Value can't be zero`, function () {
      //     const { amount_claimable } = this.parent;

      //     if (
      //         amount_claimable === null ||
      // // !Number.isFinite(1 / amount_claimable)
      //     ) {
      //         return false;
      //     }

      //     return true;
      // })
      .nullable(),
  });

  const yupSync = {
    async validator({ field }, value) {
      await depreciationFormSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const { depreciationByIdData, loadingDepreciationById } = useSelector((state) => state.depreciationCompleteByIdReducer);

  useEffect(() => {
    dispatch(GetCompleteDepreciationById(id)).then(({ payload: { result } }) => {
      setTotal(result?.depreciable_amount || 0);
    });

    return () => {
      dispatch(resetDepreciationById());
    };
  }, [id, dispatch]);

  const propartyOptions = useMemo(
    () =>
      depreciationByIdData?.result?.user?.properties?.map((item) => {
        return {
          key: item.id,
          value: item.id,
          label: `${item.street_number} - ${item.street_name}`,
        };
      }),
    [depreciationByIdData],
  );

  useEffect(() => {
    setFinancialYearEndingData(depreciationByIdData?.result?.depreciationFinancialYears);
    depreciationForm.setFieldsValue({
      virtual_email: depreciationByIdData?.result?.user?.virtual_email,
      Financial_year_ending: dayjs(new Date()),
      depreciation_method:
        depMethod === DepreciationMethod?.PRIME_COST_DEPRECIATION || depreciationByIdData?.result?.property?.depreciation_method === DepreciationMethod?.PRIME_COST_DEPRECIATION
          ? 'Prime Cost'
          : depMethod === DepreciationMethod?.WDV_DEPRECIATION || depreciationByIdData?.result?.property?.depreciation_method === DepreciationMethod?.WDV_DEPRECIATION
          ? 'Diminishing Value'
          : '',
      financial_year_arr: depreciationByIdData?.result?.depreciationFinancialYears?.map((item) => {
        return { ...item, financial_date: dayjs(item.financial_date).format('DD-MM-YYYY') };
      }),
      address: `${depreciationByIdData?.result?.property?.street_number} ${depreciationByIdData?.result?.property.street_name} ${depreciationByIdData?.result?.property?.suburb} ${depreciationByIdData?.result?.property?.postcode}`,
      depreciable_amount: depreciationByIdData?.result?.depreciable_amount,
      construction_date: dayjs(depreciationByIdData?.result?.construction_date),
      property_type: getPropertyTypeLabel(depreciationByIdData?.result?.property?.property_type),
    });
  }, [depreciationByIdData, depMethod, depreciationForm]);

  // useEffect(() => {
  //     if (isValidArray(propertyListByUser)) {
  //         let data = propertyListByUser.find((item) => item.id == propertyId);

  //         if (data) {
  //             const {
  //                 full_address,
  //                 depreciation_method,
  //             } = data;

  //             depreciationForm.setFieldsValue({
  //                 address: full_address,
  //                 depreciation_method: depreciation_method
  //             })
  //         }
  //     }
  // }, [propertyId, propertyListByUser]);

  const valueChange = (e) => {
    // get total value of financial year array
    const fin_total = depreciationForm?.getFieldsValue()?.financial_year_arr?.reduce((acc, item) => acc + Number(item?.amount), 0);
    setTotal(fin_total);
  };

  const handleFormSubmit = (data) => {
    setFormSubmitting(true);
    const [{ virtual_email, Depreciation_Id, depreciation_schedule }] = depreciationData;
    const { construction_date, amount_claimable, depreciable_amount, Financial_year_ending, financial_year_arr } = data;
    const getFinancialYearData = () => {
      return financial_year_arr.map((item, i) => {
        return {
          ...item,
          financial_date: dayjs(item.financial_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          amount: item?.amount ? parseFloat(item?.amount).toFixed(2) : 0,
          fourty_division_amount: item?.fourty_division_amount ? parseFloat(item?.fourty_division_amount).toFixed(2) : 0,
          fourtythree_division_amount: item?.fourtythree_division_amount ? parseFloat(item?.fourtythree_division_amount).toFixed(2) : 0,
        };
      });
    };

    let body = {
      email: virtual_email,
      property_id: depreciationByIdData?.result?.property?.id,
      id: Number(depreciationByIdData?.result?.id),
      construction_date: dayjs(construction_date).format('YYYY-MM-DD'),
      depreciable_amount,
      depreciation_id: Depreciation_Id,
      depreciation_schedule: depreciation_schedule || null,
      claimable_amount: parseFloat(amount_claimable) ? parseFloat(amount_claimable).toFixed(2) : 0, // not in post man
      ending_year: dayjs(Financial_year_ending).format('YYYY-MM-DD'), // not in post man
      financial_year_arr: [...getFinancialYearData()],
    };

    if (
      Number(total) !== 0
        ? Number(total).toFixed(2) === Number(depreciable_amount).toFixed(2)
        : Number(depreciationByIdData?.result?.depreciable_amount).toFixed(2) === Number(depreciable_amount).toFixed(2)
    ) {
      dispatch(UpdateCompleteDepreciation(body)).then((res) => {
        if (res.payload.success) {
          SendNotification({
            type: 'success',
            message: res.payload.message || 'Depreciation data updated',
          });
          setFormSubmitting(false);
          navigate('/depreciation/complete');
        } else {
          setFormSubmitting(false);
          SendNotification({ type: 'error', message: res.payload.message || 'Something went wrong' });
        }
      });
    } else {
      setFormSubmitting(false);
      SendNotification({
        type: 'error',
        message: 'Please check financial year Amonut does not equal to depreciation Amount.',
      });
    }
  };

  const discardRecord = (id) => {
    setFormSubmitting(true);
    dispatch(DeleteDepreciationList(id, 'complete')).then((response) => {
      if (response?.payload?.success) {
        SendNotification({
          type: 'success',
          message: response.payload.message || 'Depreciation deleted successfully',
        });
        dispatch(GetAllDepreciation());
        navigate('/depreciation/complete', { replace: true });
        setFormSubmitting(false);
      } else {
        SendNotification({ type: 'error', message: response?.payload?.message || 'Something went wrong' });
        setFormSubmitting(false);
      }
    });
  };

  const onPropertyCkeck = (data) => {
    depreciationByIdData?.result?.user?.properties?.forEach((ele) => {
      setDepMethod(ele.depreciation_method);
    });

    dispatch(checkDepreciation(data)).then((res) => {
      if (res?.payload?.result?.length === 0) {
        return setCheckDepre(0);
      } else {
        setCheckDepre(1);
        SendNotification({
          type: 'error',
          message: 'Depreciation of the property has already been submitted.',
        });
      }
    });
  };

  const changeDate = (e) => {
    const date = dayjs(e);
    const year = date.year();
    const Ddate = dayjs(`${year}-06-30`);
    const Financial_year_ending = date.isAfter(Ddate) ? dayjs(`${year + 1}-06-30`) : Ddate;
    const financial_year = date.isAfter(Ddate) ? year + 1 : year;
    const financial_year_arr = financialYearEndingData.map((item, index) => ({
      ...item,
      financial_date: dayjs(`${financial_year + index}-06-30`).format('DD-MM-YYYY'),
    }));
    depreciationForm.setFieldsValue({ Financial_year_ending, financial_year_arr });
  };

  const valueChangeDivision = (index) => {
    const values = depreciationForm.getFieldsValue();
    const fourtyValue = parseFloat(values.financial_year_arr?.[index]?.fourty_division_amount) || 0;
    const fourtyThreeValue = parseFloat(values.financial_year_arr?.[index]?.fourtythree_division_amount) || 0;

    const total = fourtyValue + fourtyThreeValue;

    depreciationForm.setFields([
      {
        name: ['financial_year_arr', index, 'amount'],
        value: total,
      },
    ]);
    valueChange();
  };

  const valueCopyFourtyDivision = () => {
    // Retrieve existing financial year data
    const financialYearData = depreciationForm.getFieldValue('financial_year_arr') || [];

    const fourtyValue = parseFloat(financialYearData?.[0]?.fourty_division_amount) || 0;

    // Update the `fourty_division_amount` for the specified index
    const updatedData = financialYearData.map((item) => ({
      ...item,
      fourty_division_amount: fourtyValue,
      amount: fourtyValue || item.fourtythree_division_amount ? Number(fourtyValue) + Number(item.fourtythree_division_amount || 0) : '',
    }));

    // Set the updated values to the form
    depreciationForm.setFieldsValue({
      financial_year_arr: updatedData,
    });

    valueChange();
  };

  const valueCopyFourtyThreeDivision = () => {
    // Retrieve existing financial year data
    const financialYearData = depreciationForm.getFieldValue('financial_year_arr') || [];

    const fourtyThreeValue = parseFloat(financialYearData?.[0]?.fourtythree_division_amount) || 0;

    // Update the `fourty_division_amount` for the specified index
    const updatedData = financialYearData.map((item) => ({
      ...item,
      fourtythree_division_amount: fourtyThreeValue,
      amount: fourtyThreeValue || item.fourty_division_amount ? Number(fourtyThreeValue) + Number(item.fourty_division_amount || 0) : '',
    }));

    // Set the updated values to the form
    depreciationForm.setFieldsValue({
      financial_year_arr: updatedData,
    });
    valueChange();
  };

  if (loadingDepreciationById) {
    return <SmallLoader />;
  }

  return (
    <>
      <Row>
        <GoBack />
        <h5 className="m-0">Depreciation Schedule</h5>
      </Row>

      <div className="schedule_wrapper">
        <Spin spinning={isFormSubmitting}>
          <div className="row">
            <div className="col-xl-6 col-md-6 col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div id="Iframe-Master-CC-and-Rs" className="set-margin set-padding set-border set-box-shadow center-block-horiz">
                    <div className="responsive-wrapper responsive-wrapper-wxh-572x612 docFile">
                      <iframe title="Depreciation Receipt" src={depreciationByIdData?.result?.depreciation_receipt_path}></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-md-6 col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <Form
                    form={depreciationForm}
                    layout="vertical"
                    name="form"
                    initialValues={{
                      financial_year_arr: financialYearEndingData,
                    }}
                    onFinish={handleFormSubmit}
                  >
                    <div className="row">
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-4">
                          <Form.Item label="User Unique Email" name="virtual_email">
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="">
                          <Form.Item
                            name="property_id"
                            label="Property"
                            rules={[
                              {
                                required: checkDepre,
                                message: 'Please Select Property',
                              },
                            ]}
                          >
                            <Select onChange={(data) => onPropertyCkeck(data)} defaultValue={depreciationByIdData?.result?.property.id} options={propartyOptions || []} disabled></Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-4">
                          <Form.Item label="Property Address" name="address">
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-4 schedule_method">
                          <Form.Item label="Depreciation Method" name="depreciation_method">
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="">
                          <Form.Item label="Construction Date or Purchase Date" name="construction_date">
                            <DatePicker format="DD-MM-YYYY" onChange={(e) => changeDate(e)} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <Form.Item label="Total Depreciable Amount" name="depreciable_amount" rules={[yupSync]}>
                          <Input autoComplete="off" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-12">
                      <Form.Item label="Property Type" name="property_type">
                        <Input className="form-control p-1 px-2" style={{ color: 'black' }} disabled={true} placeholder="property Type" type="text" />
                      </Form.Item>
                    </div>
                    <div className="col-xl-6 col-md-6 col-12"></div>

                    <div className="row">
                      <div className="col-xl-4 col-md-4 col-12">
                        <label>Financial Year Ending</label>
                      </div>

                      <div className="col-xl-8 col-md-8 col-12">
                        <Row gutter={16} className="mb-2">
                          <Col span={8}>
                            <label>Division 40 </label>
                            <Button type="dashed" size="small" icon={<CopyOutlined />} onClick={valueCopyFourtyDivision}>
                              Copy
                            </Button>
                          </Col>
                          <Col span={8}>
                            <label>Division 43 </label>
                            <Button type="dashed" size="small" icon={<CopyOutlined />} onClick={valueCopyFourtyThreeDivision}>
                              Copy
                            </Button>
                          </Col>
                          <Col span={8}>
                            <label>Amount Claimable</label>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="row overflow__scroll">
                      <Form.List name="financial_year_arr">
                        {(fields) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Fragment key={key}>
                                <div className="col-xl-4 col-md-4 col-12">
                                  <Form.Item {...restField} name={[name, 'financial_date']}>
                                    <Input disabled={true} />
                                  </Form.Item>
                                </div>
                                <div className="col-xl-8 col-md-8 col-12">
                                  <div className="position-relative">
                                    <Space.Compact block>
                                      <Form.Item {...restField} name={[name, 'fourty_division_amount']} defaultValue={0} rules={[{ required: false, message: 'Amount is required' }]}>
                                        <Input autoComplete="off" className="onchange" onChange={() => valueChangeDivision(name)} />
                                      </Form.Item>
                                      <Form.Item {...restField} name={[name, 'fourtythree_division_amount']} defaultValue={0} rules={[{ required: false, message: 'Amount is required' }]}>
                                        <Input autoComplete="off" className="onchange" onChange={() => valueChangeDivision(name)} />
                                      </Form.Item>
                                      <Form.Item {...restField} name={[name, 'amount']}>
                                        <Input className="onchange" disabled />
                                      </Form.Item>
                                    </Space.Compact>
                                  </div>
                                </div>
                                <input type="hidden" className="form-control" placeholder="$5000" />
                              </Fragment>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </div>

                    <div className="row justify-content-between">
                      <div className="col-xl-4 col-md-2 col-2">
                        <div className="d-flex justify-content-between">
                          <div className="mt-3 me-1">
                            <Form.Item>
                              <Button htmlType="submit" type="primary">
                                Submit
                              </Button>
                            </Form.Item>
                          </div>
                          <div className="mt-3 me-2">
                            {!depreciationData[0]?.Property_Id ? (
                              <div className="justify-content-start text-start">
                                <Popconfirm placement="left" title={text} onConfirm={() => discardRecord(id)} okText="Yes" cancelText="No">
                                  <Button danger ghost>
                                    Discard
                                  </Button>
                                </Popconfirm>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8 col-md-10 col-10">
                        <div className="d-flex mt-3 align-items-center justify-content-center">
                          <label htmlFor="">Depreciation Total :</label>
                          <h4 className="mb-1">
                            <small className="text-muted">{parseFloat(total).toFixed(2)}</small>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default DepreciationCompleteSchedule;

import { createSlice } from "@reduxjs/toolkit";

const initialState = { allSettlementData: [], error: false, loadingAllSettlement: true };

export const allSettlementSlice = createSlice({
    name: 'allSettlement',
    initialState,
    reducers: {
        allSettlementRequest: (state) => {
            state.allSettlementData = [];
            state.loadingAllSettlement = true;
        },
        allSettlementSuccess: (state, action) => {
            state.allSettlementData = action.payload;
            state.loadingAllSettlement = false;
        },
        allSettlementFailure: (state, action) => {
            state.allSettlementData = action.payload;
            state.loadingAllSettlement = false;
            state.error = true;
        }
    }

})

export const { allSettlementSuccess, allSettlementFailure, allSettlementRequest } = allSettlementSlice.actions;

export default allSettlementSlice.reducer;
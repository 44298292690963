import { postRequest } from '../../../utils/apiUtils';

export const getFilterData = (filters) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest('/admin/notifications/filters', filters);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

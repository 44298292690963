import { FrownOutlined } from '@ant-design/icons';
import { notification, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { verifyAdmin } from '../../container/actions/login/verify.action';
import { UserTypes } from '../../components/common/userTypes';

const Verify = () => {
  const [searchParams] = useSearchParams();
  let { type } = useParams();
  const code = searchParams.get('code');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (code && type) {
      const values = {
        code: code,
        userType: 1,
      };
      dispatch(verifyAdmin(values)).then((res) => {
        if (res?.payload?.success === true) {
          if (res.payload.result.user_type === UserTypes.ADMIN) {
            const data = res.payload.result;
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);
            localStorage.setItem('aws_cognito_sub', data.aws_cognito_sub);
            localStorage.setItem('name', data.name);
            localStorage.setItem('email', data.email);

            return navigate('/');
           
          } else {
            // localStorage.clear();
            // navigate('/login')
          }
        } else {
          notification.open({
            message: 'Failure',
            description: res?.payload?.message || 'Something went wrong!',
            icon: (
              <FrownOutlined
                style={{
                  color: 'red',
                }}
              />
            ),
          });
          // localStorage.clear();
          // navigate('/login')
        }
      });
    } else {
      notification.open({
        message: 'Failure',
        description: 'Something went wrong!',
        icon: (
          <FrownOutlined
            style={{
              color: 'red',
            }}
          />
        ),
      });
      // localStorage.clear();
      // navigate('/login')
    }
  }, [code, type, dispatch, navigate]);

  return (
    <div className="login_wrapper">
      <div className="">
        <div className="card-body p-0">
          <div className="modal-body p-4">
            <div className="text-center">
              <Spin spinning={true} size="large"></Spin>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;

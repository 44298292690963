import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getUnconfirmedUsersListSlice = createSlice({
  name: 'UnconfirmedUsersList',
  initialState,
  reducers: {
    GetUnconfirmedUsersListSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    GetUnconfirmedUsersListFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { GetUnconfirmedUsersListSuccess, GetUnconfirmedUsersListFailure } = getUnconfirmedUsersListSlice.actions;

export default getUnconfirmedUsersListSlice.reducer;

import { getRequest } from '../../../utils/apiUtils'
import { allSettlementFailure, allSettlementSuccess, allSettlementRequest } from "../../reducers/slices/settlement/allSettlementsSlice";

export const AllSettlements = () => {
    return async (dispatch) => {
        await dispatch(allSettlementRequest([]));
        const { result, error } = await getRequest(`admin/fetch-settlement/pending`);

        if (!error) {
            return dispatch(allSettlementSuccess(result));
        }
        return dispatch(allSettlementFailure(result))
    }
}
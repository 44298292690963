import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true }

export const updatePropertyValuationSlice = createSlice({
    name: 'updatePropertyValuation',
    initialState,
    reducers: {
        updatePropertyValuationSuccess: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        updatePropertyValuationFailure: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
            state.error = true;
        }
    }
})

export const { updatePropertyValuationSuccess, updatePropertyValuationFailure } = updatePropertyValuationSlice.actions

export default updatePropertyValuationSlice.reducer
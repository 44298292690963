import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getManagedClients, getRegisteredClients, getTeamMembers } from '../../../actions/TaxAccountantDetails/TaxAccountantDetailsApi';

const initialState = {
  error: false,
  isLoading: false,
  teamMembers: [],
  registeredClients: [],
  managedClients: [],
};

export const getTeamMembersAsync = createAsyncThunk('Tax Accountant Details/getTeamMembers', async ({ id, ...filters }) => {
  const response = await getTeamMembers(id, filters);
  return response;
});

export const getRegisteredClientsAsync = createAsyncThunk('Tax Accountant Details/getRegisteredClients', async ({ id, ...filters }) => {
  const response = await getRegisteredClients(id, filters);
  return response;
});

export const getManagedClientsAsync = createAsyncThunk('Tax Accountant Details/getManagedClients', async ({ id, ...filters }) => {
  const response = await getManagedClients(id, filters);
  return response;
});

export const TaxAccountantDetailsSlice = createSlice({
  name: 'Tax Accountant Details',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeamMembersAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTeamMembersAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.teamMembers = action.payload.data.result;
      })
      .addCase(getTeamMembersAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getRegisteredClientsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRegisteredClientsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.registeredClients = action.payload.data.result;
      })
      .addCase(getRegisteredClientsAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getManagedClientsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getManagedClientsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.managedClients = action.payload.data.result;
      })
      .addCase(getManagedClientsAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectTaxAccountantTeamMembersLoading = (state) => state.taxAccountantDetails.isLoading;
export const selectTaxAccountantTeamMembers = (state) => state.taxAccountantDetails.teamMembers;

export const selectTaxAccountantRegisteredClients = (state) => state.taxAccountantDetails.registeredClients;
export const selectTaxAccountantClientsLoading = (state) => state.taxAccountantDetails.isLoading;

export const selectTaxAccountantManagedClients = (state) => state.taxAccountantDetails.managedClients;
export const selectTaxAccountantManagedClientsLoading = (state) => state.taxAccountantDetails.isLoading;

export default TaxAccountantDetailsSlice.reducer;

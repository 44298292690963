import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    propertySettlentListDataByUser: [],
    error: false,
    loadingPropertyListDataByUser: true,
};




const propertySettlementListByUserSlice = createSlice({
    name: "propertyListByUser",
    initialState,
    reducers: {
        PropertySettlementListByUserSuccess: (state, action) => {
            state.propertySettlentListDataByUser = action.payload
            state.loadingPropertyListDataByUser = false;
        },
        PropertySettlementListByUserFailure: (state, action) => {
            state.propertySettlentListDataByUser = action.payload
            state.loadingPropertyListDataByUser = false;
            state.error = true;
        },
        ResetPropertySettlementListByUser: () => initialState

    }
});
export const { PropertySettlementListByUserSuccess, PropertySettlementListByUserFailure, ResetPropertySettlementListByUser } = propertySettlementListByUserSlice.actions

export default propertySettlementListByUserSlice.reducer;

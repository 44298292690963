import { postRequest } from '../../../utils/apiUtils';
import { updateCompleteDepreciationFailure, updateCompleteDepreciationSuccess } from '../../reducers/slices/Depreciation/updateCompeleteDepreciationSlice';

export const UpdateCompleteDepreciation = (body) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`admin/depreciation/update`, body);

    if (!error) {
      return dispatch(updateCompleteDepreciationSuccess(result));
    }
    return dispatch(updateCompleteDepreciationFailure(error));
  };
};

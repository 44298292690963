import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true }

export const DeleteDepreciationListSlice = createSlice({
    name: 'DepreciationDelete',
    initialState,
    reducers: {
        DeleteDepreciationListRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        DeleteDepreciationListSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        DeleteDepreciationListFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
        // resetGetCategoryList: () => initialState
    }

})

export const { DeleteDepreciationListRequest, DeleteDepreciationListSuccess, DeleteDepreciationListFailure } = DeleteDepreciationListSlice.actions;

export default DeleteDepreciationListSlice.reducer;
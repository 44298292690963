import { FieldTimeOutlined, HistoryOutlined, UnorderedListOutlined } from '@ant-design/icons';
import {
  DashboardIcon,
  DepreciationIcon,
  PropertySettlementIcon,
  PropertyValuationsIcon,
  DashboardCouponIcon,
  DashboardNotificationIcon,
  DashboardLoneIcon,
  RentSummaryNoMarginIcon,
  UsersIcon,
} from '../assets/icons';

const NavigationConfig = [
  {
    title: 'Dashboard',
    path: 'dashboard',
    icon: <DashboardIcon />,
  },
  {
    title: 'Depreciation',
    path: 'depreciation/pending',
    icon: <DepreciationIcon />,
  },
  {
    title: 'Property Settlement',
    path: 'property-settlement/pending',
    icon: <PropertySettlementIcon />,
  },
  {
    title: 'Property Valuation',
    path: 'property-valuation',
    icon: <PropertyValuationsIcon />,
  },
  {
    title: 'Rent Summary',
    path: 'rent/pending',
    icon: <RentSummaryNoMarginIcon />,
  },
  {
    title: 'Users',
    path: 'users',
    icon: <UsersIcon />,
  },
  {
    title: 'User Loans',
    path: 'loans',
    icon: <DashboardLoneIcon />,
  },

  {
    title: 'Notification',
    path: 'notification',
    icon: <DashboardNotificationIcon />,
  },

  {
    title: 'Coupon',
    path: 'coupons',
    icon: <DashboardCouponIcon />,
  },
  {
    title: 'User Activities',
    path: 'users/activities',
    icon: <FieldTimeOutlined style={{ fontSize: 22, marginRight: '6px', marginLeft: '6px' }} />,
  },
  {
    title: 'Categories',
    path: 'categories',
    icon: <UnorderedListOutlined style={{ fontSize: 22, marginRight: '6px', marginLeft: '6px' }} />,
  },
  {
    title: 'Subscription History',
    path: 'subscription-history',
    icon: <HistoryOutlined style={{ fontSize: 22, marginRight: '6px', marginLeft: '6px' }} />,
  },
  // {
  //   title: 'Logout',
  //   path: 'logout',
  //   icon: <DashboardPoweroffIcon />,
  // },
  // {
  //   title: 'Market Value',
  //   path: 'market-value',
  // },
  // {
  //   title: 'Subscription History',
  //   path: 'subscription/history',
  // },
  // {
  //   title: 'Query',
  //   path: 'end-of-year/pending',
  //   icon: <PaperClipOutlined />,
  // },
];

export default NavigationConfig;

import { Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamMembersAsync, selectTaxAccountantTeamMembers, selectTaxAccountantTeamMembersLoading } from '../../container/reducers/slices/TaxAccountantDetails/TaxAccountantDetailsSlice';
import { UserTypes } from '../../components/common/userTypes';
import { useParams } from 'react-router-dom';

const TeamMembers = () => {
  const teamMembers = useSelector(selectTaxAccountantTeamMembers);
  const loading = useSelector(selectTaxAccountantTeamMembersLoading);

  const { id } = useParams();
  const dispatch = useDispatch();

  const defaultPagination = {
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  };

  const [pagination, setPagination] = useState(defaultPagination);

  useEffect(() => {
    dispatch(
      getTeamMembersAsync({
        id,
        skip: (Number(pagination.current) - 1) * pagination.pageSize,
        limit: pagination.pageSize,
      }),
    ).catch((error) => {
      notification.error({
        message: 'Failed to Fetch Team Members',
        description: error.message || 'Something went wrong while fetching team members.',
      });
    });
  }, [dispatch, id, pagination]);

  const handleTableChange = (newPagination) => {
    setPagination({
      ...pagination,
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // Optional: Add a render function if you want to add click handlers or formatting
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Role',
      dataIndex: 'user_type',
      key: 'user_type',
      render: (text) => {
        return text === UserTypes.ADMIN_SUB_TAXACCOUNTANT ? 'Admin' : 'Manager';
      },
    },
    {
      title: 'Managed Clients',
      dataIndex: 'managed_clients',
      key: 'managed_clients',
      render: (text) => text || '-',
    },
  ];

  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="dashboard_list">
          <Table
            columns={columns}
            pagination={{
              ...pagination,
              total: teamMembers?.count || 0,
            }}
            dataSource={teamMembers?.data}
            loading={loading}
            onChange={handleTableChange}
            scroll={{ x: 'max-content' }}
            rowKey={(record) => record.user_id || record.id} // Adjust based on your data
          />
        </div>
      </div>
    </div>
  );
};

export default TeamMembers;

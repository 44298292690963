import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDeletedUsers } from '../../../actions/DeletedUsers/DeletedUsersApi';

const initialState = {
  error: false,
  isLoading: true,
  data: [],
};

export const getDeletedUsersAsync = createAsyncThunk('DeletedUsers/getUsers', async (body) => {
  const response = await getDeletedUsers(body);
  return response;
});

export const DeletedUserSlice = createSlice({
  name: 'DeletedUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeletedUsersAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDeletedUsersAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getDeletedUsersAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectDeletedUsers = (state) => state.deletedUsers.data;
export const selectDeletedUsersLoading = (state) => state.deletedUsers.isLoading;

export default DeletedUserSlice.reducer;

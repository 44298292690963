import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { ToastContainer } from 'react-toastify';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import './assets/index';
import store from './container/store/store';
import AppRoutes from './routes';
import awsconfig from './aws-exports';

Amplify.configure({
  Auth: {
    Cognito: {
      region: awsconfig.REGION,
      userPoolId: awsconfig.USER_POOL_ID,
      userPoolClientId: awsconfig.USER_POOL_APP_CLIENT_ID,
      signUpVerificationMethod: 'code',
    },
  },
});
function App() {
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Authenticator.Provider>
        <Provider store={store}>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: 'Nunito',
                colorPrimary: '#56aaaa',
              },
            }}
          >
            <AppRoutes />
          </ConfigProvider>
        </Provider>
      </Authenticator.Provider>
    </>
  );
}

export default App;

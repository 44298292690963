import { deleteRequest, getRequest, putRequest } from '../../../utils/apiUtils';
import { GetMobileUnverifiedListFailure, GetMobileUnverifiedListSuccess } from '../../reducers/slices/MobileUnverifiedUsers/getMobileUnverifiedSlice';

export const GetMobileUnverified = () => {
  return async (dispatch) => {
    const { result, error } = await getRequest(`admin/unverified-phone-user`);
    if (!error) {
      return dispatch(GetMobileUnverifiedListSuccess(result));
    }
    return dispatch(GetMobileUnverifiedListFailure(error));
  };
};

export const verifiedMobile = (id) => {
  return async () => {
    const { result, error } = await putRequest(`admin/verified-phone-user/${id}`);
    return result || error;
  };
};

export const deleteUnverifiedMobileUser = (id) => {
  return async () => {
    const { result, error } = await deleteRequest(`admin/verified-phone-user/${id}`);
    return result || error;
  };
};

import { postRequest } from "../../../utils/apiUtils";
import {
    allValuationsFailure,
    allValuationsSuccess,
} from "../../reducers/slices/Valuation/allValuationsSlice";

export const GetAllValuations = (data) => {
    return async (dispatch) => {
        const { result, error } = await postRequest(`admin/property`, data);

        if (!error) {
            return dispatch(allValuationsSuccess(result));
        }

        return dispatch(allValuationsFailure(result));
    };
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = { settlementDatacomplete: [], error: false, isLoading: true };

export const getTaxAccountantListSlice = createSlice({
  name: 'UsersList',
  initialState,
  reducers: {
    GetTaxAccountantListSuccess: (state, action) => {
      state.settlementDatacomplete = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    GetTaxAccountantListFailure: (state, action) => {
      state.settlementDatacomplete = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { GetTaxAccountantListSuccess, GetTaxAccountantListFailure } = getTaxAccountantListSlice.actions;

export default getTaxAccountantListSlice.reducer;

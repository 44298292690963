import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import GoBack from '../../components/common/GoBack';
import { DiversEmailAction } from '../../container/actions/dashboard/diversemail.action';
import { SendNotification } from '../../utils/utilities';

const documentTypes = [
  {
    key: 'Rental Summary',
    value: 'rent',
  },
  {
    key: 'Property Settlement',
    value: 'settlement',
  },
  {
    key: 'Depreciation',
    value: 'depreciation',
  },
].sort((a, b) => (a.key.toUpperCase() - b.key.toUpperCase() ? 1 : -1));

const JunkEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const handleDocumentChange = (data) => {
    let body = {
      ...data,
      diverse_id: state.id,
    };

    dispatch(DiversEmailAction(body)).then(({ payload: { success, message } }) => {
      if (success) {
        SendNotification({ type: 'success', message });
        navigate(-1);
        return;
      }

      return SendNotification({ type: 'error', message: 'Something went wrong' });
    });
  };

  return (
    <Fragment>
      <div className="row align-items-center">
        <div className="col-6">
          <div className="text-center text-sm-start">
            <h5 className="m-0">Review Junk Email</h5>
          </div>
        </div>
        <div className="col-6">
          <div className="text-center text-sm-end">
            <GoBack />
          </div>
        </div>
      </div>

      <div className="schedule_wrapper">
        <div className="row">
          <div className="col-xl-6 col-md-6 col-12 mt-3">
            <div className="card">
              <div className="card-body">
                <div id="Iframe-Master-CC-and-Rs" className="set-margin set-padding set-border set-box-shadow center-block-horiz">
                  <div className="responsive-wrapper responsive-wrapper-wxh-572x612 docFile">
                    <iframe title="Document Viewer" src={state.file_path}></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Spin spinning={loadingPropertyListDataByUser || settlementByIdData.isLoading || categoryListReducer.isLoading}> */}
          <div className="col-xl-6 col-md-6 col-12 mt-3">
            <div className="card">
              <div className="card-body pb-0">
                <form onSubmit={handleSubmit(handleDocumentChange)}>
                  <Fragment>
                    <div className="row">
                      <div className="col-xl-6 col-md-6 col-12">
                        <div>
                          <label htmlFor="junk-email-type" className="form-label">
                            Document Type
                          </label>
                          <select
                            id="junk-email-type"
                            className="form-select"
                            name="mail_type"
                            {...register('mail_type', {
                              required: 'Please select valid document type',
                            })}
                          >
                            <option value="">Select document type</option>
                            {documentTypes.map(({ key, value }, i) => (
                              <option value={value}>{key}</option>
                            ))}
                          </select>
                        </div>
                        <p className="text-danger m-0">{errors?.mail_type?.message}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-2 col-2">
                        <div className="d-flex">
                          <div className="mt-3">
                            <button type="submit" className="btn_submit">
                              Submit
                              {/* {isUpdating ? (
                                                                <Spin
                                                                    indicator={
                                                                        <LoadingOutlined
                                                                            style={{
                                                                                color: "white",
                                                                                fontSize: 24,
                                                                            }}
                                                                            spin
                                                                        />
                                                                    }
                                                                />
                                                            ) : (
                                                                "Submit"
                                                            )} */}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                </form>
              </div>
            </div>
          </div>
          {/* </Spin> */}
        </div>
      </div>
    </Fragment>
  );
};

export default JunkEmail;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDashboardData, getDashboardUserData } from '../../../actions/dashboard/DashboardApi';

const initialState = {
  error: false,
  dashboardDataLoading: false,
  dashboardUsersDataLoading: false,
  dashboardData: [],
  userData: [],
};

export const getDashboardDataAsync = createAsyncThunk('Dashboard/getDashboardData', async () => {
  const response = await getDashboardData();
  return response;
});

export const getDashboardUserDataAsync = createAsyncThunk('Dashboard/getDashboardUserData', async (userFilter) => {
  const response = await getDashboardUserData(userFilter);
  return response;
});

export const DashboardSlice = createSlice({
  name: 'Dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardDataAsync.pending, (state, action) => {
        state.dashboardDataLoading = true;
      })
      .addCase(getDashboardDataAsync.fulfilled, (state, action) => {
        state.dashboardData = action.payload.data;
        state.error = action.payload.error;
        state.dashboardDataLoading = false;
      })
      .addCase(getDashboardDataAsync.rejected, (state, action) => {
        state.dashboardDataLoading = false;
        state.error = true;
      })
      .addCase(getDashboardUserDataAsync.pending, (state) => {
        state.dashboardUsersDataLoading = true;
      })
      .addCase(getDashboardUserDataAsync.fulfilled, (state, action) => {
        state.userData = action.payload.data;
        state.error = action.payload.error;
        state.dashboardUsersDataLoading = false;
      })
      .addCase(getDashboardUserDataAsync.rejected, (state, action) => {
        state.dashboardUsersDataLoading = false;
        state.error = true;
      });
  },
});

export const selectDashboardData = (state) => state.dashboard.dashboardData;
export const selectDashboardUserData = (state) => state.dashboard.userData;
export const selectDashboardDataLoading = (state) => state.dashboard.dashboardDataLoading;
export const selectDashboardUserDataLoading = (state) => state.dashboard.dashboardUsersDataLoading;

export default DashboardSlice.reducer;

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Form, Modal, notification, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import emailAddresses from 'email-addresses';
import { useDispatch } from 'react-redux';
import { sendTestNotificationAsync } from '../../container/reducers/slices/Notification/NotificationSlice';

const validateMobileNumber = (number) => {
  const mobileRegex = /^\+?(\d{1,3})?[-.\s]?(\d{10})$/;
  return mobileRegex.test(number);
};

const PreviewTemplate = ({ type, setType, open, setOpen, record, setRecord, isNotification, previewContent, setPreviewContent, subject }) => {
  const isEmail = type === 'email';
  const isSms = type === 'sms';
  const isPushNotification = type === 'pushNotification';

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [clientReady, setClientReady] = useState(false);

  const dispatch = useDispatch();

  // To disable submit button at the beginning.
  useEffect(() => {
    setClientReady(true);
  }, []);

  const content = useMemo(() => {
    if (isEmail) {
      if (isNotification) {
        return record?.email_templete_content;
      } else {
        return previewContent || record?.content;
      }
    }
    if (isSms) {
      return previewContent || record?.sms_templete_content || record?.content;
    }
    if (isPushNotification) {
      if (isNotification) {
        return record?.push_notification_templete_content;
      } else {
        return previewContent || record?.content;
      }
    }
  }, [isEmail, isSms, isPushNotification, previewContent, isNotification, record]);

  const title = useMemo(() => {
    if (isEmail) {
      if (record !== undefined) {
        if (isNotification) {
          return `Subject: ${record?.email_templete_subject}`;
        } else if (record?.subject) {
          return `Subject: ${record.subject}`;
        }
      } else if (subject) {
        return `Subject: ${subject}`;
      } else {
        return 'Email Template Preview';
      }
    }
    if (isSms) {
      return 'SMS Template Preview';
    }
    if (isPushNotification) {
      if (record) {
        if (isNotification) {
          return `Title: ${record?.push_notification_templete_subject}`;
        } else if (record?.subject) {
          return `Title: ${record.subject}`;
        }
      } else if (subject) {
        return `Title: ${subject}`;
      } else {
        return 'Push Notification Template Preview';
      }
    }
    return '';
  }, [isEmail, isSms, isPushNotification, subject, isNotification, record]);

  const handlePreviewClose = () => {
    setPreviewContent('');
    setOpen(false);
    setType('');
    setRecord(null);
  };

  // Custom validator for email addresses
  const validateEmails = (rule, value) => {
    if (!value || value.length === 0) {
      return Promise.resolve();
    }

    const invalidEmails = value.filter((email) => emailAddresses.parseOneAddress(email.trim()) === null);

    if (invalidEmails.length > 0) {
      return Promise.reject(new Error(`Invalid email address(es): ${invalidEmails.join(', ')}`));
    }

    return Promise.resolve();
  };

  const validateMobileNumbers = (rule, value) => {
    if (!value || value.length === 0) {
      return Promise.resolve();
    }

    const invalidNumbers = value.filter((num) => !validateMobileNumber(num.trim()));

    if (invalidNumbers.length > 0) {
      return Promise.reject(new Error(`Invalid mobile number(s): ${invalidNumbers.join(', ')}`));
    }

    return Promise.resolve();
  };

  const onFinish = (values) => {
    if ((isSms && values?.extra_phone_number?.length < 1) || (isEmail && values?.extra_emails?.length < 1)) {
      return;
    }

    const param = {
      ...values,
    };

    param.template_content = content;

    if (isEmail) {
      param.template_subject = subject || '';
    }

    setLoading(true);

    dispatch(sendTestNotificationAsync(param)).then((data) => {
      if (data.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: data.payload?.data?.message,
        });
      } else {
        notification.error({
          message: 'Failure',
          description: data.payload?.data?.message || 'Something went wrong!',
        });
      }
      setLoading(false);
    });
  };

  return (
    <Modal
      destroyOnClose
      footer={
        isPushNotification ? null : (
          <div style={{ textAlign: 'left' }}>
            <Form name="horizontal_form" form={form} layout="inline" onFinish={onFinish}>
              {isEmail && (
                <Form.Item
                  className="notificationSelect"
                  name={'extra_emails'}
                  label="Send Test Email To :"
                  style={{ width: '80%' }}
                  rules={[
                    {
                      validator: validateEmails,
                    },
                  ]}
                >
                  <Select mode="tags" tokenSeparators={[',']} allowClear placeholder="Enter comma-separated email addresses" />
                </Form.Item>
              )}

              {isSms && (
                <Form.Item
                  name="extra_phone_number"
                  label="Send Test SMS To :"
                  rules={[
                    {
                      validator: validateMobileNumbers,
                    },
                  ]}
                  style={{ width: '80%' }}
                >
                  <Select mode="tags" allowClear tokenSeparators={[',']} placeholder="Enter comma-separated mobile numbers with country code" />
                </Form.Item>
              )}

              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!clientReady || !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
                    loading={loading}
                  >
                    Send
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
        )
      }
      title={title}
      closeIcon={<CloseCircleOutlined className="modalCloseIcon" />}
      centered
      open={open}
      onCancel={() => handlePreviewClose()}
      width={1000}
    >
      <div className="templatePreviewParent">
        <div className="templatePreviewContent" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </Modal>
  );
};

export default PreviewTemplate;

PreviewTemplate.defaultProps = {
  open: false,
  setOpen: () => {},
  type: '',
  setType: () => {},
  record: undefined,
  setRecord: () => {},
  previewContent: '',
  setPreviewContent: () => {},
  subject: '',
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true }

export const DeleteSettlementListSlice = createSlice({
    name: 'SettlementDelete',
    initialState,
    reducers: {
        DeleteSettlementListRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        DeleteSettlementListSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        DeleteSettlementListFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
        // resetGetCategoryList: () => initialState
    }

})

export const { DeleteSettlementListRequest, DeleteSettlementListSuccess, DeleteSettlementListFailure } = DeleteSettlementListSlice.actions;

export default DeleteSettlementListSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getRentalSummaryTransectionByIdSlice = createSlice({
  name: 'GetTransactions',
  initialState,
  reducers: {
    getRentalSummaryTransectionByIdRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    getRentalSummaryTransectionByIdSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getRentalSummaryTransectionByIdFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
    getRentalSummaryByIdReset: (state, action) => {
      state.data = [];
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getRentalSummaryTransectionByIdRequest, getRentalSummaryTransectionByIdSuccess, getRentalSummaryTransectionByIdFailure, getRentalSummaryByIdReset } =
  getRentalSummaryTransectionByIdSlice.actions;

export default getRentalSummaryTransectionByIdSlice.reducer;

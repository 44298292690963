import { DatePicker, Form, Input, Spin, Table, notification, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { ReloadOutlined } from '@ant-design/icons';
import { getDeletedUsersAsync } from '../../container/reducers/slices/DeletedUsers/DeletedUserSlice';
import { getUserTypeLabel } from '../../utils/ConstLabel';
import { useDispatch } from 'react-redux';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const defaultFilter = {
  startDate: '',
  endDate: '',
  searchSlug: '',
};

const DeletedUsers = () => {
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });
  const [total, setTotal] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // Initialize filters and pagination from localStorage
  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem('deletedUsersFilter'));
    const storedPagination = JSON.parse(localStorage.getItem('deletedUsersPagination'));

    if (storedFilter) {
      filterForm.setFieldsValue({
        Search: storedFilter.searchSlug || '',
        startDate: storedFilter.startDate ? dayjs(storedFilter.startDate) : null,
        endDate: storedFilter.endDate ? dayjs(storedFilter.endDate) : null,
      });
      setFilter(storedFilter);
      setInputValue(storedFilter.searchSlug || '');
    } else {
      setFilter(defaultFilter);
    }

    if (storedPagination) {
      setPagination(storedPagination);
    }
  }, [filterForm]);

  // Fetch data when filters or pagination change
  useEffect(() => {
    if (filter !== null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, pagination, dispatch]);

  // Update localStorage when filters change
  useEffect(() => {
    if (filter !== null) {
      localStorage.setItem('deletedUsersFilter', JSON.stringify(filter));
    }
  }, [filter]);

  // Update localStorage when pagination changes
  useEffect(() => {
    if (pagination !== null) {
      localStorage.setItem('deletedUsersPagination', JSON.stringify(pagination));
    }
  }, [pagination]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await dispatch(
        getDeletedUsersAsync({
          ...filter,
          skip: (Number(pagination.current) - 1) * pagination.pageSize,
          limit: pagination.pageSize,
        }),
      );
      if (res?.payload?.error === false) {
        setUsersData(res?.payload?.data?.users);
        setTotal(res?.payload?.data?.count);
      } else {
        notification.error({
          message: 'Failure',
          description: 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Failure',
        description: error.message || 'Something went wrong!',
      });
    }
    setLoading(false);
  };

  const startDateChange = (value) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    if (value === null) {
      setFilter({ ...filter, startDate: '' });
      return;
    }

    if (value && dayjs(value).isValid() && filter.endDate && dayjs(filter.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be less than End Date.',
      });
      filterForm.setFieldsValue({ startDate: null });
      return;
    }

    const newStartDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setFilter({ ...filter, startDate: newStartDate });
  };

  const endDateChange = (value) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    if (value === null) {
      setFilter({ ...filter, endDate: '' });
      return;
    }

    if (value && dayjs(value).isValid() && filter.startDate && dayjs(filter.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'End Date should be greater than Start Date.',
      });
      filterForm.setFieldsValue({ endDate: null });
      return;
    }

    const newEndDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setFilter({ ...filter, endDate: newEndDate });
  };

  const SearchSlug = debounce((e) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setInputValue(e.target.value);
    const values = e.target.value.trim();
    setFilter({ ...filter, searchSlug: values });
  }, 300);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    // localStorage will be updated via useEffect
  };

  const propertyInvesterColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => text || '-',
    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
      key: 'user_type',
      render: (text, record) => {
        if (record.channel_partner_type) {
          return getUserTypeLabel(record.channel_partner_type);
        } else {
          return getUserTypeLabel(text);
        }
      },
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Deleted At',
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      render: (text) => dayjs(text).format('LLL') || '-',
    },
    {
      title: 'Number Of Property',
      dataIndex: 'properties',
      key: 'properties',
      render: (text) => text || '-',
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter(defaultFilter);
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setInputValue('');
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Deleted Users</h5>
          </div>
        </div>
        <div className="date_picker reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex  loans-header-responsive justify-content-between align-items-center gap-3">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input allowClear onChange={SearchSlug} value={inputValue} style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={Loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table
                columns={propertyInvesterColumn}
                pagination={{ ...pagination, total: total, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
                dataSource={usersData}
                scroll={{ x: 'max-content' }}
                onChange={handleTableChange}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default DeletedUsers;

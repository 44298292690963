import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, DatePicker, Form, Input, Modal, notification, Select, Spin } from 'antd';
import { GroupIcon } from '../../assets/icons';
import dayjs from 'dayjs';
import { getNotificationListAsync, saveNotificationAsync } from '../../container/reducers/slices/Notification/NotificationSlice';
import moment from 'moment';
import emailAddresses from 'email-addresses';
import { selectEmailTemplates, selectPushNotificationTemplates, selectSmsTemplates, selectTemplateLoading } from '../../container/reducers/slices/Notification/NotificationTemplateSlice';
import NotificationUsers from './NotificationUsers';
import AddTemplate from './AddTemplate';
import PreviewTemplate from './PreviewTemplate';
import './Notification.css';

const NewNotification = ({ open, setOpen }) => {
  // Chekckbox initial values
  const [emailTemplate, setEmailTemplate] = useState(true);
  const [smsTemplate, setSmsTemplate] = useState(false);
  const [pushNotificationTemplate, setPushNotificationTemplate] = useState(false);

  const [record, setRecord] = useState(null);

  //modal and drawer open states
  const [previewOpen, setPreviewOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [type, setType] = useState('');

  const [emailTemplateId, setEmailTemplateId] = useState(null);
  const [smsTemplateId, setSmsTemplateId] = useState(null);
  const [pushNotificationTemplateId, setPushNotificationTemplateId] = useState(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [emailErrorMessages, setEmailErrorMessages] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [emails, setEmails] = useState([]);
  const [mobiles, setMobiles] = useState([]);
  const [selectedTableRows, setSelectedTableRows] = useState([]);

  const templateLoading = useSelector(selectTemplateLoading);

  const onBoardUsersId = useMemo(() => {
    const toReturn = selectedTableRows?.filter((user) => user.key.startsWith('o'))?.map((user) => user.id);
    return toReturn;
  }, [selectedTableRows]);

  const userId = useMemo(() => {
    const toReturn = selectedTableRows?.filter((user) => user.key.startsWith('u'))?.map((user) => user.id);
    return toReturn;
  }, [selectedTableRows]);

  const [notificationForm] = Form.useForm();

  const dispatch = useDispatch();

  const handlePreviewEmail = () => {
    if (emailTemplateId) {
      setType('email');
      const selectedEmailTemplate = emailTemplateDropdown?.find((template) => template.value === emailTemplateId)?.record;
      setRecord(selectedEmailTemplate);
      setPreviewOpen(true);
    } else {
      notification.warning({
        message: 'Warning',
        description: 'Please select a email template to preview!',
      });
    }
  };

  const handlePreviewSMS = () => {
    if (smsTemplateId) {
      setType('sms');
      const selectedSmsTemplate = smsTemplateDropdown?.find((template) => template.value === smsTemplateId)?.record;
      setRecord(selectedSmsTemplate);
      setPreviewOpen(true);
    } else {
      notification.warning({
        message: 'Warning',
        description: 'Please select a sms template to preview!',
      });
    }
  };

  const handlePreviewPushNotification = () => {
    if (pushNotificationTemplateId) {
      setType('pushNotification');
      const selectedPushNotificationTemplate = pushNotificationDropdown?.find((template) => template.value === pushNotificationTemplateId)?.record;
      setRecord(selectedPushNotificationTemplate);
      setPreviewOpen(true);
    } else {
      notification.warning({
        message: 'Warning',
        description: 'Please select a push notification template to preview!',
      });
    }
  };

  const handleInputChange = (value) => {
    const parsedEmails = value?.map((email) => email.trim());
    const invalidEmails = parsedEmails?.filter((email) => emailAddresses.parseOneAddress(email) === null);
    if (invalidEmails?.length > 0) {
      setEmailErrorMessages(invalidEmails);
    } else {
      setEmailErrorMessages([]);
      setEmails(parsedEmails);
    }
  };

  const emailTemplates = useSelector(selectEmailTemplates);
  const smsTemplates = useSelector(selectSmsTemplates);
  const pushNotificationTemplates = useSelector(selectPushNotificationTemplates);

  const emailTemplateDropdown = useMemo(() => {
    return emailTemplates
      .map((template) => ({
        label: template.name,
        value: template.id,
        disabled: template.is_active === 0,
        record: template,
      }))
      .sort((a, b) => a.disabled - b.disabled);
  }, [emailTemplates]);

  const smsTemplateDropdown = useMemo(() => {
    return smsTemplates
      .map((template) => ({
        label: template.name,
        value: template.id,
        disabled: template.is_active === 0,
        record: template,
      }))
      .sort((a, b) => a.disabled - b.disabled);
  }, [smsTemplates]);

  const pushNotificationDropdown = useMemo(() => {
    return pushNotificationTemplates
      .map((template) => ({
        label: template.name,
        value: template.id,
        disabled: template.is_active === 0,
        record: template,
      }))
      .sort((a, b) => a.disabled - b.disabled);
  }, [pushNotificationTemplates]);

  const handleEmailTemplateDropdownChange = (option) => {
    setEmailTemplateId(option);
  };

  const handleSMSTemplateDropdownChange = (option) => {
    setSmsTemplateId(option);
  };

  const handlePushNotificationTemplateDropdownChange = (option) => {
    setPushNotificationTemplateId(option);
  };

  const handleTemplateChange = (option) => (e) => {
    if (option === 'emailTemplate') {
      if (e.target.checked) {
        setEmailTemplate(true);
      } else {
        notificationForm.setFieldValue('email_templete_id', undefined);
        setEmailTemplateId(null);
        setEmailTemplate(false);
      }
    }
    if (option === 'smsTemplate') {
      if (e.target.checked) {
        setSmsTemplate(true);
      } else {
        notificationForm.setFieldValue('sms_templete_id', undefined);
        setSmsTemplate(false);
        setSmsTemplateId(null);
      }
    }
    if (option === 'pushNotificationTemplate') {
      if (e.target.checked) {
        setPushNotificationTemplate(true);
      } else {
        notificationForm.setFieldValue('push_notification_templete_id', undefined);
        setPushNotificationTemplate(false);
        setPushNotificationTemplateId(null);
      }
    }
  };

  const validateSelectedUsers = () => {
    if (emails.length > 0 || mobiles.length > 0 || selectedRowKeys.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please select at least one user'));
  };

  const validateMobileNumber = (number) => {
    const mobileRegex = /^\+?(\d{1,3})?[-.\s]?(\d{10})$/;
    return mobileRegex.test(number);
  };

  const handleMobileChange = (value) => {
    const numbers = value.map((num) => num.trim());
    const errors = numbers.filter((num) => !validateMobileNumber(num));
    if (errors?.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);
      setMobiles(value);
    }
  };

  const handleModalClose = () => {
    setOpen(false);

    setErrorMessages([]);
    setEmailErrorMessages([]);
    setSelectedRowKeys([]);
    setSelectedTableRows([]);

    setEmailTemplate(true);
    setSmsTemplate(false);
    setPushNotificationTemplate(false);

    setEmailTemplateId(null);
    setSmsTemplateId(null);
    setPushNotificationTemplateId(null);

    notificationForm.resetFields();
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  const disabledTime = (date) => {
    if (date.isSame(moment(), 'day')) {
      const currentHour = moment().hour();
      const currentMinute = moment().minute() + 6;

      let adjustedHour = currentHour;
      let adjustedMinute = currentMinute;

      if (currentMinute >= 60) {
        adjustedHour = currentHour + 1;
        adjustedMinute = currentMinute % 60;
      }

      return {
        disabledHours: () => {
          const hours = [];
          for (let i = 0; i < adjustedHour; i++) {
            hours.push(i);
          }
          return hours;
        },
        disabledMinutes: (selectedHour) => {
          if (selectedHour === adjustedHour) {
            const minutes = [];
            for (let i = 0; i < adjustedMinute; i++) {
              minutes.push(i);
            }
            return minutes;
          }
          return [];
        },
      };
    }
    return {};
  };

  const handleSubmit = async (values) => {
    const { schedule_at } = values;
    const currentTime = dayjs();
    const scheduledTime = dayjs(schedule_at);
    const timeDifference = scheduledTime.diff(currentTime, 'minute');

    if (timeDifference < 5) {
      notification.warning({
        message: 'Warning',
        description: 'Please select a schedule time at least 5 minutes ahead of the current time!',
      });
      return;
    }

    delete values.user;

    if (emailTemplateId || smsTemplateId || pushNotificationTemplateId) {
      if (onBoardUsersId?.length === 0 && userId?.length === 0 && emails?.length === 0 && mobiles?.length === 0) {
        notification.warning({
          message: 'Warning',
          description: 'Please select atleast one user to send notification!',
        });
        return;
      }

      if (emailTemplateId && emails.length < 1 && userId.length < 1 && onBoardUsersId.length < 1) {
        notification.warning({
          message: 'Warning',
          description: 'Please add email address or select user to send email notification!',
        });
        return;
      }

      if (smsTemplateId && mobiles.length < 1 && userId.length < 1 && onBoardUsersId.length < 1) {
        notification.warning({
          message: 'Warning',
          description: 'Please add mobile number or select user to send sms notification!',
        });
        return;
      }

      if (pushNotificationTemplateId && userId.length < 1 && onBoardUsersId.length < 1) {
        notification.warning({
          message: 'Warning',
          description: 'Please select user to send push notification!',
        });
        return;
      }

      values.email_templete_id = emailTemplateId;
      values.sms_templete_id = smsTemplateId;
      values.push_notification_templete_id = pushNotificationTemplateId;

      values.users = userId;

      values.onboard_users = onBoardUsersId;

      setOpen(false);
      const res = await dispatch(saveNotificationAsync(values));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(getNotificationListAsync());
        handleModalClose();
        notificationForm.resetFields();
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } else {
      notification.warning({
        message: 'Warning',
        description: 'Please select atleast one template to send notification!',
      });
      return;
    }
  };

  return (
    <>
      <Modal footer={null} title={'Schedule Notification'} closeIcon={<CloseCircleOutlined className="modalCloseIcon" />} centered open={open} onCancel={handleModalClose} destroyOnClose width={1000}>
        <Spin spinning={templateLoading} size="large">
          <NotificationUsers
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            setSelectedTableRows={setSelectedTableRows}
            selectedTableRows={selectedTableRows}
          />
          <div className="row mt-3 emailTemplateForm ">
            <Form form={notificationForm} layout="vertical" requiredMark="default" onFinish={handleSubmit}>
              <div className="row justify-content-start">
                <div>
                  <Form.Item
                    name={'name'}
                    required
                    label="Notification Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the template name!',
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.length >= 255) {
                            return Promise.reject('Notification name should not exceed 255 characters');
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input maxLength={255} placeholder="Notification Name" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <Form.Item
                    label="Schedule Date and Time"
                    name="schedule_at"
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please select date and time!',
                      },
                    ]}
                  >
                    <DatePicker disabledDate={disabledDate} disabledTime={disabledTime} format="DD MMM YY, hh:mm A" showTime />
                  </Form.Item>
                </div>
                <div className="col-3">
                  <Form.Item
                    label="Select Users"
                    name="users"
                    required={emails.length === 0 && mobiles.length === 0 && selectedRowKeys.length === 0}
                    rules={[
                      {
                        validator: validateSelectedUsers,
                      },
                    ]}
                  >
                    <Button disabled={false} onClick={() => setDrawerOpen(true)} icon={<GroupIcon />} type="primary" className="inviteeButton">
                      Select Users {selectedRowKeys?.length}
                    </Button>
                  </Form.Item>
                </div>
              </div>

              <div className="col-12">
                <Form.Item
                  className="notificationSelect"
                  validateStatus={emailErrorMessages.length ? 'error' : 'success'}
                  help={emailErrorMessages.length ? 'Invalid email address(es) detected' : ''}
                  name={'extra_emails'}
                  label="Add more emails"
                >
                  <Select mode="tags" tokenSeparators={[',']} allowClear value={emails} onChange={handleInputChange} placeholder="Enter comma-separated email addresses" />
                </Form.Item>
                {emailErrorMessages.length > 0 && <Alert message="Validation Errors" description={`Invalid email addresses: ${emailErrorMessages.join(', ')}`} type="error" showIcon />}
              </div>
              <div className="col-12">
                <Form.Item
                  className="notificationSelect"
                  name={'extra_phone_number'}
                  label="Mobile Numbers"
                  validateStatus={errorMessages.length ? 'error' : 'success'}
                  help={errorMessages.length ? 'Invalid mobile number(s) detected' : ''}
                >
                  <Select mode="tags" allowClear tokenSeparators={[',']} value={mobiles} onChange={handleMobileChange} placeholder="Enter comma-separated mobile numbers with country code" />
                </Form.Item>
                {errorMessages.length > 0 && <Alert message="Validation Errors" description={`Invalid mobile numbers: ${errorMessages.join(', ')}`} type="error" showIcon />}
              </div>

              <div className="mb-4">
                <Checkbox checked={emailTemplate} onChange={handleTemplateChange('emailTemplate')}>
                  Email
                </Checkbox>
                <Checkbox checked={smsTemplate} onChange={handleTemplateChange('smsTemplate')}>
                  SMS
                </Checkbox>
                <Checkbox checked={pushNotificationTemplate} onChange={handleTemplateChange('pushNotificationTemplate')}>
                  Push Notification
                </Checkbox>
              </div>

              <div className="d-flex flex-column gap-3">
                {emailTemplate && (
                  <>
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="w-100">
                        <Form.Item
                          name={'email_templete_id'}
                          label="Email"
                          required
                          rules={[
                            {
                              required: true,
                              message: 'Please select email template!',
                            },
                          ]}
                        >
                          <Select showSearch allowClear placeholder="Select Email Template" onChange={handleEmailTemplateDropdownChange} options={emailTemplateDropdown} />
                        </Form.Item>
                      </div>
                      <div className="d-flex justify-content-center align-items-center alignButtons">
                        <Button
                          type="link"
                          className="ms-3"
                          onClick={() => {
                            setType('email');
                            setModalOpen(true);
                          }}
                        >
                          Add New
                        </Button>
                        {notificationForm.getFieldValue('email_templete_id') && (
                          <Button onClick={handlePreviewEmail} ghost type="primary" className="ms-3">
                            Preview
                          </Button>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {smsTemplate && (
                  <>
                    <div className="d-flex gap-1 justify-content-start align-items-center">
                      <div className="w-100">
                        <Form.Item
                          required
                          name={'sms_templete_id'}
                          label="SMS"
                          rules={[
                            {
                              required: true,
                              message: 'Please select sms template!',
                            },
                          ]}
                        >
                          <Select allowClear showSearch className="headerSelect" onChange={handleSMSTemplateDropdownChange} options={smsTemplateDropdown} placeholder="Select SMS Template" />
                        </Form.Item>
                      </div>
                      <div className="d-flex justify-content-center align-items-center alignButtons">
                        <Button
                          type="link"
                          className="ms-3"
                          ghost
                          onClick={() => {
                            setType('sms');
                            setModalOpen(true);
                          }}
                        >
                          Add New
                        </Button>
                        {notificationForm.getFieldValue('sms_templete_id') && (
                          <Button onClick={handlePreviewSMS} ghost type="primary" className="ms-3">
                            Preview
                          </Button>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {pushNotificationTemplate && (
                  <>
                    <div className="d-flex gap-1 justify-content-start align-items-center">
                      <div className="w-100">
                        <Form.Item
                          required
                          name={'push_notification_templete_id'}
                          label="Push Notification"
                          rules={[
                            {
                              required: true,
                              message: 'Please select push notification template!',
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            showSearch
                            className="headerSelect"
                            onChange={handlePushNotificationTemplateDropdownChange}
                            options={pushNotificationDropdown}
                            placeholder="Select Push Notification Template"
                          />
                        </Form.Item>
                      </div>
                      <div className="d-flex justify-content-center align-items-center alignButtons">
                        <Button
                          type="link"
                          className="ms-3"
                          ghost
                          onClick={() => {
                            setType('pushNotification');
                            setModalOpen(true);
                          }}
                        >
                          Add New
                        </Button>
                        {notificationForm.getFieldValue('push_notification_templete_id') && (
                          <Button onClick={handlePreviewPushNotification} ghost type="primary" className="ms-3">
                            Preview
                          </Button>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="d-flex justify-content-end align-items-center gap-3 mt-3">
                <Button disabled={false} onClick={handleModalClose} ghost size="medium" type="primary">
                  Cancel
                </Button>
                <div>
                  <Button htmlType="submit" size="medium" type="primary">
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Spin>
      </Modal>

      <AddTemplate notificationComponent={true} open={modalOpen} setOpen={setModalOpen} notificationPreview={previewOpen} setNotificationPreview={setPreviewOpen} type={type} />

      {!modalOpen && <PreviewTemplate record={record} setRecord={setRecord} type={type} open={previewOpen} setOpen={setPreviewOpen} />}
    </>
  );
};

export default NewNotification;

NewNotification.defaultProps = {
  open: false,
  setOpen: () => {},
};

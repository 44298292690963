import { getRequest } from '../../../utils/apiUtils';
import { getSettlementByIdFailure, getSettlementByIdSuccess, resetGetSettlementById } from '../../reducers/slices/settlement/settlementByIdSlice';

export const GetSettlementById = (id) => {
  return async (dispatch) => {
    dispatch(resetGetSettlementById());
    const { result, error } = await getRequest(`admin/fetch-settlement/${id}`);

    if (!error) {
      return dispatch(getSettlementByIdSuccess(result));
    }
    return dispatch(getSettlementByIdFailure(result));
  };
};

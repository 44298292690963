import { Input, Table, Tabs } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PencilIcon } from '../../assets/icons';
import { AllSettlements } from '../../container/actions/settlenents/allSettlements.action';
import { GetCompleteSettlements } from '../../container/actions/settlenents/getSettlements.action';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';
const Settlement = () => {
  const dispatch = useDispatch();
  const { status } = useParams();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState('1');
  const [settlementData, setSettlementData] = useState([]);
  const [settlementListComplete, setSettlementComplete] = useState([]);
  const [searchText, setSearchText] = useState('');

  const { allSettlementData, loadingAllSettlement } = useSelector((state) => state.allSettlementReducer);
  const { settlementDatacomplete } = useSelector((state) => state.settlementCompleteDataReducer);

  useEffect(() => {
    const search = JSON.parse(localStorage.getItem('settlementFilter'));
    setSearchText(search || '');
  }, []);

  // useEffect(() => {
  //   if (status === 'pending') {
  //     setActiveIndex('1');
  //   } else if (status === 'completed') {
  //     setActiveIndex('2');
  //   }
  // }, [status, navigate]);

  useEffect(() => {
    if (activeIndex === '1') {
      dispatch(AllSettlements());
    }
    if (activeIndex === '2') {
      dispatch(GetCompleteSettlements());
    }
  }, [dispatch, activeIndex]);

  useEffect(() => {
    if (allSettlementData) {
      if (allSettlementData?.result && Object.keys(allSettlementData?.result).length > 0) {
        const allSet = allSettlementData?.result?.map((res) => {
          return { ...res, key: crypto.randomUUID() };
        });
        setSettlementData(allSet);
      } else {
        setSettlementData([]);
      }
    }
    if (settlementDatacomplete?.result && Object.keys(settlementDatacomplete?.result).length > 0) {
      const allSetcomplete = settlementDatacomplete?.result?.map((res) => {
        return { ...res, key: crypto.randomUUID() };
      });
      setSettlementComplete(allSetcomplete);
    }
  }, [allSettlementData, settlementDatacomplete]);

  useEffect(() => {
    if (searchText !== '') {
      const filteredData = allSettlementData?.result?.filter((item) => {
        return item?.user?.email?.toLowerCase().includes(searchText.toLowerCase()) || item?.user?.virtual_email?.toLowerCase().includes(searchText.toLowerCase());
      });

      const filteredDataComplete = settlementDatacomplete?.result?.filter((item) => {
        return item?.user?.email?.toLowerCase().includes(searchText.toLowerCase()) || item?.user?.virtual_email?.toLowerCase().includes(searchText.toLowerCase());
      });

      setSettlementData(filteredData);
      setSettlementComplete(filteredDataComplete);
    } else {
      setSettlementData(allSettlementData?.result || []);
      setSettlementComplete(settlementDatacomplete?.result || []);
    }
  }, [searchText, allSettlementData, settlementDatacomplete]);

  const tableColumns = [
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => {
        return <>{record?.user?.email}</>;
      },
    },
    {
      title: 'User Unique ID',
      dataIndex: 'virtual_email',
      key: 'virtual_email',
      render: (_, record) => {
        return <>{record?.user?.virtual_email}</>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        return (
          <button className="btn btn-primary px-0 mt-xxl-0 mt-2" onClick={storeData}>
            <Link to={`/property-settlement/${status}/${record?.id}`}>
              <PencilIcon className="img-fluid" />
            </Link>
          </button>
        );
      },
    },
  ];

  const tableColumnscompleted = [
    {
      title: 'User Email',
      dataIndex: 'user',
      key: 'user_email',
      render: (_, record) => {
        return <>{record?.user?.email}</>;
      },
    },
    {
      title: 'User Unique ID',
      dataIndex: 'virtual_email',
      key: 'virtual_email',
      render: (_, record) => {
        return <>{record?.user?.virtual_email}</>;
      },
    },
    {
      title: 'Property Type',
      dataIndex: 'property_type',
      key: 'property_type',
      width: '20%',
      render: (_text, record) => {
        return getPropertyTypeLabel(record?.property?.property_type);
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        return (
          <button className="btn btn-primary px-0 mt-xxl-0 mt-2" onClick={storeData}>
            {' '}
            <Link to={`/property-settlement/${status}/${record?.id}`}>
              <PencilIcon className="img-fluid" />
            </Link>
          </button>
        );
      },
    },
  ];

  function storeData() {
    localStorage.setItem('settlementFilter', JSON.stringify(searchText));
  }

  const handleClick = (index, route) => {
    if (index !== activeIndex) {
      setSearchText('');
    }
    if (index === '1') {
      navigate(route);
      setActiveIndex(index);
    }
    if (index === '2') {
      navigate(route);
      setActiveIndex(index);
    }
  };

  return (
    <Fragment>
      <div className="row align-items-center">
        <div className="row align-items-center mt-2 custom-documentation">
          <div className="col-xl-6 col-md-6 col-sm-6 col-12">
            <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
              <h5 className="m-0">Property Settlement</h5>
            </div>
          </div>
          <div className="col-xl-6 col-md-6 col-sm-6 col-12 text-end custom-search-parent ">
            <Input.Search placeholder="Search by Email, Unique ID" allowClear value={searchText} size="middle" onChange={(e) => setSearchText(e.target.value)} className="custom-search" />
          </div>
        </div>
      </div>
      <div className="mt-3">
        <Tabs
          activeKey={activeIndex}
          onTabClick={(value) => {
            if (value === '1') {
              handleClick('1', '/property-settlement/pending');
            }
            if (value === '2') {
              handleClick('2', '/property-settlement/completed');
            }
          }}
        >
          <Tabs.TabPane tab="Pending" key="1">
            <div className="row mt-4">
              <div className="col-12">
                <div className="dashboard_list">
                  <Table columns={tableColumns} dataSource={settlementData} loading={loadingAllSettlement} />
                </div>
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Completed" key="2">
            <div className="row mt-4">
              <div className="col-12">
                <div className="dashboard_list">
                  <Table columns={tableColumnscompleted} dataSource={settlementListComplete} />
                </div>
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Fragment>
  );
};

export default Settlement;

import { deleteRequest, getRequest, putRequest } from '../../../utils/apiUtils';
import { GetUnconfirmedUsersListFailure, GetUnconfirmedUsersListSuccess } from '../../reducers/slices/UnconfirmedUsers/getUnconfirmedUsersSlice';

export const GetUnconfirmedUsers = (data) => {
  return async (dispatch) => {
    const { result, error } = await getRequest(`admin/unconfirmed-user`, data);
    if (!error) {
      return dispatch(GetUnconfirmedUsersListSuccess(result));
    }
    return dispatch(GetUnconfirmedUsersListFailure(error));
  };
};

export const confirmUser = (id) => {
  return async () => {
    const { result, error } = await putRequest(`admin/confirmed-user/${id}`);
    return result || error;
  };
};

export const deleteConfirmUser = (id) => {
  return async () => {
    const { result, error } = await deleteRequest(`admin/unconfirmed-user/${id}`);
    return result || error;
  };
};

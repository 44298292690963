import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

const GoBack = () => {
  const navigate = useNavigate();

  return <Button className="btn_goBack" onClick={() => navigate(-1)} icon={<LeftOutlined />} />;
};

export default GoBack;

import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const addChannelPartnerDataSlice = createSlice({
  name: 'AddChannelPartnerData',
  initialState,
  reducers: {
    addChannelPartnerDataRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    addChannelPartnerDataSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    addChannelPartnerDataFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { addChannelPartnerDataRequest, addChannelPartnerDataSuccess, addChannelPartnerDataFailure } = addChannelPartnerDataSlice.actions;

export default addChannelPartnerDataSlice.reducer;

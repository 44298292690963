import { createSlice } from "@reduxjs/toolkit";

const initialState = { checDepreciationSlice: [], error: false, isLoading: true };

export const checDepreciationSlice = createSlice({
    name: "Checkdepreciation",
    initialState,
    reducers: {
        checkDepreciationSuccess: (state, action) => {
            state.depreciationData = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        checkDepreciationFailure: (state, action) => {
            state.depreciationData = action.payload;
            state.error = true;
            state.isLoading = false;
        }
    }

})

export const { checkDepreciationSuccess, checkDepreciationFailure } = checDepreciationSlice.actions;

export default checDepreciationSlice.reducer;
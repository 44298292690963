import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addCommentsList, deleteCommentsList, getCommentsList } from '../../../actions/Users/getUsersComments.action';

const initialState = {
  error: false,
  isLoading: true,
  commentsList: [],
};

export const getCommentsListAsync = createAsyncThunk('UsersComments/getCommentsList', async (id) => {
  const response = await getCommentsList(id);
  return response;
});

export const addCommentsListAsync = createAsyncThunk('UsersComments/addCommentsList', async (payload) => {
  const response = await addCommentsList(payload);
  return response;
});

export const deleteCommentsListAsync = createAsyncThunk('UsersComments/deleteCommentsList', async ({ id }) => {
  const response = await deleteCommentsList(id);
  return response;
});

export const getUsersCommentsSlice = createSlice({
  name: 'UsersComments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCommentsListAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCommentsListAsync.fulfilled, (state, action) => {
        state.commentsList = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getCommentsListAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(deleteCommentsListAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCommentsListAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(deleteCommentsListAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(addCommentsListAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCommentsListAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(addCommentsListAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const getUsersCommentsList = (state) => state.UsersComments.commentsList;
export const getUsersCommentsLoading = (state) => state.UsersComments.isLoading;

export default getUsersCommentsSlice.reducer;

import { deleteRequest } from '../../../utils/apiUtils';
import { DeleteSettlementListFailure, DeleteSettlementListRequest, DeleteSettlementListSuccess } from '../../reducers/slices/settlement/deleteSettlementSlice';

export const DeleteSettlementList = (id) => {
  return async (dispatch) => {
    await dispatch(DeleteSettlementListRequest());
    const { result, error } = await deleteRequest(`admin/settlement/delete/${id}`);

    if (!error) {
      return await dispatch(DeleteSettlementListSuccess(result));
    }
    return await dispatch(DeleteSettlementListFailure(error));
  };
};

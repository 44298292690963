import { deleteRequest, postRequest, putRequest } from '../../../utils/apiUtils';

export const fetchUserCoupon = (filters) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`admin/coupons/list`, filters);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const addUserCoupon = (info) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`admin/coupons`, info);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const editUserCoupon = (id, info) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await putRequest(`admin/coupons/${id}`, info);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const changeCouponStatus = (id, info) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await putRequest(`admin/coupons-status/${id}`, info);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const deleteUserCoupon = (id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await deleteRequest(`admin/coupons/${id}`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export default function setAuthToken(type = null) {
  const accesToken = localStorage.getItem('accessToken');
  if (accesToken) {
    return {
      Accept: 'application/json',
      'Content-Type': type ? type : 'application/json',
      authorization: `Bearer ${accesToken}`,
      user: 1,
    };
  } else {
    return {
      'Content-Type': 'application/json',
    };
  }
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changeUserDiscountStatus, deleteUserDiscount, editUserDiscount, fetchUserDiscount, setUserDiscount } from '../../../actions/UserDiscount/UserDiscountApi';

const initialState = {
  error: false,
  isLoading: false,
  data: [],
};

export const fetchUserDiscountAsync = createAsyncThunk('UserDiscount/fetchDiscount', async (id) => {
  const response = await fetchUserDiscount(id);
  return response;
});

export const setUserDiscountAsync = createAsyncThunk('UserDiscount/setDiscount', async ({ id, info }) => {
  const response = await setUserDiscount(id, info);
  return response;
});

export const editUserDiscountAsync = createAsyncThunk('UserDiscount/editDiscount', async ({ userId, id, info }) => {
  const response = await editUserDiscount(userId, id, info);
  return response;
});

export const changeUserDiscountStatusAsync = createAsyncThunk('UserDiscount/changeDiscountStatus', async ({ userId, id, info }) => {
  const response = await changeUserDiscountStatus(userId, id, info);
  return response;
});

export const deleteUserDiscountAsync = createAsyncThunk('UserDiscount/deleteDiscount', async (id) => {
  const response = await deleteUserDiscount(id);
  return response;
});

export const UserDiscountSlice = createSlice({
  name: 'User Discount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDiscountAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserDiscountAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.error = false;
      })
      .addCase(fetchUserDiscountAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(setUserDiscountAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setUserDiscountAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;

        state.data = action.payload.data.result;
      })
      .addCase(setUserDiscountAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(editUserDiscountAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editUserDiscountAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
      })
      .addCase(editUserDiscountAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(changeUserDiscountStatusAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeUserDiscountStatusAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
      })
      .addCase(changeUserDiscountStatusAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(deleteUserDiscountAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUserDiscountAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
      })
      .addCase(deleteUserDiscountAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectUserDiscountLoading = (state) => state.userDiscount.isLoading;
export const selectUserDiscount = (state) => state.userDiscount.data;

export default UserDiscountSlice.reducer;

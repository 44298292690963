import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true}

export const getCategoryListSlice = createSlice({
    name: 'categoryList',
    initialState,
    reducers: {
        getCategoryListSuccess: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        getCategoryListFailure: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
            state.error = true;
        },
        resetGetCategoryList: () => initialState
    }

})

export const { getCategoryListSuccess, getCategoryListFailure, resetGetCategoryList } = getCategoryListSlice.actions;

export default getCategoryListSlice.reducer;
import { getRequest, postRequest } from '../../../utils/apiUtils';

export const getTeamMembers = (id, filters) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`admin/tax-accountant/team-members/${id}`, filters);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const getRegisteredClients = (id, filters) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`admin/tax-accountant/registered-clients/${id}`, filters);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const getManagedClients = (id, filters) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`admin/tax-accountant/managed-clients/${id}`, filters);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

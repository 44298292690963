import { getRequest, postRequest } from '../../../utils/apiUtils';

export const getDashboardData = () => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`/admin/dashboard`);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};
export const getDashboardUserData = (userFilter) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`/admin/dashboard-user-data`, userFilter);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

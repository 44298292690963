import { createSlice } from "@reduxjs/toolkit";

const initialState = { depreciationDatacomplete: [], error: false, isLoading: true };

export const depreciationCompleteSlice = createSlice({
    name: "depreciationcomplete",
    initialState,
    reducers: {
        CompleteDepreciationSuccess: (state, action) => {
            state.depreciationDatacomplete = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        CompleteDepreciationFailure: (state, action) => {
            state.depreciationDatacomplete = action.payload;
            state.error = true;
            state.isLoading = false;
        }
    }

})

export const { CompleteDepreciationSuccess, CompleteDepreciationFailure } = depreciationCompleteSlice.actions;

export default depreciationCompleteSlice.reducer;
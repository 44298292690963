import { postRequest } from '../../../utils/apiUtils';
import { CompleteRentSummaryFailure, CompleteRentSummarySuccess } from '../../reducers/slices/RentSummary/getCompleteSummarySlice';

export const GetCompleteSummaryList = (data) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`admin/fetch-rent/complete`, data);
    if (!error) {
      return dispatch(CompleteRentSummarySuccess(result));
    }
    return dispatch(CompleteRentSummaryFailure(result));
  };
};

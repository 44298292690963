import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFilterData } from '../../../actions/Notification/NotificationFilterApi';

const initialState = {
  error: false,
  isLoading: true,
  users: [],
  onBoardUsers: [],
};

export const getFilterDataAsync = createAsyncThunk('NotificationTemplate/getFilterData', async (type) => {
  const response = await getFilterData(type);
  return response;
});

export const NotificationTemplateFilters = createSlice({
  name: 'NotificationTemplateFilters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFilterDataAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFilterDataAsync.fulfilled, (state, action) => {
        state.users = action.payload.data.users.map((user) => {
          return {
            ...user,
            key: `u${user.id}`,
          };
        });
        state.onBoardUsers = action.payload.data.onboardUsers.map((user) => {
          return {
            ...user,
            key: `o${user.id}`,
          };
        });
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getFilterDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectUsers = (state) => state.notificationFilters.users;
export const selectOnBoardUsers = (state) => state.notificationFilters.onBoardUsers;
export const selectFilterDataLoading = (state) => state.notificationFilters.isLoading;

export default NotificationTemplateFilters.reducer;

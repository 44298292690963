import { createSlice } from "@reduxjs/toolkit";

const initialState = { settlementDatacomplete: [], error: false, isLoading: true };

export const settlementCompleteSlice = createSlice({
    name: "settlementcomplete",
    initialState,
    reducers: {
        CompleteSettlementSuccess: (state, action) => {
            state.settlementDatacomplete = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        CompleteSettlementFailure: (state, action) => {
            state.settlementDatacomplete = action.payload;
            state.error = true;
            state.isLoading = false;
        }
    }

})

export const { CompleteSettlementSuccess, CompleteSettlementFailure } = settlementCompleteSlice.actions;

export default settlementCompleteSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMortgageBrokers } from '../../../actions/Users/MortgageBrokerApi';

const initialState = {
  error: false,
  isLoading: false,
  data: [],
};

export const fetchUserMortgageBrokersAsync = createAsyncThunk('UserMortgageBroker/fetchData', async (filters) => {
  const response = await fetchMortgageBrokers(filters);
  return response;
});

export const UserMortgageBroker = createSlice({
  name: 'Mortgage Broker',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserMortgageBrokersAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserMortgageBrokersAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data.result;
      })
      .addCase(fetchUserMortgageBrokersAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectMortgageBrokersLoading = (state) => state.mortgageBrokers.isLoading;
export const selectMortgageBrokers = (state) => state.mortgageBrokers.data;

export default UserMortgageBroker.reducer;

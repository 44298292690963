import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteTemplate, saveTemplate, getTemplateList, editTemplate } from '../../../actions/Notification/NotificationTemplateApi';

const initialState = {
  templateData: {},
  error: false,
  isLoading: true,
  templateList: [],
  emailTemplates: [],
  smsTemplates: [],
  pushNotificationTemplates: [],
};

export const getTemplateListAsync = createAsyncThunk('NotificationTemplate/getTemplateList', async (type) => {
  const response = await getTemplateList(type);
  return response;
});

export const saveTemplateAsync = createAsyncThunk('NotificationTemplate/saveTemplate', async (body) => {
  const response = await saveTemplate(body);
  return response;
});

export const editTemplateAsync = createAsyncThunk('NotificationTemplate/editTemplate', async ({ id, newValues }) => {
  const response = await editTemplate(id, newValues);
  return response;
});

export const deleteTemplateAsync = createAsyncThunk('NotificationTemplate/deleteTemplate', async (id) => {
  const response = await deleteTemplate(id);
  return response;
});

export const NotificationTemplate = createSlice({
  name: 'NotificationTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveTemplateAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveTemplateAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(saveTemplateAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(getTemplateListAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTemplateListAsync.fulfilled, (state, action) => {
        state.templateList = action.payload.data;
        state.emailTemplates = action.payload.data.filter((item) => item.type === 1);
        state.smsTemplates = action.payload.data.filter((item) => item.type === 2);
        state.pushNotificationTemplates = action.payload.data.filter((item) => item.type === 3);
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getTemplateListAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(deleteTemplateAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTemplateAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(deleteTemplateAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectTemplateData = (state) => state.notificationTemplate.dashboardData;
export const selectEmailTemplates = (state) => state.notificationTemplate.emailTemplates;
export const selectSmsTemplates = (state) => state.notificationTemplate.smsTemplates;
export const selectPushNotificationTemplates = (state) => state.notificationTemplate.pushNotificationTemplates;
export const selectTemplateLoading = (state) => state.notificationTemplate.isLoading;

export default NotificationTemplate.reducer;

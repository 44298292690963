import { DatePicker, Form, Input, Spin, Table, notification, Button, Popover, Select, InputNumber, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GetUsersList } from '../../container/actions/Users/getUsers.action';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { FilterOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { PropertyType } from '../../components/common/userTypes';

const bankDropdown = [
  { label: 'Connected', value: 'CONNECTED' },
  { label: 'Not Connected', value: 'NOT_CONNECTED' },
  { label: 'Partial Connected', value: 'PARTIAL_CONNECTED' },
  { label: 'No Bank Added', value: 'NO_BANK' },
];

const propertyDropdown = [
  { label: 'Residential Investment', value: PropertyType.INVESTMENT_PROPERTY },
  { label: 'Residential Owner Occupied', value: PropertyType.OWNER_OCCUPIED_PROPERTY },
  { label: 'Commercial Investment', value: PropertyType.COMMERCIAL_PROPERTY },
];

const defaultFilter = {
  property_type: [],
  bank_connectivity: '',
  property_search_slug: '',
  userSearchSlug: '',
  number_of_properties: null,
  free_users: false,
  no_property: false,
  subscription_pending: false,
  inactive_users: false,
  startDate: '',
  endDate: '',
};

const UserPropertyInvestor = () => {
  const dispatch = useDispatch();
  const [filterForm] = Form.useForm();
  const [filters, setFilters] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });
  const [total, setTotal] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [userNameSearchValue, setUserNameSearchValue] = useState('');
  const [userLocationSearch, setUserLocationSearch] = useState('');
  const navigate = useNavigate();

  // Initialize filters and pagination from localStorage
  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem('userPropertyInvestorFilter'));
    const storedPagination = JSON.parse(localStorage.getItem('userPropertyInvestorPagination'));

    if (storedFilter) {
      if (storedFilter?.property_type?.length > 0) {
        storedFilter.property_type = propertyDropdown.filter((property) => storedFilter.property_type.includes(property.value)).map((property) => property.value);
      }
      filterForm.setFieldsValue({
        Search: storedFilter.property_search_slug || '',
        startDate: storedFilter.startDate ? dayjs(storedFilter.startDate) : null,
        endDate: storedFilter.endDate ? dayjs(storedFilter.endDate) : null,
        propertyType: storedFilter.property_type || [],
        bank_connectivity: storedFilter.bank_connectivity || undefined,
        numberOfProperties: storedFilter.number_of_properties || null,
        // Checkboxes
        free_users: storedFilter.free_users || false,
        no_property: storedFilter.no_property || false,
        subscription_pending: storedFilter.subscription_pending || false,
        pending_signup: storedFilter.pending_signup || false,
        inactive_users: storedFilter.inactive_users || false,
        userSearchSlug: storedFilter.userSearchSlug || '',
      });
      setUserLocationSearch(storedFilter.property_search_slug || '');
      setUserNameSearchValue(storedFilter.userSearchSlug || '');
      setFilters(storedFilter);
    } else {
      setFilters(defaultFilter);
    }

    if (storedPagination) {
      setPagination(storedPagination);
    }
  }, [filterForm]);

  // Fetch data when filters or pagination change
  useEffect(() => {
    if (filters !== null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination, dispatch]);

  // Update localStorage when filters change
  useEffect(() => {
    if (filters !== null) {
      localStorage.setItem('userPropertyInvestorFilter', JSON.stringify(filters));
    }
  }, [filters]);

  // Update localStorage when pagination changes
  useEffect(() => {
    if (pagination !== null) {
      localStorage.setItem('userPropertyInvestorPagination', JSON.stringify(pagination));
    }
  }, [pagination]);

  const fetchData = async () => {
    setLoading(true);
    const params = {
      ...filters,
      skip: (Number(pagination.current) - 1) * pagination.pageSize,
      limit: pagination.pageSize,
    };

    await dispatch(GetUsersList(params)).then((res) => {
      if (res?.payload?.success === true) {
        setUsersData(res.payload.result.users);
        setTotal(res.payload.result.count);
      } else {
        notification.error({
          message: 'Failure',
          description: 'Something went wrong!',
        });
      }
      setLoading(false);
    });
  };

  const startDateChange = (value) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });

    if (value === null) {
      setFilters({ ...filters, startDate: '' });
      return;
    }

    if (value && dayjs(value).isValid() && filters.endDate && dayjs(filters.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be less than End Date.',
      });
      filterForm.setFieldsValue({ startDate: null });
      return;
    }

    const newStartDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setFilters({ ...filters, startDate: newStartDate });
  };

  const endDateChange = (value) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });

    if (value === null) {
      setFilters({ ...filters, endDate: '' });
      return;
    }

    if (value && dayjs(value).isValid() && filters.startDate && dayjs(filters.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'End Date should be greater than Start Date.',
      });
      filterForm.setFieldsValue({ endDate: null });
      return;
    }

    const newEndDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setFilters({ ...filters, endDate: newEndDate });
  };

  const SearchSlug = debounce((e) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setUserNameSearchValue(e.target.value);
    const values = e.target.value.trim();
    setFilters({ ...filters, userSearchSlug: values });
  }, 300);

  const propertySearchSlug = debounce((e) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setUserLocationSearch(e.target.value);
    const values = e.target.value.trim();
    setFilters({ ...filters, property_search_slug: values });
  }, 300);

  const handleView = (record) => {
    navigate(`/user/property-investor/detail/${record.id}`);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    // localStorage will be updated via useEffect
  };

  const propertyInvestorColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <span className="userColumnName" onClick={() => handleView(record)}>
          {record?.name}
        </span>
      ),
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'User Unique Email',
      dataIndex: 'virtual_email',
      key: 'virtual_email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Number Of Property',
      dataIndex: 'property_count',
      key: 'property_count',
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilters(defaultFilter);
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setUserLocationSearch('');
    setUserNameSearchValue('');
    // localStorage will be updated via useEffect
  };

  const propertyTypeChange = (option) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setFilters({ ...filters, property_type: option });
  };

  const bankDropdownChange = (option) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setFilters({ ...filters, bank_connectivity: option });
  };

  const propertyNumberChange = debounce((newValue) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setFilters({ ...filters, number_of_properties: newValue });
  }, 300);

  const handleChange = (option) => (e) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setFilters({ ...filters, [option]: e.target.checked });
  };

  const getUserFilters = () => {
    return (
      <Form form={filterForm} requiredMark="default" layout="vertical" className="d-flex justify-content-center flex-column gap-3 w-100">
        <div className="filterLayout row gx-5 gy-2">
          <div className="reset-width-100">
            <Form.Item label="User" name="userSearchSlug" className="p-1 m-0">
              <Input value={userNameSearchValue} allowClear onChange={SearchSlug} style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
            </Form.Item>
          </div>
          <div className="reset-width-100 mb-0">
            <Form.Item label="Location" name="Search" className="p-1 m-0">
              <Input placeholder="Location" className="" allowClear value={userLocationSearch} suffix={<SearchOutlined />} onChange={propertySearchSlug} />
            </Form.Item>
          </div>
          <div className="reset-width-100">
            <Form.Item label="Start Date" name="startDate" className="p-0 m-0">
              <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
            </Form.Item>
          </div>
          <div className="reset-width-100">
            <Form.Item label="End Date" name="endDate" className="p-0 m-0">
              <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
            </Form.Item>
          </div>
          <div className="reset-width-100">
            <Form.Item className="categorySelect" label="Property Type" name="propertyType">
              <Select
                mode="multiple"
                allowClear
                className="categorySelect"
                maxTagCount={1}
                maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
                style={{ width: '100%' }}
                placeholder="Please select property type"
                onChange={propertyTypeChange}
                options={propertyDropdown}
              />
            </Form.Item>
          </div>
          <Form.Item label="Bank" name="bank_connectivity">
            <Select allowClear style={{ width: '100%' }} placeholder="Please select bank type" onChange={bankDropdownChange} options={bankDropdown} />
          </Form.Item>

          <Form.Item label="Number of Properties" name="numberOfProperties">
            <InputNumber min={0} onChange={propertyNumberChange} placeholder="Number of properties" style={{ width: '100%', height: '35px' }} />
          </Form.Item>
        </div>

        <div className="d-flex flex-column gap-1">
          <Checkbox checked={filters?.free_users} onChange={handleChange('free_users')}>
            Free Users
          </Checkbox>
          <Checkbox checked={filters?.no_property} onChange={handleChange('no_property')}>
            No Property
          </Checkbox>
          <Checkbox checked={filters?.subscription_pending} onChange={handleChange('subscription_pending')}>
            Subscription pending
          </Checkbox>
          <Checkbox checked={filters?.inactive_users} onChange={handleChange('inactive_users')}>
            Inactive Users
          </Checkbox>
        </div>
      </Form>
    );
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Property Investors</h5>
          </div>
        </div>
        <div className="date_picker reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex loans-header-responsive justify-content-end align-items-center gap-3">
              {/* <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="userSearchSlug" className="p-1 m-0">
                    <Input allowClear onChange={SearchSlug}  style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div> */}
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </div>
              <div className="reset-width-100">
                <Popover
                  overlayClassName="property-investors-filter"
                  placement="bottom"
                  content={<div className="p-2">{getUserFilters()}</div>}
                  trigger={['click']}
                  open={visibleFilter}
                  onOpenChange={() => setVisibleFilter(!visibleFilter)}
                >
                  <Button icon={<FilterOutlined />} type="primary" size="large">
                    Filter
                  </Button>
                </Popover>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={Loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table
                columns={propertyInvestorColumn}
                pagination={{ ...pagination, total: total, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
                dataSource={usersData}
                scroll={{ x: 'max-content' }}
                onChange={handleTableChange}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default UserPropertyInvestor;

import { Drawer } from 'antd';
import React from 'react';
import UserDiscount from './UserDiscount';

export default function DiscountModal({ openDiscount = false, setOpenDiscount = () => {} }) {
  return (
    <Drawer destroyOnClose title="Subscription Discount" placement="right" onClose={() => setOpenDiscount(false)} open={openDiscount} width={1200}>
      <UserDiscount />
    </Drawer>
  );
}

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Popconfirm, Row, Select, Space, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SmallLoader from '../../components/common/SmallLoader';
import { GetDepreciationById } from '../../container/actions/depreciation/getDepreciation.action';
import { SendNotification } from '../../utils/utilities';
import * as yup from 'yup';
import { resetDepreciationById } from '../../container/reducers/slices/Depreciation/depreciationByIdSlice';
import { ResetPropertyListByUser } from '../../container/reducers/slices/Property/propertyListByUserSlice';
import GoBack from '../../components/common/GoBack';
import { GetAllDepreciation } from '../../container/actions/depreciation/getAllDepreciation.action';
import { DeleteDepreciationList } from '../../container/actions/depreciation/deleteDepreciation.action';

import { DepreciationMethod, OCRStatus } from '../../components/common/userTypes';
import { UpdateDepreciation } from '../../container/actions/depreciation/updateDepreciation.action';
import dayjs from 'dayjs';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';
import { CopyOutlined } from '@ant-design/icons';
import { UpdateCompleteDepreciation } from '../../container/actions/depreciation/updateCompeleteDepreciation.action';

const text = 'Are you sure you want to discard?';

const DepreciationSchedule = () => {
  const [depreciationForm] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const depreciationData = [{}];

  const [financialYearEndingData, setFinancialYearEndingData] = useState([]);
  const [Total, setTotal] = useState(0);

  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const depreciationFormSchema = yup.object({
    property_id: yup.string().required('Please select property').nullable(),
    depreciable_amount: yup
      .string()
      .required('Depreciable amount is required')
      .test('depreciable_amount', `Enter valid amount`, function () {
        const { depreciable_amount } = this.parent;

        if (depreciable_amount === null || !Number.isFinite(1 / depreciable_amount)) {
          return false;
        }

        return true;
      })
      .nullable(),
    amount_claimable: yup.string().nullable(),
  });

  const yupSync = {
    async validator({ field }, value) {
      await depreciationFormSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const { depreciationByIdData, loadingDepreciationById } = useSelector((state) => state.depreciationByIdReducer);

  const propartyOptions = useMemo(
    () =>
      depreciationByIdData?.result?.user?.properties?.map((item) => {
        return { key: item.id, value: item.id, label: `${item.street_number} - ${item.street_name}`, property_type: item.property_type };
      }),
    [depreciationByIdData],
  );

  useEffect(() => {
    dispatch(GetDepreciationById(Number(id)));
    return () => {
      dispatch(resetDepreciationById());
      dispatch(ResetPropertyListByUser());
    };
  }, [id, dispatch]);

  useEffect(() => {
    async function setVales() {
      if (depreciationByIdData?.result !== null && depreciationByIdData?.result !== undefined && Object?.keys(depreciationByIdData?.result)?.length > 0) {
        if (![OCRStatus.OCR_FAILED, OCRStatus.OCR_PENDING].includes(depreciationByIdData?.result?.ocr_status) && depreciationByIdData?.result?.depreciable_amount) {
          setFinancialYearEndingData(depreciationByIdData?.result?.depreciationFinancialYears);

          depreciationForm.setFieldsValue({
            virtual_email: depreciationByIdData?.result?.user?.virtual_email,
            Financial_year_ending: dayjs(new Date()),
            depreciation_method:
              depreciationByIdData?.result?.property?.depreciation_method === DepreciationMethod?.PRIME_COST_DEPRECIATION
                ? 'Prime Cost'
                : depreciationByIdData?.result?.property?.depreciation_method === DepreciationMethod?.WDV_DEPRECIATION
                ? 'Diminishing Value'
                : '',
            financial_year_arr: depreciationByIdData?.result?.depreciationFinancialYears?.map((item) => {
              return { ...item, financial_date: dayjs(item.financial_date).format('DD-MM-YYYY') };
            }),
            address: `${depreciationByIdData?.result?.property?.street_number} ${depreciationByIdData?.result?.property?.street_name} ${depreciationByIdData?.result?.property?.suburb} ${depreciationByIdData?.result?.property?.postcode}`,
            depreciable_amount: depreciationByIdData?.result?.depreciable_amount,
            construction_date: dayjs(depreciationByIdData?.result?.construction_date),
            property_type: getPropertyTypeLabel(depreciationByIdData?.result?.property?.property_type),
            property_id: depreciationByIdData?.result?.property?.id,
          });

          setTotal(depreciationByIdData?.result?.depreciable_amount);
        } else {
          setFinancialYearEndingData(initialValues());
          depreciationForm.setFieldsValue({
            virtual_email: depreciationByIdData?.result?.user?.virtual_email,
            Financial_year_ending: dayjs(new Date()),
            construction_date: dayjs(new Date()),
            depreciation_method:
              depreciationByIdData?.result?.property?.depreciation_method === DepreciationMethod.PRIME_COST_DEPRECIATION
                ? 'Prime Cost'
                : depreciationByIdData?.result?.property?.depreciation_method === DepreciationMethod.WDV_DEPRECIATION
                ? 'Diminishing Value'
                : '',
            financial_year_arr: initialValues(),
          });

          changeDate(new Date());
        }
      }
    }
    setVales();
    // eslint-disable-next-line
  }, [depreciationByIdData]);

  const valueChange = () => {
    const fin_total = depreciationForm?.getFieldsValue()?.financial_year_arr?.reduce((acc, item) => acc + Number(item?.amount), 0);
    const amount_claimable = depreciationForm?.getFieldsValue()?.amount_claimable ?? 0;
    setTotal(fin_total + Number(amount_claimable));
  };

  const handleFormSubmit = async (data) => {
    setFormSubmitting(true);
    const [{ virtual_email, Depreciation_Id }] = depreciationData;
    const { construction_date, property_id, amount_claimable, depreciable_amount, Financial_year_ending, financial_year_arr } = data;
    const getFinancialYearData = () => {
      return financial_year_arr.map((item, i) => {
        return {
          ...item,
          financial_date: item.financial_date.split('-').reverse().join('-'),
          amount: item?.amount ? parseFloat(item?.amount).toFixed(2) : 0,
          fourty_division_amount: item?.fourty_division_amount ? parseFloat(item?.fourty_division_amount).toFixed(2) : 0,
          fourtythree_division_amount: item?.fourtythree_division_amount ? parseFloat(item?.fourtythree_division_amount).toFixed(2) : 0,
        };
      });
    };

    let body = {
      email: virtual_email,
      property_id,
      construction_date: dayjs(construction_date).format('YYYY-MM-DD'),
      depreciable_amount,
      depreciation_id: Depreciation_Id,
      id: Number(depreciationByIdData?.result?.id),
      claimable_amount: parseFloat(amount_claimable) ? parseFloat(amount_claimable).toFixed(2) : 0,
      ending_year: dayjs(Financial_year_ending).format('YYYY-MM-DD'),
      financial_year_arr: [...getFinancialYearData()],
    };

    if (Number(Total).toFixed(2) === Number(depreciable_amount).toFixed(2)) {
      let response;
      if (![OCRStatus.OCR_FAILED, OCRStatus.OCR_PENDING].includes(depreciationByIdData?.result?.ocr_status) && depreciationByIdData?.result?.depreciable_amount) {
        response = await dispatch(UpdateCompleteDepreciation(body));
      } else {
        response = await dispatch(UpdateDepreciation(body));
      }
      if (response?.payload?.success) {
        SendNotification({ type: 'success', message: response.payload.message || 'Depreciation data updated' });
        setFormSubmitting(false);
        navigate('/depreciation/pending');
      } else {
        SendNotification({ type: 'error', message: response?.payload?.message || 'Something went wrong' });
        setFormSubmitting(false);
      }
    } else {
      setFormSubmitting(false);
      SendNotification({
        type: 'error',
        message: 'Please check financial year Amount does not equal to depreciation Amount.',
      });
    }
  };

  const discardRecord = (id) => {
    setFormSubmitting(true);
    dispatch(DeleteDepreciationList(id, 'pending')).then((response) => {
      if (response?.payload?.success) {
        SendNotification({ type: 'success', message: response.payload.message || 'Depreciation deleted successfully' });
        dispatch(GetAllDepreciation());
        navigate('/depreciation/pending', { replace: true });
        setFormSubmitting(false);
      } else {
        SendNotification({ type: 'error', message: response?.payload?.message || 'Something went wrong' });
        setFormSubmitting(false);
      }
    });
  };

  const initialValues = () => {
    return Array(41)
      .fill()
      .map((_, i) => {
        return {
          financial_date: dayjs().format('DD-MM-YYYY'),
          amount: '',
        };
      });
  };

  const handleReset = () => {
    const fields = depreciationForm.getFieldsValue();
    const resetFields = fields.financial_year_arr.map((item) => ({
      financial_date: item.financial_date,
      fourty_division_amount: '',
      fourtythree_division_amount: '',
      amount: '',
    }));
    depreciationForm.setFieldsValue({
      financial_year_arr: resetFields,
    });
    depreciationForm.setFieldsValue({
      amount_claimable: '',
      fourty_division_amount: '',
      fourtythree_division_amount: '',
    });
    setTotal(0);
  };

  const onPropertyCkeck = (e, data) => {
    depreciationForm.setFieldsValue({
      property_type: getPropertyTypeLabel(data?.property_type),
    });
    const propartyData = depreciationByIdData?.result?.user?.properties?.find((ele) => ele.id === e);

    depreciationForm.setFieldsValue({
      address: `${propartyData.street_number}  ${propartyData.street_name}  ${propartyData.suburb} ${propartyData.postcode}`,
      depreciation_method:
        propartyData.depreciation_method === DepreciationMethod.PRIME_COST_DEPRECIATION
          ? 'Prime Cost'
          : propartyData.depreciation_method === DepreciationMethod.WDV_DEPRECIATION
          ? 'Diminishing Value'
          : '',
    });
  };

  const changeDate = (e) => {
    let date = dayjs(e);
    let year = date.format('YYYY');
    const Ddate = dayjs(new Date(year, 5, 30));
    let Kdate = dayjs(new Date(year, 5, 30));
    const Financial_year_ending = date.isAfter(Ddate) ? Kdate.add(1, 'year') : Kdate;

    const financial_year_arr = depreciationForm?.getFieldsValue()?.financial_year_arr?.map((item, index) => {
      return {
        ...item,
        financial_date: Financial_year_ending.add(index, 'year').format('DD-MM-YYYY'),
      };
    });

    depreciationForm.setFieldsValue({ Financial_year_ending, financial_year_arr });
  };

  const valueChangeDivision = (index) => {
    const values = depreciationForm.getFieldsValue();
    const fourtyValue = parseFloat(values.financial_year_arr?.[index]?.fourty_division_amount) || 0;
    const fourtyThreeValue = parseFloat(values.financial_year_arr?.[index]?.fourtythree_division_amount) || 0;

    const total = fourtyValue + fourtyThreeValue;

    depreciationForm.setFields([
      {
        name: ['financial_year_arr', index, 'amount'],
        value: total.toFixed(2),
      },
    ]);
    valueChange();
  };

  const valueCopyFourtyDivision = () => {
    const financialYearData = depreciationForm.getFieldValue('financial_year_arr') || [];

    const fourtyValue = parseFloat(financialYearData?.[0]?.fourty_division_amount) || 0;

    const updatedData = financialYearData.map((item) => ({
      ...item,
      fourty_division_amount: fourtyValue,
      amount: fourtyValue || item.fourtythree_division_amount ? Number(fourtyValue) + Number(item.fourtythree_division_amount || 0) : '',
    }));

    depreciationForm.setFieldsValue({
      financial_year_arr: updatedData,
    });

    valueChange();
  };

  const valueCopyFourtyThreeDivision = () => {
    const financialYearData = depreciationForm.getFieldValue('financial_year_arr') || [];

    const fourtyThreeValue = parseFloat(financialYearData?.[0]?.fourtythree_division_amount) || 0;

    const updatedData = financialYearData.map((item) => ({
      ...item,
      fourtythree_division_amount: fourtyThreeValue,
      amount: fourtyThreeValue || item.fourty_division_amount ? Number(fourtyThreeValue) + Number(item.fourty_division_amount || 0) : '',
    }));

    depreciationForm.setFieldsValue({
      financial_year_arr: updatedData,
    });
    valueChange();
  };
  if (loadingDepreciationById) {
    return <SmallLoader />;
  }
  return (
    <>
      <Row>
        <GoBack />
        <h5 className="m-0">Depreciation Schedule</h5>
      </Row>

      <div className="schedule_wrapper">
        <Spin spinning={isFormSubmitting}>
          <div className="row">
            <div className="col-xl-6 col-md-6 col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div id="Iframe-Master-CC-and-Rs" className="set-margin set-padding set-border set-box-shadow center-block-horiz">
                    <div className="responsive-wrapper responsive-wrapper-wxh-572x612 docFile">
                      <iframe title="Receipt" src={depreciationByIdData?.result?.receipt_path}></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-md-6 col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <Form
                    form={depreciationForm}
                    layout="vertical"
                    name="form"
                    initialValues={{
                      financial_year_arr: financialYearEndingData,
                    }}
                    onFinish={handleFormSubmit}
                  >
                    <div className="row">
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-4">
                          <Form.Item label="User Unique Email" name="virtual_email">
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="">
                          <Form.Item
                            name="property_id"
                            label="Property"
                            rules={[
                              {
                                required: true,
                                message: 'Please Select Property',
                              },
                            ]}
                          >
                            <Select
                              onChange={(e, data) => onPropertyCkeck(e, data)}
                              options={propartyOptions || []}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                            ></Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-4">
                          <Form.Item label="Property Address" name="address">
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-4 schedule_method">
                          <Form.Item label="Depreciation Method" name="depreciation_method">
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="">
                          <Form.Item label="Construction Date or Purchase Date" name="construction_date">
                            <DatePicker format="DD-MM-YYYY" onChange={(e) => changeDate(e)} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <Form.Item label="Total Depreciable Amount" name="depreciable_amount" rules={[yupSync]}>
                          <Input autoComplete="off" />
                        </Form.Item>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <Form.Item label="Property Type" name="property_type">
                          <Input className="form-control p-1 px-2" style={{ color: 'black' }} disabled={true} placeholder="property Type" type="text" />
                        </Form.Item>
                      </div>

                      <div className="row">
                        <div className="col-xl-4 col-md-4 col-12">
                          <label>Financial Year Ending</label>
                        </div>

                        <div className="col-xl-8 col-md-8 col-12">
                          <Row gutter={16} className="mb-2">
                            <Col span={8}>
                              <label>Division 40 </label>
                              <Button type="dashed" size="small" icon={<CopyOutlined />} onClick={valueCopyFourtyDivision}>
                                Copy
                              </Button>
                            </Col>
                            <Col span={8}>
                              <label>Division 43 </label>
                              <Button type="dashed" size="small" icon={<CopyOutlined />} onClick={valueCopyFourtyThreeDivision}>
                                Copy
                              </Button>
                            </Col>
                            <Col span={8}>
                              <label>Amount Claimable</label>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className="row overflow__scroll">
                      <Form.List name="financial_year_arr">
                        {(fields) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Fragment key={key}>
                                <div className="col-xl-4 col-md-4 col-12">
                                  <Form.Item {...restField} name={[name, 'financial_date']}>
                                    <Input disabled={true} />
                                  </Form.Item>
                                </div>
                                <div className="col-xl-8 col-md-8 col-12">
                                  <div className="position-relative">
                                    <Space.Compact block>
                                      <Form.Item {...restField} name={[name, 'fourty_division_amount']} defaultValue={0} rules={[{ required: false, message: 'Amount is required' }]}>
                                        <Input autoComplete="off" className="onchange" onChange={() => valueChangeDivision(name)} />
                                      </Form.Item>
                                      <Form.Item {...restField} name={[name, 'fourtythree_division_amount']} defaultValue={0} rules={[{ required: false, message: 'Amount is required' }]}>
                                        <Input autoComplete="off" className="onchange" onChange={() => valueChangeDivision(name)} />
                                      </Form.Item>
                                      <Form.Item {...restField} name={[name, 'amount']}>
                                        <Input className="onchange" disabled />
                                      </Form.Item>
                                    </Space.Compact>
                                  </div>
                                </div>
                              </Fragment>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div>
                        <div className="d-flex mt-3 align-items-center justify-content-center">
                          <label htmlFor="">Depreciation Total :</label>
                          <h4 className="mb-1">
                            <small className="text-muted">{parseFloat(Total).toFixed(2)}</small>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="mt-3 me-2">
                        {!depreciationData[0]?.Property_Id ? (
                          <div className="justify-content-start text-start">
                            <Popconfirm placement="left" title={text} onConfirm={() => discardRecord(id)} okText="Yes" cancelText="No">
                              <Button danger ghost>
                                Discard
                              </Button>
                            </Popconfirm>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="d-flex gap-3">
                        <div className="mt-3 me-1">
                          <Form.Item>
                            <Button ghost onClick={() => handleReset()} type="primary">
                              Reset
                            </Button>
                          </Form.Item>
                        </div>
                        <div className="mt-3 me-1">
                          <Form.Item>
                            <Button htmlType="submit" type="primary">
                              Submit
                            </Button>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default DepreciationSchedule;

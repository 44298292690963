import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getRentalSummaryByIdSlice = createSlice({
  name: 'GetTransactions',
  initialState,
  reducers: {
    getRentalSummaryByIdRequest: (state, action) => {
      state.data = [];
      state.isLoading = true;
    },
    getRentalSummaryByIdSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getRentalSummaryByIdFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
    getRentalSummaryByIdReset: (state, action) => {
      state.data = [];
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getRentalSummaryByIdRequest, getRentalSummaryByIdSuccess, getRentalSummaryByIdFailure, getRentalSummaryByIdReset } = getRentalSummaryByIdSlice.actions;

export default getRentalSummaryByIdSlice.reducer;

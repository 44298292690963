import { Button, Popconfirm, Space, Spin, Table, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetMobileUnverified, deleteUnverifiedMobileUser, verifiedMobile } from '../../container/actions/MobileUnverifiedUsers';
import dayjs from 'dayjs';
import { getUserTypeLabel } from '../../utils/ConstLabel';
import { ChannelPartnerType } from '../../components/common/userTypes';

const MobileUnverified = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [unverifiedUsersData, setUnverifiedUsersData] = useState([]);

  const getMobileUnverifiedUsersList = useCallback(() => {
    setLoading(true);
    dispatch(GetMobileUnverified()).then((res) => {
      if (res?.payload?.success === true) {
        // Setting the data with fallback to an empty array if res.payload.result is undefined or null
        let usersData = res.payload?.result || [];

        // Sorting the data by the created_at field
        usersData = [...usersData].sort((a, b) => b.created_at.localeCompare(a.created_at));

        setUnverifiedUsersData(usersData);
      }
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    getMobileUnverifiedUsersList();
  }, []);

  const handleConfirm = (id) => {
    setLoading(true);
    dispatch(verifiedMobile(id)).then((res) => {
      if (res.success === true) {
        notification.success({
          message: 'Success',
          description: res?.message,
        });

        getMobileUnverifiedUsersList();
      } else {
        notification.error({
          message: 'Failure',
          description: res?.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const handleDelete = (id) => {
    setLoading(true);
    dispatch(deleteUnverifiedMobileUser(id)).then((res) => {
      if (res.success === true) {
        notification.success({
          message: 'Success',
          description: res?.message,
        });

        getMobileUnverifiedUsersList();
      } else {
        notification.error({
          message: 'Failure',
          description: res?.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const unverifiedUsersColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      fixed: 'left',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Create Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (text) => (text ? dayjs(text).format('DD-MM-YYYY') : '-'),
    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
      key: 'user_type',
      render: (text, record) => {
        if (record.channel_partner_type) {
          return getUserTypeLabel(record.channel_partner_type);
        } else {
          return getUserTypeLabel(text);
        }
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text) => {
        return (
          <Space>
            <Popconfirm title="Are you sure you want to confirm this user?" trigger="click" onConfirm={() => handleConfirm(text)}>
              <Button type="primary">Confirm</Button>
            </Popconfirm>
            <Popconfirm title="Are you sure you want to delete this user?" trigger="click" onConfirm={() => handleDelete(text)} placement="top">
              <Button type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className="row align-items-center">
        <div className="col-xl-4 col-md-4 col-sm-6 col-12 date_picker">
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Mobile Unverified Users</h5>
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table
                pagination={{
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                columns={unverifiedUsersColumn}
                dataSource={unverifiedUsersData}
                rowKey={'id'}
                scroll={{ x: 'max-content' }}
              />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default MobileUnverified;

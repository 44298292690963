import { createSlice } from "@reduxjs/toolkit";

const initialState = { depreciationByIdData: [], error: false, loadingDepreciationById: true };

export const depreciationCompleteByIdSlice = createSlice({
    name: "depreciationById",
    initialState,
    reducers: {
        depreciationCompleteByIdSuccess: (state, action) => {
            state.depreciationByIdData = action.payload;
            state.loadingDepreciationById = false;
        },
        depreciationCompleteByIdFailure: (state, action) => {
            state.depreciationByIdData = action.payload;
            state.loadingDepreciationById = false;
            state.error = true;
        },
        resetDepreciationCompleteById: () => initialState
    },
});

export const { depreciationCompleteByIdSuccess, depreciationCompleteByIdFailure, resetDepreciationCompleteById } = depreciationCompleteByIdSlice.actions;

export default depreciationCompleteByIdSlice.reducer;

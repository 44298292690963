import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const getBankListSlice = createSlice({
  name: "GetBankList",
  initialState,
  reducers: {
    getBankListRequest: (state) => {
      state.data = [];
      state.isLoading = true;
    },
    getBankListSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getBankListFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { getBankListRequest, getBankListSuccess, getBankListFailure } = getBankListSlice.actions;

export default getBankListSlice.reducer;

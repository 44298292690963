import { Form, Input, Table, Button, Select, Spin, Popconfirm, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  deleteNotRegisteredUsersAsync,
  getNotRegisteredUsersAsync,
  selectNotRegisteredUsers,
  selectNotRegisteredUsersLoading,
} from '../../container/reducers/slices/NotRegisteredUsers/NotRegisteredUsersSlice';
import { getUserTypeLabel } from '../../utils/ConstLabel';
import { ChannelPartnerType, UserTypes } from '../../components/common/userTypes';

const defaultFilter = {
  searchSlug: '',
  userType: null,
};

const NotRegisteredUsers = () => {
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });
  const [inputValue, setInputValue] = useState('');

  const data = useSelector(selectNotRegisteredUsers);
  const loading = useSelector(selectNotRegisteredUsersLoading);

  // Initialize filters and pagination from localStorage
  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem('notRegisteredUsersFilter'));
    const storedPagination = JSON.parse(localStorage.getItem('notRegisteredUsersPagination'));

    if (storedFilter) {
      filterForm.setFieldsValue({
        Search: storedFilter.searchSlug || '',
        userTypeFilter: storedFilter.userType || null,
      });
      setFilters(storedFilter);
      setInputValue(storedFilter.searchSlug || '');
    } else {
      setFilters(defaultFilter);
    }

    if (storedPagination) {
      setPagination(storedPagination);
    }
  }, [filterForm]);

  // Fetch data when filters or pagination change
  useEffect(() => {
    if (filters !== null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination, dispatch]);

  // Update localStorage when filters change
  useEffect(() => {
    if (filters !== null) {
      localStorage.setItem('notRegisteredUsersFilter', JSON.stringify(filters));
    }
  }, [filters]);

  // Update localStorage when pagination changes
  useEffect(() => {
    if (pagination !== null) {
      localStorage.setItem('notRegisteredUsersPagination', JSON.stringify(pagination));
    }
  }, [pagination]);

  const fetchData = async () => {
    await dispatch(
      getNotRegisteredUsersAsync({
        ...filters,
        skip: (Number(pagination.current) - 1) * pagination.pageSize,
        limit: pagination.pageSize,
      }),
    );
  };

  const SearchSlug = debounce((e) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setInputValue(e.target.value);
    const values = e.target.value.trim();
    setFilters({ ...filters, searchSlug: values });
  }, 300);

  const handleUserTypeChange = (value) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setFilters({ ...filters, userType: value });
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const resetFilter = () => {
    filterForm.resetFields();
    setFilters(defaultFilter);
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });
    setInputValue('');
  };

  const handleDelete = async (id) => {
    try {
      const res = await dispatch(deleteNotRegisteredUsersAsync(id));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(
          getNotRegisteredUsersAsync({
            ...filters,
            skip: (Number(pagination.current) - 1) * pagination.pageSize,
            limit: pagination.pageSize,
          }),
        );
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const TaxAccountantColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'User type',
      dataIndex: 'user_type',
      key: 'user_type',
      render: (text, record) => {
        if (record?.channel_partner_type) {
          return getUserTypeLabel(record?.channel_partner_type);
        } else {
          return getUserTypeLabel(text);
        }
      },
    },
    {
      title: 'Added By',
      dataIndex: 'added_by',
      key: 'added_by',
      render: (text) => text || '-',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        return (
          <div>
            <Popconfirm title="Are you sure you want to delete?" placement="top" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
              <Button
                className="Delete_btn"
                size="large"
                icon={
                  <DeleteOutlined
                    style={{
                      fontSize: 18,
                      paddingTop: 3,
                    }}
                  />
                }
                type="primary"
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const userTypeDropdownOptions = [
    {
      label: 'Tax Accountant',
      value: UserTypes.TAXACCOUTANT,
    },
    {
      label: 'Sub Tax Accountant',
      value: UserTypes.SUB_TAXACCOUTANT,
    },
    {
      label: 'Admin Sub Tax Accountant',
      value: UserTypes.ADMIN_SUB_TAXACCOUNTANT,
    },
    {
      label: 'Mortgage Broker',
      value: ChannelPartnerType.MORTGAGE_BROKER,
    },
    {
      label: 'Conveyancer',
      value: ChannelPartnerType.CONVEYANCER,
    },
    {
      label: 'Rental Property Manager',
      value: ChannelPartnerType.RENTAL_PROPERTY_MANAGER,
    },
    {
      label: 'Buyers Agent',
      value: ChannelPartnerType.BUYERS_AGENT,
    },
    {
      label: 'Others',
      value: ChannelPartnerType.OTHERS,
    },
  ];

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Not Registered Users</h5>
          </div>
        </div>
        <div className="reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex  loans-header-responsive justify-content-between align-items-center gap-3 w-100">
              <div className="reset-width-100">
                <div className="mb-2 pt-1 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={SearchSlug} allowClear value={inputValue} style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div
                  className="mb-3 mb-lg-0 "
                  style={{
                    width: 220,
                  }}
                >
                  <Form.Item name="userTypeFilter" className="p-1 m-0 w-100">
                    <Select options={userTypeDropdownOptions} placeholder={<p className="text-center m-0 p-0">- - All Users - -</p>} onChange={handleUserTypeChange} allowClear />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Button
                    icon={<ReloadOutlined />}
                    type="primary"
                    size="middle"
                    className="reset-width-100 d-flex align-items-center"
                    onClick={resetFilter}
                    style={{
                      height: 36,
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table
                columns={TaxAccountantColumn}
                pagination={{ ...pagination, total: data?.count, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
                dataSource={data?.users}
                scroll={{ x: 'max-content' }}
                onChange={handleTableChange}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default NotRegisteredUsers;

import { Button, Popconfirm, Space, Spin, Table, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetUnconfirmedUsers, confirmUser, deleteConfirmUser } from '../../container/actions/UnconfirmedUsers';
import dayjs from 'dayjs';

const UnconfirmedUsers = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [unconfirmedUsersData, setUnconfirmedUsersData] = useState([]);

  // useEffect(() => {
  //   getUnconfirmedUsersList();
  // }, []);

  // const getUnconfirmedUsersList = () => {
  //   setLoading(true);
  //   dispatch(GetUnconfirmedUsers()).then((res) => {
  //     if (res?.payload?.success === true) {
  //       setUnconfirmedUsersData(res.payload.result);
  //       setLoading(false);
  //     }
  //   });
  // };
  const getUnconfirmedUsersList = useCallback(() => {
    setLoading(true);
    dispatch(GetUnconfirmedUsers()).then((res) => {
      if (res?.payload?.success === true) {
        // Setting the data with fallback to an empty array if res.payload.result is undefined or null
        let usersData = res.payload?.result || [];

        // Sorting the data by the create_date field
        usersData = [...usersData].sort((a, b) => b.create_date.localeCompare(a.create_date));

        setUnconfirmedUsersData(usersData);
      }
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    getUnconfirmedUsersList();
  }, [getUnconfirmedUsersList]);

  const handleConfirm = (id) => {
    setLoading(true);
    dispatch(confirmUser(id)).then((res) => {
      if (res.success === true) {
        notification.success({
          message: 'Success',
          description: res?.message,
        });

        getUnconfirmedUsersList();
      } else {
        notification.error({
          message: 'Failure',
          description: res?.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const handleDelete = (id) => {
    setLoading(true);
    dispatch(deleteConfirmUser(id)).then((res) => {
      if (res.success === true) {
        notification.success({
          message: 'Success',
          description: res?.message,
        });

        getUnconfirmedUsersList();
      } else {
        notification.error({
          message: 'Failure',
          description: res?.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const unconfirmedUsersColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      fixed: 'left',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Create Date',
      dataIndex: 'create_date',
      key: 'create_date',
      sorter: (a, b) => a.create_date.localeCompare(b.create_date),
      render: (text) => (text ? dayjs(text).format('DD-MM-YYYY') : '-'),
    },
    {
      title: 'Action',
      dataIndex: 'sub',
      key: 'sub',
      render: (text) => {
        return (
          <Space>
            <Popconfirm title="Are you sure you want to confirm this user?" trigger="click" onConfirm={() => handleConfirm(text)}>
              <Button type="primary">Confirm</Button>
            </Popconfirm>
            <Popconfirm title="Are you sure you want to delete this user?" trigger="click" onConfirm={() => handleDelete(text)}>
              <Button type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className="row align-items-center">
        <div className="col-xl-4 col-md-4 col-sm-6 col-12 date_picker">
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Unconfirmed Users</h5>
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table columns={unconfirmedUsersColumn} dataSource={unconfirmedUsersData} rowKey={'id'} scroll={{ x: 'max-content' }} />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default UnconfirmedUsers;

import React, { useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import { isValidStoredKey, useQueryParam } from '../utils/utilities'

const PublicRoutes = ({ children }) => {
    const [query] = useQueryParam('redirect')

    const isAuthenticated = useMemo(() => {
        return isValidStoredKey(['accessToken'])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children])
    return isAuthenticated ? <Navigate to={query || `/dashboard`} /> : children
}

export default PublicRoutes;
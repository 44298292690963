import { DatePicker, Form, Input, Spin, Table, notification, Button, Popconfirm, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { fetchUserMortgageBrokersAsync, selectMortgageBrokers, selectMortgageBrokersLoading } from '../../container/reducers/slices/Users/MortgageBrokerSlice';
import { ChannelPartnerType, UserStatus } from '../../components/common/userTypes';
import { ApproveMortgageBroker } from '../../container/actions/ChannelPartner/getChannelPartner.action';
import ModalAddChannelPartner from '../../utils/ModalAddChannelPartner';

const defaultFilter = {
  startDate: '',
  endDate: '',
  searchSlug: '',
};

const UserMortgageBroker = () => {
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filters, setFilters] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });
  const [inputValue, setInputValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);

  const mortgageBrokerData = useSelector(selectMortgageBrokers);
  const loading = useSelector(selectMortgageBrokersLoading);

  // Initialize filters and pagination from localStorage
  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem('userMortgageBrokerFilter'));
    const storedPagination = JSON.parse(localStorage.getItem('userMortgageBrokerPagination'));

    if (storedFilter) {
      filterForm.setFieldsValue({
        Search: storedFilter.searchSlug || '',
        startDate: storedFilter.startDate ? dayjs(storedFilter.startDate) : null,
        endDate: storedFilter.endDate ? dayjs(storedFilter.endDate) : null,
      });
      setFilters(storedFilter);
      setInputValue(storedFilter.searchSlug || '');
    } else {
      setFilters(defaultFilter);
    }

    if (storedPagination) {
      setPagination(storedPagination);
    }
  }, [filterForm]);

  // Fetch data when filters or pagination change
  useEffect(() => {
    if (filters !== null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination, dispatch]);

  // Update localStorage when filters change
  useEffect(() => {
    if (filters !== null) {
      localStorage.setItem('userMortgageBrokerFilter', JSON.stringify(filters));
    }
  }, [filters]);

  // Update localStorage when pagination changes
  useEffect(() => {
    if (pagination !== null) {
      localStorage.setItem('userMortgageBrokerPagination', JSON.stringify(pagination));
    }
  }, [pagination]);

  const fetchData = async () => {
    setLoading(true);
    const params = {
      ...filters,
      skip: (Number(pagination.current) - 1) * pagination.pageSize,
      limit: pagination.pageSize,
    };
    await dispatch(fetchUserMortgageBrokersAsync(params));

    setLoading(false);
  };

  const startDateChange = (value) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10,
    });
    if (value === null) {
      setFilters({ ...filters, startDate: '' });
      return;
    }

    if (value && dayjs(value).isValid() && filters.endDate && dayjs(filters.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be less than End Date.',
      });
      filterForm.setFieldsValue({ startDate: null });
      return;
    }

    const newStartDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setFilters({ ...filters, startDate: newStartDate });
  };

  const endDateChange = (value) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10,
    });
    if (value === null) {
      setFilters({ ...filters, endDate: '' });
      return;
    }

    if (value && dayjs(value).isValid() && filters.startDate && dayjs(filters.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'End Date should be greater than Start Date.',
      });
      filterForm.setFieldsValue({ endDate: null });
      return;
    }

    const newEndDate = value ? dayjs(value).format('YYYY-MM-DD') : '';
    setFilters({ ...filters, endDate: newEndDate });
  };

  const SearchSlug = debounce((e) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10,
    });
    setInputValue(e.target.value);
    const values = e.target.value.trim();
    setFilters({ ...filters, searchSlug: values });
  }, 300);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleView = (record) => {
    navigate(`/user/mortgage-broker/detail/${record.id}`);
  };

  const handleApproveMortgageBroker = async (id) => {
    setLoading(true);
    try {
      const res = await dispatch(ApproveMortgageBroker(id));
      if (res.success === true || res?.payload?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.message || 'Mortgage Broker approved successfully.',
        });
        fetchData();
      } else {
        notification.error({
          message: 'Failure',
          description: res?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Failure',
        description: error.message || 'Something went wrong!',
      });
    }
    setLoading(false);
  };

  const MortgageBrokerColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span className="userColumnName" onClick={() => handleView(record)}>
          {text}
        </span>
      ),
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (_, record) => record?.company_name || '-',
    },
    {
      title: 'Total Clients',
      dataIndex: 'client_count',
      key: 'client_count',
    },
    {
      title: 'CRN',
      dataIndex: 'mortgage_broker_uni_number',
      key: 'mortgage_broker_uni_number',
      render: (text) => text || '-',
    },
    {
      title: 'Action',
      dataIndex: 'approve',
      key: 'approve',
      render: (_text, record) => {
        if (record.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER && record.mortgage_broker_status === UserStatus.PENDING) {
          return (
            <Popconfirm title="Are you sure you want to Approve?" trigger="click" placement="topRight" onConfirm={() => handleApproveMortgageBroker(record.id)}>
              <Button type="primary">Approve</Button>
            </Popconfirm>
          );
        } else if (record.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER && record.mortgage_broker_status === UserStatus.ACTIVE) {
          return <Tag color="green">Approved</Tag>;
        } else {
          return '-';
        }
      },
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilters(defaultFilter);
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10,
    });
    setInputValue('');
  };

  const onSuccessAdd = () => {
    // Optionally trigger a data refetch after adding a new channel partner
    setFilters({ ...filters });
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div>
          <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
            <h5 className="m-0">Mortgage Brokers</h5>
          </div>
        </div>
        <div className="date_picker reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex loans-header-responsive justify-content-between align-items-center gap-3">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search" className="p-1 m-0">
                    <Input onChange={SearchSlug} value={inputValue} style={{ width: '100%', background: 'transparent' }} placeholder="Search" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 d-flex gap-3 mb-lg-0">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={resetFilter}>
                    Reset
                  </Button>
                  <Button size="large" type="primary" className="reset-width-100" onClick={() => setIsModalOpen(true)}>
                    + Add New
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={loading || Loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table
                columns={MortgageBrokerColumn}
                pagination={{
                  ...pagination,
                  total: mortgageBrokerData?.count,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                dataSource={mortgageBrokerData?.users || []}
                scroll={{ x: 'max-content' }}
                onChange={handleTableChange}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </Spin>
      <ModalAddChannelPartner isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} onSuccess={onSuccessAdd} />
    </>
  );
};

export default UserMortgageBroker;

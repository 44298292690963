import { createSlice } from "@reduxjs/toolkit";

const initialState = { depreciationByIdData: [], error: false, loadingDepreciationById: true };

export const depreciationByIdSlice = createSlice({
  name: "depreciationById",
  initialState,
  reducers: {
    depreciationByIdSuccess: (state, action) => {
      state.depreciationByIdData = action.payload;
      state.loadingDepreciationById = false;
    },
    depreciationByIdFailure: (state, action) => {
      state.depreciationByIdData = action.payload;
      state.loadingDepreciationById = false;
      state.error = true;
    },
    resetDepreciationById: () => initialState
  },
});

export const { depreciationByIdSuccess, depreciationByIdFailure, resetDepreciationById } = depreciationByIdSlice.actions;

export default depreciationByIdSlice.reducer;

import { getRequest } from "../../../utils/apiUtils"
import { CompleteDepreciationFailure, CompleteDepreciationSuccess } from "../../reducers/slices/Depreciation/depriciationCompleteSlice"

export const GetCompleteDepreciation = () => {
    return async (dispatch) => {
        const { result, error } = await getRequest(`admin/fetch-depreciation/complete`)

        if (!error) {
            return dispatch(CompleteDepreciationSuccess(result))
        }
        return dispatch(CompleteDepreciationFailure(result))
    }
}
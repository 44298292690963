import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  deleteUserAccountAsync,
  deleteUserConsentAsync,
  getUserBankDetailsAsync,
  selectBankDetailsLoading,
  selectUserBankDetails,
} from '../../container/reducers/slices/BasiqMultipleAccounts/BasiqBankAccountsSlice';
import { Badge, Button, Card, Empty, List, notification, Popconfirm, Spin } from 'antd';
import { BankAccountTypes } from '../../components/common/userTypes';
import './BasiqMultipleAccounts.css';
import { PencilIcon } from '../../assets/icons/index';
import EditBank from './EditBank';
import { selectUserDetails } from '../../container/reducers/slices/UserOverview/UserOverviewSlice';
import { DeleteOutlined } from '@ant-design/icons';

const BankDetails = () => {
  const [record, setRecord] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const { id } = useParams();

  const dispatch = useDispatch();

  const details = useSelector(selectUserBankDetails);
  const userInfo = useSelector(selectUserDetails);
  const loading = useSelector(selectBankDetailsLoading);

  useEffect(() => {
    if (id) {
      dispatch(getUserBankDetailsAsync(id));
    }
  }, [dispatch, id]);

  const handleEdit = ({ detail, record }) => {
    record.bu_id = detail?.bu_id;
    record.userId = id;
    setRecord(record);
    setDrawerOpen(true);
  };

  const removeConsent = async (bu_id) => {
    setSubmitting(true);
    const userId = userInfo?.id;
    try {
      const res = await dispatch(deleteUserConsentAsync({ bu_id: bu_id, id: userId }));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        dispatch(getUserBankDetailsAsync(id));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Failure',
        description: error.message || 'Something went wrong!',
      });
    }
    setSubmitting(false);
  };

  const handleDelete = async (bu_id) => {
    const userId = userInfo?.id;
    try {
      const res = await dispatch(deleteUserAccountAsync({ bu_id: bu_id, id: userId }));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(getUserBankDetailsAsync(id));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spin spinning={loading} size="large" />
        </div>
      ) : (
        <Spin spinning={submitting} size="large">
          <div className="p-3 d-flex flex-column gap-2 bg-white">
            {details?.length > 0 ? (
              <>
                {details?.map((detail, index) => {
                  const bankArray = Object.entries(detail?.account);
                  const groupedData = bankArray.map(([bank, accounts]) => ({
                    bank,
                    accounts,
                  }));
                  return (
                    <>
                      <Card
                        title={`User Account ${index + 1} (${detail?.bu_id})`}
                        key={crypto.randomUUID()}
                        loading={loading}
                        extra={
                          <>
                            {detail?.consent ? (
                              <Button type="primary" onClick={() => removeConsent(detail?.bu_id)}>
                                Remove Consent
                              </Button>
                            ) : (
                              ''
                            )}
                            {detail?.delete_account ? (
                              <Popconfirm placement="topLeft" title="Are you sure to delete this account?" onConfirm={() => handleDelete(detail?.bu_id)} okText="Yes" cancelText="No">
                                <Button type="link" danger icon={<DeleteOutlined />} key="delete" />
                              </Popconfirm>
                            ) : (
                              ''
                            )}
                          </>
                        }
                      >
                        <List
                          grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 2,
                            lg: 4,
                            xl: 3,
                            xxl: 3,
                          }}
                          dataSource={groupedData}
                          renderItem={(record) => {
                            const showIcon = record?.accounts?.some((account) => account.connected !== 1);
                            return (
                              <List.Item key={crypto.randomUUID()}>
                                <Card
                                  title={
                                    <>
                                      <img src={record?.accounts?.[0]?.bank_logo} style={{ height: 24, width: 24, borderRadius: '4px' }} className="img-fluid" alt="" />
                                      &nbsp; {record.bank}
                                    </>
                                  }
                                  extra={
                                    <>
                                      {showIcon && (
                                        <Button type="primary px-2" onClick={() => handleEdit({ detail, record })}>
                                          <PencilIcon className="img-fluid" />
                                        </Button>
                                      )}
                                    </>
                                  }
                                >
                                  <List
                                    dataSource={record.accounts}
                                    renderItem={({ masked_number, connected, ba_type }) => (
                                      <List.Item className="bankAccountCard gx-1" key={crypto.randomUUID()}>
                                        <span>{masked_number}</span>
                                        <span className="text-end">
                                          {
                                            Object.entries(BankAccountTypes)
                                              .find(([key, val]) => val === ba_type)?.[0]
                                              .split(' ')[0]
                                          }
                                        </span>
                                        <span>{connected ? <Badge size={'small'} status="success" styles={{ fontSize: '40px' }} /> : <Badge status="error" />}</span>
                                      </List.Item>
                                    )}
                                  />
                                </Card>
                              </List.Item>
                            );
                          }}
                        />
                      </Card>
                    </>
                  );
                })}
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </Spin>
      )}
      <EditBank record={record} setRecord={setRecord} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
    </div>
  );
};

export default BankDetails;

import { Button, Drawer, Flex, Form, Select, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { BankAccountTypes } from '../../components/common/userTypes';
import { useDispatch } from 'react-redux';
import { getUserBankDetailsAsync, updateBankDetailsAsync } from '../../container/reducers/slices/BasiqMultipleAccounts/BasiqBankAccountsSlice';

const EditBank = ({ record, setRecord, drawerOpen, setDrawerOpen }) => {
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const bankAccountTypes = [
    {
      label: 'Individual',
      value: BankAccountTypes['Individual Bank Account'],
    },
    {
      label: 'Trust',
      value: BankAccountTypes['Trust Bank Account'],
    },
    {
      label: 'Joint',
      value: BankAccountTypes['Joint Bank Account'],
    },
    {
      label: 'Spouse',
      value: BankAccountTypes['Spouse Bank Account'],
    },
  ];

  useEffect(() => {
    if (record) {
      form.setFieldsValue({ accounts: record.accounts });
    }
  }, [record, form]);

  const onClose = () => {
    setRecord(null);
    setDrawerOpen(false);
    form.resetFields();
  };

  const onOk = async () => {
    setSubmitting(true);
    const values = form.getFieldsValue();
    const newAccounts = values.accounts.map((account) => {
      return {
        l_id: account.l_id,
        bu_id: record.bu_id,
        ba_type: account.ba_type,
      };
    });
    const payload = {
      userId: record.userId,
      account: newAccounts,
    };
    await dispatch(updateBankDetailsAsync(payload));
    setSubmitting(false);
    setDrawerOpen(false);
    await dispatch(getUserBankDetailsAsync(record.userId));
  };

  return (
    <Drawer
      destroyOnClose
      title={
        <Flex justify="space-between" align="center">
          <span>Edit Bank Account Type</span>
          <Space className="d-flex justify-content-end">
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onOk}>
              Save
            </Button>
          </Space>
        </Flex>
      }
      placement="right"
      maskClosable={false}
      width={550}
      onClose={onClose}
      open={drawerOpen}
      footer={null}
    >
      <Spin spinning={submitting} size="large">
        <div className="d-flex flex-column gap-5">
          <div className="d-flex gap-1 align-items-center">
            <span>
              <img src={record?.accounts?.[0]?.bank_logo} style={{ height: 30, width: 30, borderRadius: '4px' }} className="img-fluid" alt="bank logo" />
            </span>
            <h4 className="m-0"> {record?.bank} </h4>
          </div>
          <div>
            <Form form={form}>
              <Form.List name="accounts">
                {(fields) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Flex justify="space-between" key={key}>
                        <label className="pt-1">{record?.accounts[name]?.masked_number}</label>
                        <Form.Item {...restField} name={[name, 'ba_type']} style={{ width: '30%' }}>
                          <Select options={bankAccountTypes} disabled={record?.accounts[name]?.connected === 1} placeholder="Select bank account type" />
                        </Form.Item>
                      </Flex>
                    ))}
                  </>
                )}
              </Form.List>
            </Form>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
};

export default EditBank;

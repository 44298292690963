import { postRequest } from '../../../utils/apiUtils';
import { updateDepreciationFailure, updateDepreciationSuccess } from '../../reducers/slices/Depreciation/updateDepreciationSlice';

export const UpdateDepreciation = (body) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`admin/depreciation/add`, body);

    if (!error) {
      return dispatch(updateDepreciationSuccess(result));
    }
    return dispatch(updateDepreciationFailure(error));
  };
};

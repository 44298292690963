import { UserActivityType, UserTypes } from '../../components/common/userTypes';

export const userActivityTypesOptions = [
  {
    value: UserActivityType.ACTIVITY_PROPERTY_INSERT,
    label: 'Property Added',
  },
  {
    value: UserActivityType.ACTIVITY_PROPERTY_EDIT,
    label: 'Property Updated',
  },
  {
    value: UserActivityType.ACTIVITY_PROPERTY_DELETE,
    label: 'Property Deleted',
  },
  {
    value: UserActivityType.ACTIVITY_DEPRECIATIONS_INSERT,
    label: 'Depreciation Added',
  },
  {
    value: UserActivityType.ACTIVITY_DEPRECIATIONS_EDIT,
    label: 'Depreciation Updated',
  },
  {
    value: UserActivityType.ACTIVITY_DEPRECIATIONS_DELETE,
    label: 'Depreciation Deleted',
  },
  {
    value: UserActivityType.ACTIVITY_RENT_INSERT,
    label: 'Rent Added',
  },
  {
    value: UserActivityType.ACTIVITY_RENT_EDIT,
    label: 'Rent Updated',
  },
  {
    value: UserActivityType.ACTIVITY_RENT_DELETE,
    label: 'Rent Deleted',
  },
  {
    value: UserActivityType.ACTIVITY_EXPENSE_INSERT,
    label: 'Expense Added',
  },
  {
    value: UserActivityType.ACTIVITY_EXPENSE_EDIT,
    label: 'Expense Updated',
  },
  {
    value: UserActivityType.ACTIVITY_EXPENSE_DELETE,
    label: 'Expense Deleted',
  },
  {
    value: UserActivityType.ACTIVITY_SETTLEMENT_INSERT,
    label: 'Settlement Added',
  },
  {
    value: UserActivityType.ACTIVITY_SETTLEMENT_EDIT,
    label: 'Settlement Updated',
  },
  {
    value: UserActivityType.ACTIVITY_SETTLEMENT_DELETE,
    label: 'Settlement Deleted',
  },
  {
    value: UserActivityType.ACTIVITY_BANK_CONNECT,
    label: 'Bank Connect',
  },
  {
    value: UserActivityType.ACTIVITY_BANK_DISCONNECT,
    label: 'Bank Disconnect',
  },
  {
    value: UserActivityType.ACTIVITY_REPORT_GENERATED,
    label: 'Report Generated',
  },
  {
    value: UserActivityType.ACTIVITY_SUBSCRIPTION_PURCHASE,
    label: 'Subscribed',
  },
  {
    value: UserActivityType.ACTIVITY_SUBSCRIPTION_CANCELED,
    label: 'Subscription Cancelled',
  },
];

export const userSourcesOptions = [
  {
    value: UserTypes.USER,
    label: 'Self',
  },
  {
    value: UserTypes.TAXACCOUTANT,
    label: 'Tax Account',
  },
  {
    value: UserTypes.ADMIN,
    label: 'Automated Entry',
  },
];

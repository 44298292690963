import { getRequest } from "../../../utils/apiUtils"
import { CompleteSettlementFailure, CompleteSettlementSuccess } from "../../reducers/slices/settlement/getSettlementSlice"

export const GetCompleteSettlements = () => {
    return async (dispatch) => {
        const { result, error } = await getRequest(`admin/fetch-settlement/complete`)

        if (!error) {
            return dispatch(CompleteSettlementSuccess(result))
        }
        return dispatch(CompleteSettlementFailure(result))
    }
}

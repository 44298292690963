import { Button, Form, Input, Modal, notification, Select } from 'antd';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import { AddChannelPartnerData } from '../container/actions/ChannelPartner/getChannelPartner.action';
import { ChannelPartnerType } from '../components/common/userTypes';
import { ChannelPartnerTypeOption } from '.';

const ModalAddChannelPartner = ({ isModalOpen = false, setIsModalOpen = () => {}, onSuccessAdd = () => {}, channelPartner = false }) => {
  const [addLoading, setAddLoading] = useState(false);

  const [userform] = Form.useForm();
  const dispatch = useDispatch();

  const handleCancel = () => {
    userform.resetFields();
    setIsModalOpen(false);
  };

  const onFinish = (value) => {
    const values = {
      ...value,
      mobile: `+${value.mobile}`,
    };

    if (!channelPartner) {
      values.type = ChannelPartnerType.MORTGAGE_BROKER;
    }

    setAddLoading(true);
    dispatch(AddChannelPartnerData(values)).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload.message,
        });

        setAddLoading(false);
        setIsModalOpen(false);
        userform.resetFields();
        onSuccessAdd();
        // GetChannelPartnerList(filter);
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload.message || 'Something went wrong!',
        });
        setAddLoading(false);
      }
    });
  };

  return (
    <Modal
      title={<h4 className="mb-0">{channelPartner ? 'Add Channel Partner' : 'Add Mortgage Broker'}</h4>}
      open={isModalOpen}
      destroyOnClose
      maskClosable={false}
      width={600}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="add_form">
        <div className="row justify-content-center">
          <Form form={userform} name="userform" autoComplete="off" layout="vertical" onFinish={onFinish}>
            <div className="card-body p-3 pb-0">
              <div className="row">
                <div className="col-md-6">
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your name!',
                      },
                    ]}
                  >
                    <Input className="form-control p-2" />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    label="Phone Number"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: 'Please input correct phone number!',
                      },
                    ]}
                    className="phone_input"
                  >
                    <PhoneInput specialLabel="" country="au" placeholder="Enter Phone Number" enableSearch />
                  </Form.Item>
                </div>
                <div className="col-md-12">
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your valid email!',
                        type: 'email',
                      },
                    ]}
                  >
                    <Input className="form-control p-2" inputtype="text" autoComplete="off" />
                  </Form.Item>
                </div>
                {channelPartner && (
                  <div className="col-md-12">
                    <Form.Item
                      label="Select Type"
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: 'Please select one!',
                        },
                      ]}
                    >
                      <Select placeholder="Select Type" options={ChannelPartnerTypeOption} />
                    </Form.Item>
                  </div>
                )}
              </div>
              <Form.Item className="text-center">
                <Button type="primary" className="mt-1" htmlType="submit" loading={addLoading}>
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddChannelPartner;

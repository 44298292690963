import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getManageClientsUsers } from '../../../actions/ManageClientsUsers/ManageClientsUsersApi';

const initialState = {
  error: false,
  isLoading: true,
  data: [],
};

export const getManageClientsUsersAsync = createAsyncThunk('ManageClientUsers/getUsers', async (body) => {
  const response = await getManageClientsUsers(body);
  return response;
});

export const ManageClientsUserSlice = createSlice({
  name: 'ManageClientsUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getManageClientsUsersAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getManageClientsUsersAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getManageClientsUsersAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectManageClientsUser = (state) => state.manageClients.data;
export const selectManageClientsUserLoading = (state) => state.manageClients.isLoading;

export default ManageClientsUserSlice.reducer;

import { Button, Checkbox, DatePicker, Drawer, Form, Input, InputNumber, Row, Select, Space, Spin, Table, TreeSelect } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { ChannelPartnerType, PropertyType, UserTypes } from '../../components/common/userTypes';
import { debounce } from 'lodash';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterDataAsync, selectFilterDataLoading, selectOnBoardUsers, selectUsers } from '../../container/reducers/slices/Notification/NotificationFilterSlice';

const { SHOW_PARENT } = TreeSelect;

const userTreeData = [
  {
    value: 'all_users',
    title: 'All Users',
    children: [
      {
        value: UserTypes.USER,
        title: 'Property Investor',
      },
      {
        value: UserTypes.TAXACCOUTANT,
        title: 'Tax Accountant',
      },
      {
        value: UserTypes.ADMIN_SUB_TAXACCOUNTANT,
        title: 'Admin Sub Tax Accountant',
      },
      {
        value: UserTypes.SUB_TAXACCOUTANT,
        title: 'Sub Tax Accountant',
      },
      {
        value: UserTypes.CHANNEL_PARTNER,
        title: 'Channel Partner',
        children: [
          {
            value: ChannelPartnerType.MORTGAGE_BROKER,
            title: 'Mortgage Broker',
          },
          {
            value: UserTypes.SUB_CHANNEL_PARTNER,
            title: 'Sub Mortgage Broker',
          },
          {
            value: ChannelPartnerType.CONVEYANCER,
            title: 'Conveyancer',
          },
          {
            value: ChannelPartnerType.BUYERS_AGENT,
            title: 'Buyers Agent',
          },
          {
            value: ChannelPartnerType.RENTAL_PROPERTY_MANAGER,
            title: 'Rental Property Manager',
          },
          {
            value: ChannelPartnerType.OTHERS,
            title: 'Others',
          },
        ],
      },
      {
        value: 'onboard_user',
        title: 'Onboarded User',
      },
    ],
  },
];

const bankDropdown = [
  {
    label: 'Connected',
    value: 'CONNECTED',
  },
  {
    label: 'Not Connected',
    value: 'NOT_CONNECTED',
  },
  {
    label: 'Partial Connected',
    value: 'PARTIAL_CONNECTED',
  },
  {
    label: 'No Bank Added',
    value: 'NO_BANK',
  },
];

const propertyDropdown = [
  {
    label: 'Residential Investment',
    value: 'INVESTMENT_PROPERTY',
  },
  {
    label: 'Residential Owner Occupied',
    value: 'OWNER_OCCUPIED_PROPERTY',
  },
  {
    label: 'Commercial Investment',
    value: 'COMMERCIAL_PROPERTY',
  },
];

const NotificationUsers = ({ drawerOpen, setDrawerOpen, selectedRowKeys, setSelectedRowKeys, selectedTableRows, setSelectedTableRows }) => {
  const users = useSelector(selectUsers);
  const onBoardUsers = useSelector(selectOnBoardUsers);

  // const [filteredUsers, setFilteredUsers] = useState(combined);
  const filterDataLoading = useSelector(selectFilterDataLoading);

  const [selectedKeys, setSelectedKeys] = useState(selectedRowKeys);
  const [tableSelectedRows, setTableSelectedRows] = useState(selectedTableRows);

  const sortedUsers = useMemo(() => {
    const combined = [...users, ...onBoardUsers];
    return [...tableSelectedRows, ...combined.filter((user) => !tableSelectedRows.find((selected) => selected.key === user.key))];
  }, [tableSelectedRows, users, onBoardUsers]);

  const [expand, setExpand] = useState(false);

  const defaultFilter = {
    user_type: ['all_users'],
    property_type: [],
    bank_connectivity: '',
    property_search_slug: '',
    user_search_slug: '',
    number_of_properties: null,
    free_users: false,
    no_property: false,
    subscription_pending: false,
    deleted_users: false,
    pending_signup: false,
    inactive_users: false,
    invite_later: false,
  };

  const [filters, setFilters] = useState(defaultFilter);

  const [paginationNumber, setPaginationNumber] = useState(1);

  const [filterForm] = Form.useForm();

  const dispatch = useDispatch();

  const onOk = () => {
    setSelectedTableRows(tableSelectedRows);
    filterForm.resetFields();
    setFilters(defaultFilter);
    setSelectedRowKeys(selectedKeys);
    setDrawerOpen(false);
  };

  const onClose = () => {
    setSelectedKeys([]);
    setTableSelectedRows([]);
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (drawerOpen) {
      dispatch(getFilterDataAsync(filters));
      setPaginationNumber(1);
    }
  }, [dispatch, filters, drawerOpen]);

  useEffect(() => {
    if (drawerOpen) {
      setSelectedKeys(selectedRowKeys);
      setTableSelectedRows(selectedTableRows);
    }
  }, [drawerOpen, selectedRowKeys, selectedTableRows]);

  const inviteeColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text) => {
        return <span>{text || '-'}</span>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text) => {
        return <span>{text || '-'}</span>;
      },
    },
    {
      title: 'Mobile Number',
      dataIndex: 'phone_number',
      render: (text) => {
        return <span>{text || '-'}</span>;
      },
    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
      render: (text, record) => {
        return (
          <span>
            {userTreeData[0]['children'][4]['children'].find((item) => item.value === record?.channel_partner_type)?.title ||
              userTreeData[0]['children'].find((item) => item.value === text)?.title ||
              userTreeData[0]['children'][4]['children'].find((item) => item.value === text)?.title ||
              (record?.invite_now ? 'Onboard User' : 'Manage By Tax Accountant')}
          </span>
        );
      },
    },
  ];

  const userTypeChange = (newValue) => {
    setFilters({ ...filters, user_type: newValue });
  };

  const SearchSlug = debounce((e) => {
    const values = e.target.value.trim();

    setFilters({ ...filters, property_search_slug: values });
  }, 300);

  const UserSlug = debounce((e) => {
    const values = e.target.value.trim();

    setFilters({ ...filters, user_search_slug: values });
  }, 300);

  const propertyNumberChange = debounce((newValue) => {
    setFilters({ ...filters, number_of_properties: newValue });
  }, 300);

  const propertyTypeChange = (option) => {
    const property_type = option.map((property) => PropertyType[property]);

    setFilters({ ...filters, property_type: property_type });
  };

  const bankDropdownChange = (option) => {
    setFilters({ ...filters, bank_connectivity: option });
  };

  const handleChange = (option) => (e) => {
    setFilters({ ...filters, [option]: e.target.checked });
  };

  const handleSelect = (record, selected) => {
    if (selected) {
      setTableSelectedRows((prev) => [...prev, record]);
      setSelectedKeys((keys) => [...keys, record.key]);
    } else {
      setTableSelectedRows((prev) => prev.filter((r) => r.key !== record.key));
      setSelectedKeys((keys) => {
        const index = keys.indexOf(record.key);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
    }
  };

  const toggleSelectAll = () => {
    setTableSelectedRows((rows) => (rows.length === sortedUsers.length ? [] : sortedUsers));
    setSelectedKeys((keys) => (keys.length === sortedUsers.length ? [] : sortedUsers.map((r) => r.key)));
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedKeys.length > 0 && selectedKeys.length === sortedUsers.length}
      indeterminate={selectedKeys.length > 0 && selectedKeys.length < sortedUsers.length}
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    type: 'checkbox',
    fixed: true,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationNumber(pagination.current);
  };

  const dateRangeChange = (dates, dateStrings) => {
    setFilters({ ...filters, start_date: dateStrings[0], end_date: dateStrings[1] });
  };

  return (
    <Drawer
      destroyOnClose
      title={`Select Users For Notification`}
      placement="right"
      maskClosable={false}
      width={950}
      onClose={onClose}
      open={drawerOpen}
      footer={
        <Space className="d-flex justify-content-end">
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button type="primary" onClick={() => onOk()}>
            OK
          </Button>
        </Space>
      }
    >
      <div>
        <Form form={filterForm} requiredMark="default" layout="vertical" className="d-flex justify-content-center flex-column gap-3">
          <Row gutter={24}></Row>
          <div className="filterLayout row gx-5  ">
            <Form.Item label="User Type" name="userType">
              <TreeSelect
                defaultValue={['all_users']}
                treeCheckable={true}
                onChange={userTypeChange}
                maxTagCount={1}
                maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
                treeData={userTreeData}
                placeholder="Please select user type"
                allowClear
                showCheckedStrategy={SHOW_PARENT}
                placement="bottomRight"
              />
            </Form.Item>
            <div>
              <Form.Item label="Property Type" name="propertyType">
                <Select
                  mode="multiple"
                  allowClear
                  maxTagCount={1}
                  maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
                  style={{ width: '100%' }}
                  placeholder="Please select property type"
                  onChange={propertyTypeChange}
                  options={propertyDropdown}
                />
              </Form.Item>
            </div>
            <Form.Item label="Bank" name="bank_connectivity">
              <Select allowClear style={{ width: '100%' }} placeholder="Please select bank type" onChange={bankDropdownChange} options={bankDropdown} />
            </Form.Item>
            <Form.Item label="Location" name="location">
              <Input placeholder="Location" className="no-hover-border" allowClear suffix={<SearchOutlined />} onChange={SearchSlug} />
            </Form.Item>
            <Form.Item label="User" name="users">
              <Input placeholder="User" className="no-hover-border" allowClear suffix={<SearchOutlined />} onChange={UserSlug} />
            </Form.Item>
            <Form.Item label="Number of Properties" name="numberOfProperties">
              <InputNumber min={0} onChange={propertyNumberChange} placeholder="Number of properties" style={{ width: '100%', height: '35px' }} />
            </Form.Item>
          </div>

          {expand && (
            <Space size={[24, 16]} wrap>
              <Form.Item label="Created Date" name="date_range">
                <DatePicker.RangePicker onChange={dateRangeChange} placeholder={['Date From', 'Date To']} format={'DD-MM-YYYY'} />
              </Form.Item>
              <Checkbox checked={filters['free_users']} onChange={handleChange('free_users')}>
                Free Users
              </Checkbox>
              <Checkbox checked={filters['no_property']} onChange={handleChange('no_property')}>
                No Property
              </Checkbox>

              <Checkbox checked={filters['subscription_pending']} onChange={handleChange('subscription_pending')}>
                Subscription pending
              </Checkbox>
              <Checkbox checked={filters['pending_signup']} onChange={handleChange('pending_signup')}>
                Pending Singup
              </Checkbox>
              <Checkbox checked={filters['inactive_users']} onChange={handleChange('inactive_users')}>
                Inactive Users
              </Checkbox>
              <Checkbox checked={filters['deleted_users']} onChange={handleChange('deleted_users')}>
                Deleted User
              </Checkbox>
              <Checkbox checked={filters['invite_later']} onChange={handleChange('invite_later')}>
                Manage By Tax Accountant
              </Checkbox>
            </Space>
          )}

          <div className="d-flex justify-content-end">
            <Button type="text" icon={<DownOutlined rotate={expand ? 180 : 0} />} onClick={() => setExpand(!expand)}>
              {expand ? 'Collapse' : 'Expand'}
            </Button>
          </div>
        </Form>
      </div>
      <div className="mt-5">
        <div>
          <Spin spinning={filterDataLoading} size="large">
            <Table
              scroll={{ x: 768 }}
              bordered={false}
              rowKey={'key'}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={inviteeColumns}
              pagination={{ showSizeChanger: true, current: paginationNumber }}
              onChange={handleTableChange}
              dataSource={sortedUsers}
            />
          </Spin>
        </div>
      </div>
    </Drawer>
  );
};

export default NotificationUsers;

NotificationUsers.defaultProps = {
  drawerOpen: false,
  setDrawerOpen: () => {},
};

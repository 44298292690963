import React, { useEffect, useState } from 'react';
import { notification, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectTaxAccountantRegisteredClients, selectTaxAccountantClientsLoading, getRegisteredClientsAsync } from '../../container/reducers/slices/TaxAccountantDetails/TaxAccountantDetailsSlice';
import { useParams } from 'react-router-dom';

const RegisteredClients = () => {
  const clients = useSelector(selectTaxAccountantRegisteredClients);
  const loading = useSelector(selectTaxAccountantClientsLoading);
  const defaultPagination = {
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  };

  const [pagination, setPagination] = useState(defaultPagination);

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getRegisteredClientsAsync({
        id,
        skip: (Number(pagination.current) - 1) * pagination.pageSize,
        limit: pagination.pageSize,
      }),
    ).catch((error) => {
      notification.error({
        message: 'Failed to Fetch Team Members',
        description: error.message || 'Something went wrong while fetching team members.',
      });
    });
  }, [dispatch, id, pagination]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => text || '-',
    },
    {
      title: 'Properties',
      dataIndex: 'properties',
      key: 'properties',
      render: (text) => text || '-',
    },
    {
      title: 'Managed By',
      dataIndex: 'managed_by',
      key: 'managed_by',
      render: (text) => text || '-',
    },
  ];

  const handleTableChange = (newPagination) => {
    setPagination({
      ...pagination,
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    });
    // localStorage will be updated via useEffect
  };

  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="dashboard_list">
          <Table
            pagination={{
              ...pagination,
              total: clients?.count || 0,
            }}
            onChange={handleTableChange}
            columns={columns}
            dataSource={clients?.data}
            loading={loading}
            scroll={{ x: 'max-content' }}
          />
        </div>
      </div>
    </div>
  );
};

export default RegisteredClients;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCategories, updateCategories } from '../../../actions/Categories/CategoriesApi';

const initialState = {
  error: false,
  isLoading: false,
  data: [],
};

export const getCategoriesAsync = createAsyncThunk('Categories/getTeamMembers', async () => {
  const response = await getCategories();
  return response;
});

export const updateCategoriesAsync = createAsyncThunk('Categories/getManagedClients', async ({ id, keywords }) => {
  const response = await updateCategories(id, keywords);
  return response;
});

export const CategoriesSlice = createSlice({
  name: 'Categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategoriesAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategoriesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.data = action.payload.data.result;
      })
      .addCase(getCategoriesAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(updateCategoriesAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCategoriesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
      })
      .addCase(updateCategoriesAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectCategories = (state) => state.categories.data.data;
export const selectCategoriesLoading = (state) => state.categories.isLoading;

export default CategoriesSlice.reducer;

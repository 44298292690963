import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], error: false, isLoading: true };

export const getMobileUnverifiedListSlice = createSlice({
  name: 'MobileUnverifiedList',
  initialState,
  reducers: {
    GetMobileUnverifiedListSuccess: (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    GetMobileUnverifiedListFailure: (state, action) => {
      state.data = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { GetMobileUnverifiedListSuccess, GetMobileUnverifiedListFailure } = getMobileUnverifiedListSlice.actions;

export default getMobileUnverifiedListSlice.reducer;

import { deleteRequest, getRequest, postRequest } from '../../../utils/apiUtils';

export const getCommentsList = (id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`/admin/user-comment/${id}`);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const deleteCommentsList = (id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await deleteRequest(`/admin/user-comment/${id}`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const addCommentsList = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`/admin/user-comment`, payload);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

import { putRequest } from '../../../utils/apiUtils';
import { editRecordExpenseFailure, editRecordExpenseRequest, editRecordExpenseSuccess } from '../../reducers/slices/RentSummary/editRecord.slice';
import { TransactionType } from '../../../components/common/userTypes';

export const EditRecordExpense = (data, id, id1, type = TransactionType.RENT_TYPE_TRANSACTION) => {
  return async (dispatch) => {
    await dispatch(editRecordExpenseRequest());
    const { result, error } = await putRequest(`user/transaction/update/${id}?type=${type}&userId=${id1}`, data);

    if (!error) {
      return await dispatch(editRecordExpenseSuccess(result));
    }
    return await dispatch(editRecordExpenseFailure(error));
  };
};

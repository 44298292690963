import { createSlice } from '@reduxjs/toolkit';

const initialState = { settlementDatacomplete: [], error: false, isLoading: true };

export const PendingRentSummarySlice = createSlice({
  name: 'PendingRentSummary',
  initialState,
  reducers: {
    PendingRentSummarySuccess: (state, action) => {
      state.settlementDatacomplete = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    PendingRentSummaryFailure: (state, action) => {
      state.settlementDatacomplete = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { PendingRentSummarySuccess, PendingRentSummaryFailure } = PendingRentSummarySlice.actions;

export default PendingRentSummarySlice.reducer;

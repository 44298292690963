import { FrownOutlined } from '@ant-design/icons';
import { Button, notification, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';
import { I18n } from '@aws-amplify/core';
import { Authenticator, View, useTheme, Image, Text, Heading, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { verifyAdmin } from '../../container/actions/login/verify.action';
import { UserTypes } from '../../components/common/userTypes';
import appSVG from '../../assets/images/logo.svg';
import './login.css';
import { AppIcon } from '../../assets/icons';

I18n.setLanguage('en');
I18n.putVocabulariesForLanguage('en', {
  'Sign in': 'Sign In', // Button label
});

const Login = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const landing_url = process.env.REACT_APP_LANDING_PAGE_URL;

  // const verifyUserUsingJwtToken = async () => {
  //   try {
  //     const { accessToken } = (await fetchAuthSession()).tokens ?? {};
  //     const values = {
  //       token: accessToken.toString(),
  //       userType: 4,
  //     };
  //     setLoading(true);
  //     dispatch(verifyAdmin(values)).then((res) => {
  //       setLoading(false);
  //       if (res?.payload?.success === true) {
  //         if (res.payload.result.user_type === UserTypes.ADMIN) {
  //           const data = res.payload.result;
  //           localStorage.setItem('accessToken', data.accessToken);
  //           localStorage.setItem('refreshToken', data.refreshToken);
  //           localStorage.setItem('aws_cognito_sub', data.aws_cognito_sub);
  //           localStorage.setItem('name', data.name);
  //           localStorage.setItem('email', data.email);

  //           return navigate('/');
  //         } else {
  //           localStorage.clear();
  //         }
  //       } else {
  //         notification.open({
  //           message: 'Failure',
  //           description: res?.payload?.message || 'Something went wrong!',
  //           icon: (
  //             <FrownOutlined
  //               style={{
  //                 color: 'red',
  //               }}
  //             />
  //           ),
  //         });
  //         localStorage.clear();
  //       }
  //     });
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  // useEffect(() => {
  //   if (user) {
  //     if (user.username) {
  //       verifyUserUsingJwtToken();
  //     } else {
  //       notification.open({
  //         message: 'Failure',
  //         description: 'Something went wrong!',
  //         icon: (
  //           <FrownOutlined
  //             style={{
  //               color: 'red',
  //             }}
  //           />
  //         ),
  //       });
  //       localStorage.clear();
  //       window.open(landing_url, '_self');
  //     }
  //   }
  // }, [user, landing_url,verifyUserUsingJwtToken]);

  const verifyUserUsingJwtToken = useCallback(async () => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const values = {
        token: accessToken.toString(),
        userType: 4,
      };
      setLoading(true);
      dispatch(verifyAdmin(values)).then((res) => {
        setLoading(false);
        if (res?.payload?.success === true) {
          if (res.payload.result.user_type === UserTypes.ADMIN) {
            const data = res.payload.result;
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);
            localStorage.setItem('aws_cognito_sub', data.aws_cognito_sub);
            localStorage.setItem('name', data.name);
            localStorage.setItem('email', data.email);

            return navigate('/');
          } else {
            localStorage.clear();
          }
        } else {
          notification.open({
            message: 'Failure',
            description: res?.payload?.message || 'Something went wrong!',
            icon: (
              <FrownOutlined
                style={{
                  color: 'red',
                }}
              />
            ),
          });
          localStorage.clear();
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (user) {
      if (user.username) {
        verifyUserUsingJwtToken();
      } else {
        notification.open({
          message: 'Failure',
          description: 'Something went wrong!',
          icon: (
            <FrownOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
        localStorage.clear();
        window.open(landing_url, '_self');
      }
    }
  }, [user, landing_url, verifyUserUsingJwtToken]);

  const formFields = {
    signIn: {
      username: {
        label: 'Email',
        placeholder: 'Enter your Email',
        isRequired: true,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: 'Enter your Password',
      },
    },
    forgotPassword: {
      username: {
        label: 'Email',
        placeholder: 'Enter your Email',
        isRequired: true,
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Enter your Confirmation Code',
        label: 'Confirmation Code',
        isRequired: true,
      },
      confirm_password: {
        placeholder: 'Enter your Password Please',
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'Confirmation Code',
        placeholder: 'Enter your Confirmation Code',
        isRequired: true,
      },
    },
  };

  const components = {
    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={appSVG} />
            </View>
            <Heading padding={`0 ${tokens.space.xl}`} level={4}>
              Welcome back Admin!
            </Heading>
            <Text padding={`0 ${tokens.space.xl}`} className="creat_account">
              Enter your account details below.
            </Text>
          </>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={appSVG} />
            </View>
            <Text className="creat_account">We have delivered the authentication code by SMS to your register mobile number. Please enter the code to complete authentication.</Text>
          </>
        );
      },
    },
    ForgotPassword: {
      Header() {
        const { tokens } = useTheme();

        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={appSVG} />
            </View>
            <Heading level={4}>Forgot your password?</Heading>
            <Text className="creat_account">Enter your Email below and we will send a message to reset your password.</Text>
          </>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();

        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={appSVG} />
            </View>
            <Heading level={4}>Reset Password</Heading>
          </>
        );
      },
    },
    ForceNewPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={appSVG} />
            </View>
            <Heading level={4}>Change Password</Heading>
          </>
        );
      },
    },
  };

  return (
    <section className="bg-home d-flex align-items-center login_bg">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <Authenticator hideDefault={true} formFields={formFields} components={components}>
            {() => (
              <div className="">
                <div className="card-body p-0">
                  <div className="modal-body p-4">
                    <div className="text-center">
                      {!loading ? (
                        <>
                          <AppIcon /> <br />
                          <br />
                          <Button type="primary" onClick={() => window.location.reload()}>
                            Reload
                          </Button>
                        </>
                      ) : (
                        <>
                          <AppIcon /> <br />
                          <p className="mt-2">Retrieving your profile information. Please wait...</p>
                          <Spin spinning={loading} size="large" />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Authenticator>
        </div>
      </div>
    </section>
  );
};

export default Login;

import { deleteRequest, getRequest, postRequest } from '../../../utils/apiUtils';

export const getUserBankDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`/admin/user-bank-details/${id}`);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const deleteUserConsent = (bu_id, id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await deleteRequest(`/admin/user-bank-consent/${bu_id}?userId=${id}`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const deleteUserAccount = (bu_id, id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await deleteRequest(`/admin/user-bank-account/${bu_id}?userId=${id}`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const updateBankDetails = (payload) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`/admin/user-bank-details`, payload);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const updateSettlementSlice = createSlice({
    name: "addSettlement",
    initialState,
    reducers: {
        UpdateSettlementSuccess: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        UpdateSettlementFailure: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
            state.error = true;
        }
    },
});

export const { UpdateSettlementSuccess, UpdateSettlementFailure } = updateSettlementSlice.actions

export default updateSettlementSlice.reducer

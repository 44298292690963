import { getRequest } from '../../../utils/apiUtils';
import {
  getRentalSummaryByIdReset,
  getRentalSummaryTransectionByIdFailure,
  getRentalSummaryTransectionByIdRequest,
  getRentalSummaryTransectionByIdSuccess,
} from '../../reducers/slices/RentSummary/getRentsummaryTransectionByIdSlice';
import { TransactionType } from '../../../components/common/userTypes';

export const GetRentalSummaryTransectionById = (id, id1, type = TransactionType.RENT_TYPE_TRANSACTION) => {
  return async (dispatch) => {
    await dispatch(getRentalSummaryTransectionByIdRequest());
    const { result, error } = await getRequest(`user/transaction/details/${id}?type=${type}&userId=${id1}`);

    if (!error) {
      return await dispatch(getRentalSummaryTransectionByIdSuccess(result));
    }
    return await dispatch(getRentalSummaryTransectionByIdFailure(result));
  };
};

export const ResetRentalSummaryTransection = () => {
  return async (dispatch) => {
    return await dispatch(getRentalSummaryByIdReset());
  };
};

import {postRequest } from "../../../utils/apiUtils";
import { junkEmailSuccess, junkEmailFailure } from "../../reducers/slices/dashboardSlice";

export const DiversEmailAction = (data) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`/admin/diversemail/transfer`, data);

    if (!error) {
      return dispatch(junkEmailSuccess(result));
    }
    return dispatch(junkEmailFailure(result));
  };
};

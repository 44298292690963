import React, { useEffect } from 'react';
import { Button, DatePicker, Form, Input, InputNumber, Modal, notification, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addUserCouponAsync, editUserCouponAsync, fetchUserCouponAsync, selectUserCouponLoading } from '../../container/reducers/slices/UserCoupon/UserCouponsSlice';
import { DiscountType } from '../../components/common/userTypes';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const AddCoupoun = ({ open = false, setOpen = () => {}, record = null, setRecord = () => {} }) => {
  const [form] = Form.useForm();
  const loading = useSelector(selectUserCouponLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        code: record.code,
        name: record.name,
        applyDateRange: [dayjs(record.apply_from), dayjs(record.apply_to)],
        validDateRange: [dayjs(record.valid_from), dayjs(record.valid_to)],
        discount_percentage: record.discount_percentage,
      });
    } else {
      // If no record, reset fields
      form.resetFields();
    }
  }, [record, form]);

  const handleSubmit = async (values) => {
    try {
      const { validDateRange, applyDateRange, name, discount_percentage, code } = values;

      const valid_from = validDateRange[0].format('YYYY-MM-DD');
      const valid_to = validDateRange[1].format('YYYY-MM-DD');

      const apply_from = applyDateRange[0].format('YYYY-MM-DD');
      const apply_to = applyDateRange[1].format('YYYY-MM-DD');

      const obj = {
        valid_from,
        valid_to,
        apply_from,
        apply_to,
        discount_percentage,
        name,
        code,
        discount_type: DiscountType.PERCENTAGE,
      };
      let res;
      if (record) {
        res = await dispatch(editUserCouponAsync({ id: record.id, info: obj }));
      } else {
        res = await dispatch(addUserCouponAsync(obj));
      }
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        setOpen(false);
        form.resetFields();
        setRecord(null);
        await dispatch(fetchUserCouponAsync());
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
    setRecord(null);
  };

  // Whenever the apply date range changes, reset the valid date range start date
  const handleApplyDateChange = (dates) => {
    if (dates && dates.length === 2) {
      // Set the validDateRange start date to the same as applyDateRange start date
      const startDate = dates[0];
      const currentValidRange = form.getFieldValue('validDateRange') || [];
      const endDate = currentValidRange[1] && currentValidRange[1].isAfter(startDate) ? currentValidRange[1] : null;

      form.setFieldsValue({
        validDateRange: [startDate, endDate],
      });
    } else {
      // If applyDateRange is cleared, also clear validDateRange
      form.setFieldsValue({
        validDateRange: [],
      });
    }
  };

  // Disable dates for discount validity based on apply date range
  const disabledValidDateRangeDate = (current) => {
    const applyDateRange = form.getFieldValue('applyDateRange');
    if (!applyDateRange || !applyDateRange[0]) {
      // If no apply date range yet, disable past dates
      return current && current < dayjs().startOf('day');
    }
    // The start date is fixed to applyDateRange[0], so the user should only pick an end date >= that date
    return current && current < applyDateRange[0].startOf('day');
  };

  return (
    <Modal onCancel={handleCancel} footer={null} centered style={{ borderRadius: '1em' }} destroyOnClose open={open} title={record ? 'Update Coupon' : 'Add Coupon'}>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" className="add-coupon-form" onFinish={handleSubmit}>
          <Form.Item
            name="code"
            label="Code"
            rules={[
              { required: true, message: 'Please enter coupon code', whitespace: true },
              { min: 6, message: 'Coupon code must be at least 6 characters' },
            ]}
          >
            <Input placeholder="Coupon Code" size="large" />
          </Form.Item>

          <Form.Item name="name" label="Description" rules={[{ required: true, message: 'Please enter description', whitespace: true }]}>
            <Input placeholder="Coupon Description" size="large" />
          </Form.Item>

          <Form.Item name="applyDateRange" label="Coupon Validity" rules={[{ required: true, message: 'Coupon Validity is required' }]}>
            <RangePicker onChange={handleApplyDateChange} disabledDate={(current) => current && current < dayjs().startOf('day')} format={['DD-MM-YYYY', 'DD-MM-YYYY']} />
          </Form.Item>

          <Form.Item name="validDateRange" label="Discount Validity" rules={[{ required: true, message: 'Discount Validity is required' }]}>
            <RangePicker
              disabled={[true, false]} // Disable changing the start date
              disabledDate={disabledValidDateRangeDate}
              format={['DD-MM-YYYY', 'DD-MM-YYYY']}
            />
          </Form.Item>

          <Form.Item
            name="discount_percentage"
            label="Discount Percentage"
            rules={[
              { required: true, message: 'Enter a discount value' },
              {
                type: 'number',
                min: 0,
                message: 'Discount should be greater than or equal to 0',
              },
              {
                type: 'number',
                max: 100,
                message: 'Discount should be less than or equal to 100',
              },
            ]}
          >
            <InputNumber
              placeholder="Enter percentage"
              style={{ width: '100%' }}
              size="large"
              onKeyPress={(event) => {
                if (!/^\d$/.test(event.key) && event.key !== '.' && event.key !== 'Escape') {
                  event.preventDefault(); // Block non-numeric keys except Escape
                }
              }}
            />
          </Form.Item>

          <Form.Item className="d-flex justify-content-center">
            <Button type="primary" htmlType="submit">
              {record ? 'Update Coupon' : 'Create Coupon'}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddCoupoun;

import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true }

export const updateCompleteDepreciationSlice = createSlice({
    name: 'updateDepreciation',
    initialState,
    reducers: {
        updateCompleteDepreciationSuccess: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        updateCompleteDepreciationFailure: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
            state.error = true;
        }
    }

})

export const { updateCompleteDepreciationSuccess, updateCompleteDepreciationFailure } = updateCompleteDepreciationSlice.actions;

export default updateCompleteDepreciationSlice.reducer;
import { deleteRequest, getRequest, postRequest } from '../../../utils/apiUtils';

export const getTemplateList = (type = null) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest('/admin/templates', { type });
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const editTemplate = (id, newValues) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`/admin/templates/${id}`, newValues);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const saveTemplate = (body) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest('/admin/templates', body);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const deleteTemplate = (id) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await deleteRequest(`/admin/templates/${id}`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addUserCoupon, changeCouponStatus, deleteUserCoupon, editUserCoupon, fetchUserCoupon } from '../../../actions/UserCoupon/UserCouponApi';

const initialState = {
  error: false,
  isLoading: false,
  data: [],
};

export const fetchUserCouponAsync = createAsyncThunk('Coupons/fetchCoupons', async (filters) => {
  const response = await fetchUserCoupon(filters);
  return response;
});

export const addUserCouponAsync = createAsyncThunk('Coupons/addCoupon', async (info) => {
  const response = await addUserCoupon(info);
  return response;
});

export const editUserCouponAsync = createAsyncThunk('Coupons/editCoupon', async ({ id, info }) => {
  const response = await editUserCoupon(id, info);
  return response;
});

export const changeCouponStatusAsync = createAsyncThunk('Coupons/changeCouponStatus', async ({ id, info }) => {
  const response = await changeCouponStatus(id, info);
  return response;
});

export const deleteUserCouponAsync = createAsyncThunk('Coupons/deleteCoupon', async (id) => {
  const response = await deleteUserCoupon(id);
  return response;
});

export const UserCouponSlice = createSlice({
  name: 'User Coupon',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserCouponAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserCouponAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.data = action.payload.data;
      })
      .addCase(fetchUserCouponAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(addUserCouponAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addUserCouponAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.data = action.payload.data.result;
      })
      .addCase(addUserCouponAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(editUserCouponAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editUserCouponAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
      })
      .addCase(editUserCouponAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(changeCouponStatusAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeCouponStatusAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
      })
      .addCase(changeCouponStatusAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(deleteUserCouponAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUserCouponAsync.fulfilled, (state, action) => {
        state.error = false;
        state.isLoading = false;
      })
      .addCase(deleteUserCouponAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectUserCouponLoading = (state) => state.userCoupon.isLoading;
export const selectUserCoupon = (state) => state.userCoupon.data;

export default UserCouponSlice.reducer;

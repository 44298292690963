import { postRequest } from '../../../utils/apiUtils';
import { getUsersListFailure, getUsersListSuccess } from '../../reducers/slices/Users/getUsersSlice';

export const GetUsersList = (data) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`admin/users`, data);
    if (!error) {
      return dispatch(getUsersListSuccess(result));
    }
    return dispatch(getUsersListFailure(result));
  };
};

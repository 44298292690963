import { createSlice } from "@reduxjs/toolkit";

const initialState = { depreciationData: [], error: false, isLoading: true };

export const depreciationSlice = createSlice({
    name: "depreciation",
    initialState,
    reducers: {
        AllDepreciationSuccess: (state, action) => {
            state.depreciationData = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        AllDepreciationFailure: (state, action) => {
            state.depreciationData = action.payload;
            state.error = true;
            state.isLoading = false;
        }
    }

})

export const { AllDepreciationSuccess, AllDepreciationFailure  } = depreciationSlice.actions;

export default depreciationSlice.reducer;
import { postRequest } from '../../../utils/apiUtils';
import { GetChannelPartnerListFailure, GetChannelPartnerListSuccess } from '../../reducers/slices/ChannelPartner/getChannelPartnerSlice';
import { addChannelPartnerDataRequest, addChannelPartnerDataSuccess, addChannelPartnerDataFailure } from '../../reducers/slices/ChannelPartner/postChannelPartnerData.slice';

export const GetChannelPartnerList = (data) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`admin/channel-partner`, data);
    if (!error) {
      return dispatch(GetChannelPartnerListSuccess(result));
    }
    return dispatch(GetChannelPartnerListFailure(result));
  };
};

export const AddChannelPartnerData = (data) => {
  return async (dispatch) => {
    await dispatch(addChannelPartnerDataRequest());
    const { result, error } = await postRequest(`admin/add-channel-partner`, data);

    if (!error) {
      return await dispatch(addChannelPartnerDataSuccess(result));
    }
    return await dispatch(addChannelPartnerDataFailure(error));
  };
};

export const ApproveMortgageBroker = (id) => {
  return async () => {
    const { result, error } = await postRequest(`admin/channel-partner/mortgage-broker/approve`, { id: id });
    return result || error;
  };
};

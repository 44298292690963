import { postRequest } from '../../../utils/apiUtils';
import { getUserActivitiesListFailure, getUserActivitiesListSuccess } from '../../reducers/slices/UserActivities/getUserActivitiesSlice';

export const GetUserActivitiesList = (data) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`admin/activity`, data);
    if (!error) {
      return dispatch(getUserActivitiesListSuccess(result));
    }
    return dispatch(getUserActivitiesListFailure(result));
  };
};

import { deleteRequest, getRequest, postRequest, putRequest } from '../../../utils/apiUtils';

export const saveAndUpdateFinancialYearRentSummary = (id, body) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await putRequest(`/admin/fy-rent?userId=${id}`, body);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const getFinancialYearEntryData = (id) => {
  return new Promise(async (resolve) => {
    const { result, error } = await getRequest(`/admin/fy-rent/${id}`);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      resolve({ data, error });
    }
  });
};

export const getFinancialYearRentSummaryPendingList = (filters) => {
  return new Promise(async (resolve) => {
    const { result, error } = await postRequest('/admin/fy-rent/pending', filters);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      resolve({ data, error });
    }
  });
};

export const getFinancialYearRentSummaryCompletedList = (body) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest('/admin/fy-rent/complete', body);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const deleteFinancialYearRentSummary = (id) => {
  return new Promise(async (resolve) => {
    const { result, error } = await deleteRequest(`/admin/fy-rent/${id}`);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      resolve({ data, error });
    }
  });
};

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Form, List, notification, Popconfirm } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addCommentsListAsync, deleteCommentsListAsync, getCommentsListAsync, getUsersCommentsList, getUsersCommentsLoading } from '../../container/reducers/slices/Users/getUsersCommentsSlice';
import { getLocalStorageItems } from '../../utils/utilities';

const getAbbreviation = (str) =>
  str
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase();

const CommentList = ({ comments, loading, handleDelete, user_email }) => (
  <List
    loading={loading}
    dataSource={comments}
    itemLayout="horizontal"
    renderItem={(item) => (
      <List.Item key={item.id}>
        <div className="d-flex justify-content-between">
          <List.Item.Meta avatar={<Avatar>{getAbbreviation(item.name)} </Avatar>} title={item.name} description={dayjs(item.created_at).format('DD-MM-YYYY hh:mm a')} />
          {item.email === user_email && (
            <Popconfirm key="list-delete" title="Are you sure you want to delete this comment?" onConfirm={() => handleDelete(item.id)} okText="Yes" cancelText="No">
              <span role="button" className="text-danger">
                Delete
              </span>
            </Popconfirm>
          )}
        </div>
        {item.message}
      </List.Item>
    )}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Add Comment
      </Button>
    </Form.Item>
  </>
);

export default function Comments() {
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const [email, setEmail] = useState('');

  const { id } = useParams();

  const dispatch = useDispatch();

  const commentsList = useSelector(getUsersCommentsList);
  const loading = useSelector(getUsersCommentsLoading);

  useEffect(() => {
    const { email } = getLocalStorageItems({ getAll: true, keys: ['email'] });
    setEmail(email);
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getCommentsListAsync(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    setComments(commentsList);
  }, [commentsList]);

  const handleSubmit = async () => {
    if (!value) return;
    setSubmitting(true);

    try {
      const res = await dispatch(addCommentsListAsync({ userId: id, message: value }));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(getCommentsListAsync(id));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
    setValue('');
    setSubmitting(false);
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleDelete = async (comment_id) => {
    try {
      const res = await dispatch(deleteCommentsListAsync({ id: comment_id }));
      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        await dispatch(getCommentsListAsync(id));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  return (
    <>
      {comments.length > 0 && (
        <>
          <CommentList comments={comments} handleDelete={handleDelete} loading={loading} user_email={email} />
          <Divider dashed />
        </>
      )}
      <List
        dataSource={[{}]}
        itemLayout="horizontal"
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta avatar={<Avatar size="large" icon={<UserOutlined />} />} description={<Editor onChange={handleChange} onSubmit={handleSubmit} submitting={submitting} value={value} />} />
          </List.Item>
        )}
      />
    </>
  );
}

import { getRequest, postRequest } from '../../../utils/apiUtils';

export const getSubscriptionHistory = (filters) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`admin/subscription-history`, filters);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const GetInvoiceReceipt = async (id, userId) => {
  const tempURl = `admin/subscription/receipt/${id}?userId=${userId}`;

  const result = await getRequest(tempURl);

  return result;
};

import { postRequest } from '../../../utils/apiUtils';
import { verifyAdminFailure, verifyAdminRequest, verifyAdminSuccess } from '../../reducers/slices/verifySlice';

export const verifyAdmin = (data) => {
  return async (dispatch) => {
    await dispatch(verifyAdminRequest());
    const { result, error } = await postRequest(`/auth/verify-token`, data);

    if (!error) {
      return await dispatch(verifyAdminSuccess(result));
    }
    return await dispatch(verifyAdminFailure(error));
  };
};

import { deleteRequest } from '../../../utils/apiUtils';
import { DeleteSettlementListFailure, DeleteSettlementListRequest, DeleteSettlementListSuccess } from '../../reducers/slices/RentSummary/deleteRentalSummarylist.Slice';
import { TransactionType } from '../../../components/common/userTypes';

export const DeleteSettlementList = (id, id1, status = null, type = TransactionType.RENT_TYPE_TRANSACTION) => {
  let tempURl = ``;
  if (!id1) {
    tempURl = `user/rent-summary/${id}`;
  } else {
    if (status === false) {
      tempURl = `user/rent-summary/${id}?userId=${id1}`;
    } else {
      tempURl = `user/transaction/delete/${id}?type=${type}&userId=${id1}`;
    }
  }
  return async (dispatch) => {
    await dispatch(DeleteSettlementListRequest());
    const { result, error } = await deleteRequest(tempURl);

    if (!error) {
      return await dispatch(DeleteSettlementListSuccess(result));
    }
    return await dispatch(DeleteSettlementListFailure(error));
  };
};

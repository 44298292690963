import { getRequest, putRequest } from '../../../utils/apiUtils';

export const getCategories = () => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await getRequest(`admin/categories`);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

export const updateCategories = (id, keywords) => {
  return new Promise(async (resolve, reject) => {
    const { result, error } = await putRequest(`admin/categories/${id}`, keywords);
    const data = result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const addSettlementSlice = createSlice({
    name: "addSettlement",
    initialState,
    reducers: {
        addSettlementSuccess: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        addSettlementFailure: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
            state.error = true;
        }
    },
});

export const { addSettlementSuccess, addSettlementFailure } = addSettlementSlice.actions

export default addSettlementSlice.reducer

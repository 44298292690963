import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    propertyListDataByUser: [],
    error: false,
    loadingPropertyListDataByUser: true,
};

const propertyListByUserSlice = createSlice({
    name: "propertyListByUser",
    initialState,
    reducers: {
        PropertyListByUserSuccess: (state, action) => {
            state.propertyListDataByUser = action.payload
            state.loadingPropertyListDataByUser = false;
        },
        PropertyListByUserFailure: (state, action) => {
            state.propertyListDataByUser = action.payload
            state.loadingPropertyListDataByUser = false;
            state.error = true;
        },
        ResetPropertyListByUser: () => initialState

    }
});

export const { PropertyListByUserSuccess, PropertyListByUserFailure, ResetPropertyListByUser } = propertyListByUserSlice.actions


export default propertyListByUserSlice.reducer;

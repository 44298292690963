import { Drawer } from 'antd';
import React from 'react';
import Comments from './Comments';

export default function CommentsModal({ openComment, setOpenComment }) {
  return (
    <Drawer destroyOnClose title="Admin Comments" placement="right" onClose={() => setOpenComment(false)} open={openComment} width={700}>
      <Comments />
    </Drawer>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const editRecordExpenseSlice = createSlice({
    name: "EditRecordExpense",
    initialState,
    reducers: {
        editRecordExpenseRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        editRecordExpenseSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        editRecordExpenseFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { editRecordExpenseRequest, editRecordExpenseSuccess, editRecordExpenseFailure } =
    editRecordExpenseSlice.actions;

export default editRecordExpenseSlice.reducer;

import { postRequest } from '../../../utils/apiUtils';
import { getLoansListFailure, getLoansListSuccess } from '../../reducers/slices/Loans/getLoansSlice';

export const GetLoansList = (data) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`admin/account-refresh`, data);
    if (!error) {
      return dispatch(getLoansListSuccess(result));
    }
    return dispatch(getLoansListFailure(result));
  };
};

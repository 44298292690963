import { DatePicker, Form, Input, Spin, Table, notification, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { ReloadOutlined } from '@ant-design/icons';
import { GetUserActivitiesList } from '../../container/actions/UserActivities/getUsers.action';
import { UserTypes } from '../../components/common/userTypes';
import { userActivityTypesOptions } from './activity-options';

function getLabelByValue(value) {
  const activity = userActivityTypesOptions.find((option) => option.value === value);
  return activity ? activity.label : 'Unknown Activity';
}

const UserActivities = () => {
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    userSearchSlug: '',
    propertySearchSlug: '',
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    dispatch(GetUserActivitiesList({ ...filter, skip: (Number(pagination.current) - 1) * pagination.pageSize, limit: pagination.pageSize })).then((res) => {
      if (res?.payload?.success === true) {
        setUsersData(res.payload.result.data);
        setTotal(res.payload.result.total);
        setLoading(false);
      } else {
        notification.error({
          message: 'Failure',
          description: 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  }, [filter, dispatch, pagination]);

  const startDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.endDate) < dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greter then End Date.',
      });
      filterForm.setFieldsValue({ startDate: '' });
      return;
    }
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, startDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const endDateChange = (value) => {
    if (value && dayjs(value).isValid && dayjs(filter.startDate) > dayjs(value)) {
      notification.error({
        message: 'Failure',
        description: 'Start Date should be greter then End Date.',
      });
      filterForm.setFieldsValue({ endDate: '' });
      return;
    }
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, endDate: value && value !== null ? dayjs(value).format('YYYY-MM-DD') : '' });
  };

  const UserSearchSlug = debounce((e) => {
    const values = e.target.value.trim();
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, userSearchSlug: values });
  }, 600);

  const PropertySearchSlug = debounce((e) => {
    const values = e.target.value.trim();
    setPagination({ ...pagination, current: 1 });
    setFilter({ ...filter, propertySearchSlug: values });
  }, 600);

  const handleView = (record) => {
    navigate(`/user/property-investor/detail/${record.user_id}`);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const propertyInvesterColumn = [
    {
      title: 'Property Investor Name',
      dataIndex: 'user_name',
      key: 'user_name',
      render: (_, record) => (
        <span className="userColumnName" onClick={() => handleView(record)}>
          {record?.user_name}
        </span>
      ),
    },
    {
      title: 'Activity Type',
      dataIndex: 'activity_type',
      key: 'activity_type',
      render: (text) => getLabelByValue(text),
    },
    {
      title: 'Activity Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_, record) => dayjs(record.created_at).format('DD/MM/YYYY hh:mm A'),
    },
    {
      title: 'Activity Title',
      dataIndex: 'activity_tital',
      key: 'activity_tital',
      render: (text) => text || '-',
    },
    {
      title: 'Activity By',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (record?.user_type === UserTypes.ADMIN ? 'Automated Entry' : record?.name),
    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
      key: 'user_type',
      render: (_, record) => {
        switch (record?.user_type) {
          case UserTypes.USER:
            return 'Property Investor';
          case UserTypes.TAXACCOUTANT:
            return 'Tax Accountant';
          case UserTypes.ADMIN_SUB_TAXACCOUNTANT:
            return 'Admin Sub Tax Accountant';
          case UserTypes.SUB_TAXACCOUTANT:
            return 'Sub Tax Accountant';
          default:
            return 'Admin';
        }
      },
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilter({
      startDate: '',
      endDate: '',
      userSearchSlug: '',
      propertySearchSlug: '',
    });
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row  justify-content-between">
        <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
          <h5 className="m-0">User Activities</h5>
        </div>
        <div className="date_picker reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex  loans-header-responsive justify-content-between align-items-center gap-3">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={startDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="Start Date" />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker format="DD-MM-YYYY" onChange={endDateChange} style={{ width: '100%', background: 'transparent' }} placeholder="End Date" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search User" className="m-0">
                    <Input size="large" allowClear onChange={(e) => UserSearchSlug(e)} style={{ width: '100%', background: 'transparent' }} placeholder="Search User" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="Search Property" className="m-0">
                    <Input size="large" allowClear onChange={(e) => PropertySearchSlug(e)} style={{ width: '100%', background: 'transparent' }} placeholder="Search Property" />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0 ">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={() => resetFilter()}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="dashboard_list">
              <Table columns={propertyInvesterColumn} pagination={{ ...pagination, total: total }} dataSource={usersData} scroll={{ x: 'max-content' }} onChange={handleTableChange} />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default UserActivities;

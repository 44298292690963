import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const verifyAdminSlice = createSlice({
    name: "VerifyUser",
    initialState,
    reducers: {
        verifyAdminRequest: (state, action) => {
            state.data = [];
            state.isLoading = true;
        },
        verifyAdminSuccess: (state, action) => {
            state.data = action.payload;
            state.error = false;
            state.isLoading = false;
        },
        verifyAdminFailure: (state, action) => {
            state.data = action.payload;
            state.error = true;
            state.isLoading = false;
        },
    },
});

export const { verifyAdminRequest, verifyAdminSuccess, verifyAdminFailure } = verifyAdminSlice.actions;

export default verifyAdminSlice.reducer;

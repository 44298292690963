import { useMemo } from "react"
import { Navigate } from "react-router-dom"
import { isValidStoredKey } from "../utils/utilities"

const ProtectedRoutes = ({ children }) => {
    // const { pathname } = useLocation()

    const isAuthenticated = useMemo(() => {
        return isValidStoredKey(['accessToken'])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children])
    return isAuthenticated ? children : <Navigate to={`/login`} replace={true} />
}

export default ProtectedRoutes
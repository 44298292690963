import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetInvoiceReceipt, getSubscriptionHistory } from '../../../actions/Subscription/SubscriptionHistoryApi';

const initialState = {
  error: false,
  isLoading: false,
  data: [],
};

export const getSubscriptionHistoryAsync = createAsyncThunk('Subscription History/getHistory', async (filters) => {
  const response = await getSubscriptionHistory(filters);
  return response;
});

export const SubscriptionHistorySlice = createSlice({
  name: 'Subscription History',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionHistoryAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubscriptionHistoryAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.data = action.payload.data;
      })
      .addCase(getSubscriptionHistoryAsync.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectSubscriptionHistoryLoading = (state) => state.subscriptionHistory.isLoading;
export const selectSubscriptionHistory = (state) => state.subscriptionHistory.data.result;

export default SubscriptionHistorySlice.reducer;

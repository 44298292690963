import { Badge, Button, Input, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PencilIcon } from '../../assets/icons';
import { GetCompleteDepreciation } from '../../container/actions/depreciation/getCompleteDepriciation.action';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';

const DepreciationCompleted = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const { depreciationDatacomplete, isLoading } = useSelector((state) => state.depreciationCompleteDataReducer);

  useEffect(() => {
    const savedSearch = JSON.parse(localStorage.getItem('depreciationCompleteFilter'));
    if (savedSearch) {
      setSearchText(savedSearch);
    }
  }, []);

  useEffect(() => {
    dispatch(GetCompleteDepreciation());
  }, [dispatch]);

  useEffect(() => {
    if (depreciationDatacomplete && depreciationDatacomplete.result && Array.isArray(depreciationDatacomplete.result)) {
      let data = depreciationDatacomplete.result;
      if (searchText !== '') {
        data = data.filter((item) => {
          const email = item?.email?.toLowerCase() || '';
          const vemail = item?.virtual_email?.toLowerCase() || '';
          return email.includes(searchText.toLowerCase()) || vemail.includes(searchText.toLowerCase());
        });
      }
      setFilteredData(data);
    } else {
      setFilteredData([]);
    }
  }, [depreciationDatacomplete, searchText]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value === '') {
      localStorage.removeItem('depreciationCompleteFilter');
    }
  };

  const storeData = () => {
    localStorage.setItem('depreciationCompleteFilter', JSON.stringify(searchText));
  };

  const tableColumnscompleted = [
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => record.email,
    },
    {
      title: 'User Unique ID',
      dataIndex: 'virtual_email',
      key: 'virtual_email',
      render: (_, record) => record.virtual_email,
    },
  ];

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Street Number',
        dataIndex: 'street_number',
        key: 'street_number',
        width: '20%',
      },
      {
        title: 'Street Name',
        dataIndex: 'street_name',
        key: 'street_name',
        width: '20%',
      },
      {
        title: 'Suburb',
        dataIndex: 'suburb',
        key: 'suburb',
        width: '20%',
      },
      {
        title: 'Property Type',
        dataIndex: 'property_type',
        key: 'property_type',
        width: '15%',
        render: (text) => getPropertyTypeLabel(text),
      },
      {
        title: 'Status',
        key: 'status',
        width: '20%',
        render: (_, record) => (record.depreciation === null ? <Badge status="warning" text="Pending" /> : <Badge status="success" text="Completed" />),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '20%',
        render: (_, record) => {
          return record.depreciation === null ? (
            '-'
          ) : (
            <button className="btn btn-primary px-0 mt-xxl-0 mt-2" onClick={storeData}>
              <Link to={`/depreciation/complete/${record?.depreciation?.id}`}>
                <PencilIcon className="img-fluid" />
              </Link>
            </button>
          );
        },
      },
    ];

    return <Table columns={columns} dataSource={record.properties} pagination={false} className="nestaed_tabel" rowKey={'id'} />;
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-xl-6 col-md-6 col-sm-6 col-12">
          <h5 className="m-0">Depreciation Completed</h5>
        </div>
        <div className="col-xl-6 col-md-6 col-sm-6 col-12 text-end custom-search-parent">
          <Input.Search placeholder="Search by Email, Unique ID" allowClear value={searchText} size="middle" onChange={handleSearchChange} className="custom-search" />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table
              columns={tableColumnscompleted}
              loading={isLoading}
              expandable={{
                expandedRowRender,
              }}
              rowKey={'id'}
              dataSource={filteredData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DepreciationCompleted;

import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Drawer, Form, Input, InputNumber, notification, Radio, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { editUserDiscountAsync, fetchUserDiscountAsync, selectUserDiscountLoading, setUserDiscountAsync } from '../../container/reducers/slices/UserDiscount/UserDiscountSlice';
import { DiscountType } from '../../components/common/userTypes';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const AddUserDiscount = ({ id = null, openAdd = false, setOpenAdd = () => {}, record = null, setRecord = () => {} }) => {
  const [discountType, setDiscountType] = useState('percentage');
  const [dates, setDates] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const loading = useSelector(selectUserDiscountLoading);

  const handleClose = () => {
    setOpenAdd(false);
    form.resetFields();
    setDates([]);
    setRecord(null);
    setDiscountType('percentage');
  };

  useEffect(() => {
    if (record) {
      const a = record.discount_type === DiscountType.AMOUNT ? 'flat' : 'percentage';
      setDiscountType(a);
      form.setFieldsValue({
        code: record.code,
        discountType: a,
        name: record.name,
        applyDateRange: [dayjs(record.apply_from), dayjs(record.apply_to)],
      });

      if (record.discount_type === DiscountType.PERCENTAGE) {
        form.setFieldsValue({ discount_percentage: record.discount_percentage });
        form.setFieldsValue({ validDateRange: [dayjs(record.valid_from), dayjs(record.valid_to)] });
      }

      if (record.discount_type === DiscountType.AMOUNT) {
        form.setFieldsValue({ discount_amount: record.discount_amount });
      }
    }
  }, [record, form]);

  const handleSubmit = async (values) => {
    try {
      const { validDateRange, name, applyDateRange, discount_percentage, discount_amount, discountType } = values;
      const discount_type = discountType === 'percentage' ? DiscountType.PERCENTAGE : DiscountType.AMOUNT;

      const apply_from = applyDateRange[0];
      const apply_to = applyDateRange[1];

      // Check that "Apply To" date is not more than one month after "Apply From" date
      const monthsDiff = apply_to.diff(apply_from, 'month', true);
      if (monthsDiff > 1 && discount_type !== DiscountType.PERCENTAGE) {
        form.setFields([
          {
            name: 'applyDateRange',
            errors: ['"Apply To" date must not be more than one month after the "Apply From" date'],
          },
        ]);
        return; // Stop execution if validation fails
      }

      const apply_from_formatted = apply_from.format('YYYY-MM-DD');
      const apply_to_formatted = apply_to.format('YYYY-MM-DD');

      const valid_from = discountType !== 'flat' ? validDateRange[0].format('YYYY-MM-DD') : apply_from_formatted;
      const valid_to = discountType !== 'flat' ? validDateRange[1].format('YYYY-MM-DD') : apply_to_formatted;

      const info = {
        valid_from,
        valid_to,
        apply_from: apply_from_formatted,
        apply_to: apply_to_formatted,
        name,
        discount_type,
        discount_amount: discount_type === DiscountType.AMOUNT ? discount_amount : null,
        discount_percentage: discount_type === DiscountType.PERCENTAGE ? discount_percentage : null,
      };

      let res;
      if (record) {
        res = await dispatch(editUserDiscountAsync({ userId: id, id: record.id, info }));
      } else {
        res = await dispatch(setUserDiscountAsync({ id, info }));
      }

      if (res?.payload?.data?.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload?.data?.message,
        });
        handleClose(); // Close the drawer and reset the form
        await dispatch(fetchUserDiscountAsync(id));
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload?.data?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Something went wrong!',
      });
    }
  };

  const disabledDate = (current) => {
    // Disable dates before today
    if (current && current < dayjs().startOf('day')) {
      return true;
    }

    // If start date is selected, disable dates more than one month after the start date
    if (dates && dates[0] && discountType !== 'percentage') {
      const startDate = dates[0];
      const oneMonthAfterStart = startDate.clone().add(1, 'month');
      if (current && current > oneMonthAfterStart.endOf('day')) {
        return true;
      }
    }

    return false;
  };

  const handleApplyDateChange = (dates) => {
    form.resetFields(['validDateRange']);
    if (dates && dates.length === 2) {
      // Set the validDateRange start date to the same as applyDateRange start date
      const startDate = dates[0];
      const currentValidRange = form.getFieldValue('validDateRange') || [];
      const endDate = currentValidRange[1] && currentValidRange[1].isAfter(startDate) ? currentValidRange[1] : null;

      form.setFieldsValue({
        validDateRange: [startDate, endDate],
      });
    } else {
      // If applyDateRange is cleared, also clear validDateRange
      form.setFieldsValue({
        validDateRange: [],
      });
    }
  };

  const disableValidDate = (current) => {
    const applyDateRange = form.getFieldValue('applyDateRange');
    if (!applyDateRange || !applyDateRange[0]) {
      // If no apply date range yet, disable past dates
      return current && current < dayjs().startOf('day');
    }
    // The start date is fixed to applyDateRange[0], so the user should only pick an end date >= that date
    return current && current < applyDateRange[0].startOf('day');
  };

  return (
    <Drawer destroyOnClose title="Subscription Discount" placement="right" onClose={handleClose} open={openAdd} width={650}>
      <Spin spinning={loading}>
        <Form className="add-coupon-form" form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="discountType" label="Discount Type" initialValue={'percentage'}>
            <Radio.Group
              onChange={(e) => {
                setDiscountType(e.target.value);
                form.setFieldsValue({ discount_percentage: null, discount_amount: null });
                form.setFields([
                  {
                    name: 'applyDateRange',
                    errors: null,
                  },
                ]);
              }}
              value={discountType}
            >
              <Radio value="percentage">Percentage</Radio>
              <Radio value="flat">Flat Amount</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="name"
            label="Description"
            rules={[
              { required: true, message: 'Please enter description', whitespace: true },
              {
                min: 6,
                message: 'Name should be at least 6 characters long',
              },
            ]}
          >
            <Input placeholder="Description" size="large" />
          </Form.Item>

          {discountType === 'percentage' && (
            <Form.Item
              name="discount_percentage"
              label="Discount Percentage"
              rules={[
                { required: true, message: 'Enter a discount value' },
                {
                  min: 0,
                  type: 'number',
                  message: 'Discount should be greater than or equal to 0',
                },
                {
                  max: 100,
                  type: 'number',
                  message: 'Discount should be less than or equal to 100',
                },
              ]}
            >
              <InputNumber
                placeholder="Enter percentage"
                style={{ width: '100%' }}
                size="large"
                onKeyPress={(event) => {
                  if (!/^\d$/.test(event.key) && event.key !== '.' && event.key !== 'Escape') {
                    event.preventDefault(); // Block non-numeric keys except Escape
                  }
                }}
              />
            </Form.Item>
          )}
          {discountType === 'flat' && (
            <Form.Item
              name="discount_amount"
              label="Flat Discount Amount"
              rules={[
                { required: true, message: 'Enter a discount value' },
                {
                  min: 0,
                  type: 'number',
                  message: 'Amount should be greater than or equal to 0',
                },
              ]}
            >
              <InputNumber
                onKeyPress={(event) => {
                  if (!/^\d$/.test(event.key) && event.key !== '.' && event.key !== 'Escape') {
                    event.preventDefault(); // Block non-numeric keys except Escape
                  }
                }}
                placeholder="Enter amount"
                style={{ width: '100%' }}
                size="large"
              />
            </Form.Item>
          )}

          <Form.Item name="applyDateRange" label="Coupon Validity" rules={[{ required: true, message: 'Coupon Validity is required' }]}>
            <RangePicker format="DD-MM-YYYY" disabledDate={disabledDate} onChange={handleApplyDateChange} onCalendarChange={(val) => setDates(val)} />
          </Form.Item>

          {discountType !== 'flat' && (
            <Form.Item name="validDateRange" label="Discount Validity" rules={[{ required: true, message: 'Discount Validity is required' }]}>
              <RangePicker disabledDate={disableValidDate} format={['DD-MM-YYYY', 'DD-MM-YYYY']} disabled={[true, false]} />
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {record ? 'Update Discount' : 'Create Discount'}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default AddUserDiscount;

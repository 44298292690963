import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { approveApprovalRequest, disableUserMFA, getUserDetails, rejectApprovalRequest } from '../../../actions/UserOverivew/UserOverviewApi';

const initialState = {
  error: false,
  isLoading: false,
  userDetails: {},
};

export const getUserDetailsAsync = createAsyncThunk('UserOverview/getUserDetails', async (id) => {
  const response = await getUserDetails(id);
  return response;
});

export const approveApprovalRequestAsync = createAsyncThunk('UserOverview/approveApprovalRequest', async (id) => {
  const response = await approveApprovalRequest(id);
  return response;
});

export const rejectApprovalRequestAsync = createAsyncThunk('UserOverview/rejectApprovalRequest', async (id) => {
  const response = await rejectApprovalRequest(id);
  return response;
});

export const disableUserMFAAsync = createAsyncThunk('UserOverview/disableUserMFA', async (id) => {
  const response = await disableUserMFA(id);
  return response;
});

export const UserOverviewSlice = createSlice({
  name: 'UserOverview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getUserDetailsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserDetailsAsync.fulfilled, (state, action) => {
        state.userDetails = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getUserDetailsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(approveApprovalRequestAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(approveApprovalRequestAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(approveApprovalRequestAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(rejectApprovalRequestAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(rejectApprovalRequestAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(rejectApprovalRequestAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(disableUserMFAAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(disableUserMFAAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(disableUserMFAAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectUserDetails = (state) => state.userDetails.userDetails;
export const selectUserDetailsLoading = (state) => state.userDetails.isLoading;

export default UserOverviewSlice.reducer;

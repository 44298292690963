import { Tabs } from 'antd';
import React from 'react';
import Email from './Email';
import SMS from './SMS';
import { useDispatch } from 'react-redux';
import { getTemplateListAsync } from '../../container/reducers/slices/Notification/NotificationTemplateSlice';
import PushNotification from './PushNotification';
import { NotificationType } from '../../components/common/userTypes';

const Templates = () => {
  const dispatch = useDispatch();

  const onChange = (key) => {
    if (key === '1') {
      dispatch(getTemplateListAsync(NotificationType.EMAIL));
    } else if (key === '2') {
      dispatch(getTemplateListAsync(NotificationType.SMS));
    } else if (key === '3') {
      dispatch(getTemplateListAsync(NotificationType.PUSH_NOTIFICATION));
    }
  };
  const items = [
    {
      key: '1',
      label: 'Email',
      children: <Email />,
    },
    {
      key: '2',
      label: 'SMS',
      children: <SMS />,
    },
    {
      key: '3',
      label: 'Push Notification',
      children: <PushNotification />,
    },
  ];

  return (
    <div className="bg-white p-4">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  );
};

export default Templates;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteNotRegisteredUsers, getNotRegisteredUsers } from '../../../actions/NotRegisteredUsers/NotRegisteredUsersApi';

const initialState = {
  error: false,
  isLoading: true,
  data: [],
};

export const getNotRegisteredUsersAsync = createAsyncThunk('NotRegisteredUsers/getList', async (body) => {
  const response = await getNotRegisteredUsers(body);
  return response;
});

export const deleteNotRegisteredUsersAsync = createAsyncThunk('NotRegisteredUsers/deleteUser', async (id) => {
  const response = await deleteNotRegisteredUsers(id);
  return response;
});

export const NotRegisteredUsersSlice = createSlice({
  name: 'NotRegisteredUsers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotRegisteredUsersAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNotRegisteredUsersAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getNotRegisteredUsersAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(deleteNotRegisteredUsersAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteNotRegisteredUsersAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(deleteNotRegisteredUsersAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectNotRegisteredUsers = (state) => state.taxAccountantNotRegistered.data;
export const selectNotRegisteredUsersLoading = (state) => state.taxAccountantNotRegistered.isLoading;

export default NotRegisteredUsersSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getNotificationData } from '../../../actions/Notification/NotificationPreviewApi';

const initialState = {
  error: false,
  isLoading: true,
  data: {},
};

export const getNotificationDataAsync = createAsyncThunk('NotificationPreview/getNotificationData', async (id) => {
  const response = await getNotificationData(id);
  return response;
});

export const NotificationPreviewSlice = createSlice({
  name: 'NotificationPreview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationDataAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNotificationDataAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getNotificationDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});
export const selectNotificationData = (state) => state.notificationPreview.data;

export const selectNotificationDataLoading = (state) => state.notificationPreview.isLoading;

export default NotificationPreviewSlice.reducer;

import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, InputNumber, notification, Row, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getFinancialYearEntryDataAsync, saveAndUpdateFinancialYearRentSummaryAsync, selectFYEntryData, selectFYLoading } from '../../container/reducers/slices/FYRentSummary/FYRentSummarySlice';
import { LeftOutlined } from '@ant-design/icons';
import { getYearList } from '../../utils/index';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';
import SmallLoader from '../../components/common/SmallLoader';

const EndOfYearSchedule = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { status, id } = useParams();
  const data = useSelector(selectFYEntryData);
  const loading = useSelector(selectFYLoading);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      const selectedProperty = data?.user?.properties?.find((item) => item.id === data?.property_id);
      form.setFieldsValue({
        financial_year: data.financial_year,
        property_id: selectedProperty ? selectedProperty.id : undefined,
        property_type: selectedProperty ? getPropertyTypeLabel(selectedProperty.property_type) : undefined,
        annual_rental_income: data.amount,
      });
    }
  }, [data, form]);

  const propertyOptions = useMemo(() => {
    return data?.user?.properties?.map((item) => ({
      key: item.id,
      value: item.id,
      label: `${item.street_number} - ${item.street_name}`,
      property_type: item.property_type,
    }));
  }, [data]);

  useEffect(() => {
    dispatch(getFinancialYearEntryDataAsync(id));
  }, [dispatch, id]);

  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const handleFormSubmit = async (values) => {
    setFormSubmitting(true);
    const body = {
      id: Number(id),
      amount: values.annual_rental_income,
      year: values.financial_year,
      propertyId: values.property_id,
    };
    await dispatch(saveAndUpdateFinancialYearRentSummaryAsync({ id: data.user_id, body })).then(async (response) => {
      if (response.type === 'FYRentSummary/saveAndUpdateFinancialYearRentSummary/fulfilled') {
        notification.success({
          title: 'Success',
          description: `End of financial year ${status === 'pending' ? 'saved' : 'updated'} successfully`,
        });
        await form.resetFields();
        navigate('/end-of-year/pending');
      }
    });
  };

  const handlePropertyChange = (property_id, option) => {
    form.setFieldsValue({
      property_type: getPropertyTypeLabel(option.property_type),
      property_id: property_id,
    });
  };

  if (loading) {
    return <SmallLoader />;
  }

  return (
    <>
      <Row>
        <Button className="btn_goBack" onClick={() => navigate(`/end-of-year/${status}`)} icon={<LeftOutlined />} />
        <h5 className="m-0">End Of Financial Year</h5>
      </Row>

      <div className="schedule_wrapper">
        <Spin spinning={isFormSubmitting}>
          <div className="row">
            <div className="col-xl-6 col-md-6 col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div id="Iframe-Master-CC-and-Rs" className="set-margin set-padding set-border set-box-shadow center-block-horiz">
                    <div className="responsive-wrapper responsive-wrapper-wxh-572x612 docFile">
                      <iframe title="Receipt" src={data?.receipt_path} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-md-6 col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <Form form={form} layout="vertical" name="form" onFinish={handleFormSubmit}>
                    <div>
                      <div>
                        <Form.Item
                          name="property_id"
                          label="Property"
                          rules={[
                            {
                              required: true,
                              message: 'Please Select Property',
                            },
                          ]}
                        >
                          <Select
                            onChange={handlePropertyChange}
                            options={propertyOptions}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item label="Property Type" name="property_type">
                          <Input disabled={true} />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          label="Financial Year"
                          name="financial_year"
                          required
                          rules={[
                            {
                              required: true,
                              message: 'Please Select Financial Year',
                            },
                          ]}
                        >
                          <Select maxTagCount="responsive" options={getYearList() || []} />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          label="Annual Rental Income"
                          name="annual_rental_income"
                          required
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Annual Rental Income',
                            },
                          ]}
                        >
                          <InputNumber min={0} className="w-100" autoComplete="off" />
                        </Form.Item>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Button type="primary" htmlType="submit" className="mt-3">
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default EndOfYearSchedule;

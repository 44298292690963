import { getRequest } from '../../../utils/apiUtils';
import { getBankListRequest, getBankListSuccess, getBankListFailure } from '../../reducers/slices/Loans/getBankList.slice';

export const GetBankList = () => {
  return async (dispatch) => {
    await dispatch(getBankListRequest());
    const { result, error } = await getRequest(`user/bank-list`);

    if (!error) {
      return await dispatch(getBankListSuccess(result));
    }
    return await dispatch(getBankListFailure(result));
  };
};

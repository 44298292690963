import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Popconfirm, Radio, Row, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteIcon } from '../../assets/icons';
import SmallLoader from '../../components/common/SmallLoader';
import { GetCategoryList } from '../../container/actions/settlenents/getCategoryList.action';
import { GetSettlementById } from '../../container/actions/settlenents/settlementById.action';
import { resetGetCategoryList } from '../../container/reducers/slices/settlement/getCategoryListSlice';
import { resetGetSettlementById } from '../../container/reducers/slices/settlement/settlementByIdSlice';
import { AddSettlement } from '../../container/actions/settlenents/addSettlement.action';
import { isValidObject, SendNotification } from '../../utils/utilities';
import GoBack from '../../components/common/GoBack';
import { DeleteSettlementList } from '../../container/actions/settlenents/deleteSettlement.action';
import { AllSettlements } from '../../container/actions/settlenents/allSettlements.action';
import dayjs from 'dayjs';
import { settlementType } from '../../components/common/userTypes';
import { UpdateSettlement } from '../../container/actions/settlenents/updateSettlement.action';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';
import { getLockYearsList } from '../../container/actions/AuditLock';
import { getErrorMessageForLockedYear, getFinancialYearRanges } from '../../utils';
import isBetween from 'dayjs/plugin/isBetween'; // load on demand
import { PlusOutlined } from '@ant-design/icons';
dayjs.extend(isBetween);

const text = 'Are you sure you want to discard?';

const SettlementDetails = () => {
  const [settlementForm] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { status } = useParams();

  const navigate = useNavigate();

  const [userSettlementData, setUserSettlementData] = useState([{}]);
  const [propertyId, setPropertyId] = useState('');
  const [chooseTrigger, setChooseTrigger] = useState(40);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryList1, setCategoryList1] = useState([]);

  const [incomeCategorydata, setIncomeCategorydata] = useState([]);
  const [expenseCategorydata, setExpenseCategorydata] = useState([]);
  const [costCategorydata, setCostCategorydata] = useState([]);

  const [datedayjs, setdatedayjs] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [financialYears, setFinancialYears] = useState([]);

  const settlementByIdData = useSelector((state) => state.getSettlementByIdReducer);
  const categoryListReducer = useSelector((state) => state.getCategoryListReducer);

  const propartyOptions = useMemo(
    () =>
      settlementByIdData?.data?.result?.user?.properties?.map((item) => {
        return { key: item.id, value: item.id, label: `${item.street_number} - ${item.street_name}`, property_type: item?.property_type };
      }),
    [settlementByIdData],
  );

  useEffect(() => {
    dispatch(GetSettlementById(id)).then(({ payload: { result } }) => {
      const userId = result?.user_id;
      getLockYears(userId);
      // dispatch(PropertySettlementListByUser(userId));
    });
    dispatch(GetCategoryList());

    return () => {
      dispatch(resetGetCategoryList());
      // dispatch(ResetPropertyListByUser())
      dispatch(resetGetSettlementById());
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (isValidObject(settlementByIdData)) {
      const {
        data: { result },
      } = settlementByIdData;
      setUserSettlementData(result);
    }
  }, [settlementByIdData]);

  useEffect(() => {
    if (categoryListReducer && categoryListReducer.data.result && categoryListReducer.error === false) {
      if (categoryListReducer.data.result) {
        let dt = categoryListReducer.data.result.map((elm) => {
          return {
            value: elm.id,
            label: elm.category_name,
            income: elm.expense_type === 'Revenue' && elm.transaction_type === 'In' ? true : false,
            expense: elm.expense_type === 'Revenue' && elm.transaction_type === 'Out' ? true : false,
            cost: elm.expense_type === 'Capital' ? true : false,
          };
        });
        setCategoryList(dt);
        let Incom = dt.filter((item) => ![false].includes(item.income));
        setIncomeCategorydata(Incom);
        let Expense = dt.filter((item) => ![false].includes(item.expense));
        setExpenseCategorydata(Expense);
        let Cost = dt.filter((item) => ![false].includes(item.cost));
        setCostCategorydata(Cost);
        let dtCat = categoryListReducer.data.result.filter((elm) => [19, 20, 3, 2, 21, 23].includes(elm.id));
        let dtFinal = dtCat.map((elm) => {
          return { category_id: elm.id, amount: '' };
        });
        setCategoryList1(dtFinal);
        settlementForm.setFieldsValue({ categories: dtFinal || [] });
      }
    }
  }, [categoryListReducer, settlementForm]);

  const getLockYears = (userId) => {
    dispatch(getLockYearsList(userId)).then((data) => {
      if (data.success) {
        setFinancialYears(data.result || []);
      }
    });
  };

  const validateDate = (date) => {
    const ranges = getFinancialYearRanges(financialYears);
    for (let range of ranges) {
      if (range.locked && date && date.isBetween(range.start, range.end, 'day', '[]')) {
        return getErrorMessageForLockedYear(date, range.locked_user_type, range.locked_by);
      }
    }
    return null;
  };

  const dateset = (dayjs) => {
    setdatedayjs(dayjs);
  };

  // Function to change the value of a property in an object within the array
  const changeValueOfObjectInArray = (array, objectId, propertyName, newValue) => {
    return array.map((obj) => (obj.category_id === objectId ? { ...obj, [propertyName]: newValue } : obj));
  };

  const settlementTypeChangeHandle = (value) => {
    let arrayOfObjects = settlementForm.getFieldValue('categories');
    if (settlementType.PURCHASE_SETTLEMENT === value) {
      arrayOfObjects = changeValueOfObjectInArray(arrayOfObjects, 32, 'category_id', 19);
    } else {
      arrayOfObjects = changeValueOfObjectInArray(arrayOfObjects, 19, 'category_id', 32);
    }
    settlementForm.setFieldsValue({ categories: arrayOfObjects || [] });
    setChooseTrigger(value);
  };

  const handlePropertyChange = (property_id, data) => {
    setPropertyId(property_id);
    settlementForm.setFieldsValue({
      address: getValidAddress(property_id),
    });
    settlementForm.setFieldsValue({
      property_type: getPropertyTypeLabel(data?.property_type),
    });
  };

  const handleFormSubmit = (data) => {
    setIsUpdating(true);

    if (userSettlementData?.property_id) {
      const arr = data.categories?.map((ele) => {
        return { amount: ele.amount, id: ele.id, category_id: ele.category_id };
      });
      let body1 = {
        expense_arr: arr,
        property_id: userSettlementData?.property_id,
        id: id,
        settlement_type: userSettlementData?.settlement_type === settlementType.SELL_SETTLEMENT ? settlementType.SELL_SETTLEMENT : settlementType.PURCHASE_SETTLEMENT,
        expense_date:
          Object.keys(datedayjs).length > 0
            ? dayjs(datedayjs).format('YYYY-MM-DD')
            : userSettlementData?.settlement_type === settlementType.PURCHASE_SETTLEMENT
            ? userSettlementData?.property?.purchase_settlement_date
            : userSettlementData?.property?.sell_settlement_date,
      };
      dispatch(UpdateSettlement(body1)).then(({ payload }) => {
        if (payload.success) {
          SendNotification({ type: 'success', message: 'Property settlement data updated' });
          navigate(`/property-settlement/${status}`);
        } else {
          SendNotification({ type: 'error', message: payload.message || 'Something went wrong' });
        }

        setIsUpdating(false);
      });
    } else {
      const arr = data.categories?.map((ele) => {
        return { amount: ele.amount, id: ele.id, category_id: ele.category_id };
      });
      let body = {
        expense_arr: arr,
        property_id: propertyId,
        id: id,
        settlement_type: chooseTrigger,
        expense_date: dayjs(datedayjs).format('YYYY-MM-DD'),
      };
      dispatch(AddSettlement(body)).then(({ payload }) => {
        if (payload.success) {
          SendNotification({ type: 'success', message: 'Property settlement data updated' });
          navigate(`/property-settlement/${status}`);
        } else {
          SendNotification({ type: 'error', message: payload.message || 'Something went wrong' });
        }

        setIsUpdating(false);
      });
    }
  };

  const discardRecord = (id) => {
    setIsUpdating(true);
    dispatch(DeleteSettlementList(id)).then((response) => {
      if (response?.payload?.success) {
        SendNotification({ type: 'success', message: response.payload.message || 'Property settlement deleted successfully' });
        dispatch(AllSettlements());
        navigate(`/property-settlement/${status}`, { replace: true });
        setIsUpdating(false);
      } else {
        SendNotification({ type: 'error', message: response?.payload?.message || 'Something went wrong' });
        setIsUpdating(false);
      }
    });
  };

  const getValidAddress = useCallback(
    (id) => {
      let data = settlementByIdData?.data?.result?.user?.properties.find((item) => item.id === id);
      const address = `${data?.street_number ? data?.street_number : ''} ${data?.street_name ? data?.street_name : ''} ${data?.suburb ? data?.suburb : ''} ${data?.postcode ? data?.postcode : ''}`;
      return address ? address : '';
    },
    [settlementByIdData],
  );

  useEffect(() => {
    settlementForm.setFieldsValue({
      user_email: userSettlementData?.user?.virtual_email,
      property_id: userSettlementData?.property_id ? `${userSettlementData?.property.street_number} - ${userSettlementData?.property.street_name}` : '',
      address: getValidAddress(userSettlementData?.Property_Id ? userSettlementData?.Property_Id : Number(settlementByIdData?.data?.result?.property_id)),
      categories: userSettlementData?.transactions?.length > 0 ? userSettlementData?.transactions : userSettlementData?.expense_arr?.length > 0 ? userSettlementData?.expense_arr : categoryList1,
      expense_date:
        userSettlementData?.settlement_type === settlementType.PURCHASE_SETTLEMENT
          ? dayjs(userSettlementData?.property?.purchase_settlement_date)
          : userSettlementData?.settlement_type === settlementType.SELL_SETTLEMENT
          ? dayjs(userSettlementData?.property?.sell_settlement_date)
          : '',
      settlement_type: userSettlementData?.settlement_type === settlementType.SELL_SETTLEMENT ? settlementType.SELL_SETTLEMENT : settlementType.PURCHASE_SETTLEMENT,
      property_type: getPropertyTypeLabel(userSettlementData?.property?.property_type),
    });
  }, [userSettlementData, settlementForm, categoryList1, settlementByIdData, getValidAddress]);

  if (settlementByIdData?.isLoading || categoryListReducer.isLoading) {
    return <SmallLoader />;
  }
  return (
    // minor chnages
    <>
      <Row>
        <GoBack />
        <h5 className="m-0">Settlement Statment</h5>
      </Row>

      <Spin spinning={isUpdating}>
        <div className="schedule_wrapper">
          <div className="row">
            <div className="col-xl-6 col-md-6 col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div id="Iframe-Master-CC-and-Rs" className="set-margin set-padding set-border set-box-shadow center-block-horiz">
                    <div className="responsive-wrapper responsive-wrapper-wxh-572x612 docFile">
                      <iframe title="Receipt" src={userSettlementData?.receipt_path}></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-md-6 col-12 mt-3">
              <div className="card">
                <Form form={settlementForm} layout="vertical" name="form" onFinish={handleFormSubmit}>
                  <div className="card-body pb-0">
                    <div className="row">
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-4">
                          <Form.Item name="user_email" label="User Unique Email">
                            <Input type="text" disabled placeholder="jane@thepropertyaccountant.com.au" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="col-xl-6 col-md-6 col-12 d-block">
                        <div className="">
                          <Form.Item
                            name="property_id"
                            label="Property"
                            rules={[
                              {
                                required: true,
                                message: 'Please Select Property',
                              },
                            ]}
                          >
                            <Select
                              disabled={userSettlementData?.property_id}
                              onChange={(e, data) => handlePropertyChange(e, data)}
                              options={propartyOptions || []}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                            ></Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <div className="mb-2">
                          <Form.Item label="Property Address" name="address">
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <Form.Item label="Property Type" name="property_type">
                          <Input className="form-control p-1 px-3" style={{ color: 'black' }} disabled={true} placeholder="property Type" type="text" />
                        </Form.Item>
                      </div>

                      <div className="col-xl-6 col-md-6 col-12 mb-4 mt-2">
                        <Form.Item
                          className="mb-0"
                          label={
                            <label htmlFor="schedule-address" className="form-label">
                              Settlement Type
                            </label>
                          }
                          name="settlement_type"
                          rules={[
                            {
                              required: true,
                              message: 'please select settlement type!',
                            },
                          ]}
                        >
                          <Radio.Group onChange={(e) => settlementTypeChangeHandle(e.target.value)} disabled={userSettlementData?.property_id ? true : false}>
                            <Radio value={settlementType.PURCHASE_SETTLEMENT}>Purchase Settlement</Radio>
                            <Radio value={settlementType.SELL_SETTLEMENT}>Sell Settlement</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className="col-xl-6 col-md-6 col-12">
                        <Form.Item
                          className="mb-0"
                          label={
                            <label htmlFor="schedule-address" className="">
                              Settlement Date
                            </label>
                          }
                          name="expense_date"
                          rules={[
                            {
                              required: true,
                              message: 'please enter settlement date!',
                            },
                            () => ({
                              validator(_, value) {
                                const errorMessage = validateDate(value);
                                if (!value || !errorMessage) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error(errorMessage));
                              },
                            }),
                          ]}
                        >
                          <DatePicker allowClear={false} format="DD-MM-YYYY" onChange={(dayjs) => dateset(dayjs)} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="statment_border"></div>

                  <div className="card-body expense_input">
                    <div className="row align-items-center">
                      <Form.List name="categories">
                        {(fields, { add, remove }) => (
                          <div className="col-md-12 p-0">
                            {fields.map(({ key, name }) => (
                              <Row gutter={24} key={crypto.randomUUID()}>
                                <Col span={12}>
                                  <Form.Item
                                    className="p-0 m-0"
                                    name={[name, 'category_id']}
                                    style={{ width: '100%' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please select Property type',
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Property Type"
                                      style={{
                                        width: '100%',
                                      }}
                                      defaultActiveFirstOption={true}
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                                      options={[
                                        {
                                          label: 'Income',
                                          options: incomeCategorydata,
                                        },
                                        {
                                          label: 'Expense',
                                          options: expenseCategorydata,
                                        },
                                        {
                                          label: 'Cost',
                                          options: costCategorydata,
                                        },
                                      ]}
                                    ></Select>
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <span></span>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className="p-0 m-0 mb-4"
                                    style={{ width: '100%' }}
                                    name={[name, 'amount']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please enter Amount',
                                      },
                                    ]}
                                  >
                                    <Input
                                      style={{
                                        width: '100%',
                                      }}
                                      inputtype="number"
                                      className="form-control"
                                      placeholder="Amount"
                                      allowdecimal="true"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  {fields.length > 1 ? (
                                    <Form.Item className="btn_delete p-0">
                                      <DeleteIcon
                                        onClick={async () => {
                                          remove(name);
                                        }}
                                      />
                                    </Form.Item>
                                  ) : null}
                                </Col>
                              </Row>
                            ))}
                            <div className="d-flex justify-content-center">
                              <div>
                                {categoryList.length === settlementForm.getFieldValue('categories').length ? (
                                  ''
                                ) : (
                                  <div>
                                    <Button className="d-flex align-items-center" type="primary" onClick={() => add()}>
                                      <PlusOutlined /> Add Category
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                              <div className="justify-content-end text-end">
                                <Popconfirm placement="left" title={text} onConfirm={() => discardRecord(id)} okText="Yes" cancelText="No">
                                  <Button type="primary" danger ghost>
                                    Discard
                                  </Button>
                                </Popconfirm>
                              </div>
                              <div>
                                <Button htmlType="submit" type="primary">
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Form.List>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default SettlementDetails;

export const onSuccess = (state, action) => {
    return {
        ...state,
        data : action.payload,
        isLoading : false,
        error : false,
    }
}

export const onFailure = (state, action) => {
    return {
        ...state,
        data : action.payload,
        isLoading : false,
        error : true
    }
}
import { postRequest } from '../../../utils/apiUtils';
import { addSettlementFailure, addSettlementSuccess } from '../../reducers/slices/settlement/addSettlementSlice';

export const AddSettlement = (data) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`/admin/add-settlement`, data);

    if (!error) {
      return dispatch(addSettlementSuccess(result));
    }
    return dispatch(addSettlementFailure(error));
  };
};

import { postRequest } from '../../../utils/apiUtils';
import { UpdateSettlementFailure, UpdateSettlementSuccess } from '../../reducers/slices/settlement/updateSettlementSlice';

export const UpdateSettlement = (data) => {
  return async (dispatch) => {
    const { result, error } = await postRequest(`/admin/update-settlement`, data);

    if (!error) {
      return dispatch(UpdateSettlementSuccess(result));
    }
    return dispatch(UpdateSettlementFailure(error));
  };
};

import React from 'react';
import { Button, Flex, notification } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import './BasiqMultipleAccounts.css';

const EmailDisplay = ({ email, expand, margin = true }) => {
  // const [expanded, setExpanded] = useState(false);

  // const toggleExpand = () => {
  //   setExpanded(!expanded);
  // };

  // const getEllipsisText = (text, length) => {
  //   if (text?.length <= length) {
  //     return text;
  //   }
  //   const half = Math.floor(length / 2);
  //   return `${text?.slice(0, half)}...${text?.slice(-half)}`;
  // };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    notification.success({
      message: 'Success',
      description: 'Copied.',
    });
  };

  return (
    <div>
      <Flex gap={`${!margin ? 'small' : 'middle'}`}>
        {/* <Flex gap="middle"> */}
        {/* <p>{expand ? (expanded ? email : getEllipsisText(email, 15)) : email}</p> */}
        <p className={`${!margin ? 'm-0 p-0' : ''}`}> {email} </p>

        {/* <Tooltip title={email}> */}
        <Button icon={<CopyOutlined />} size="small" onClick={() => copyToClipboard(email)} style={margin && { marginLeft: 8 }} />
        {/* </Tooltip> */}
        {/* {expand && <Button icon={expanded ? <ShrinkOutlined /> : <ExpandOutlined />} onClick={toggleExpand} style={{ marginLeft: 8 }} />} */}
      </Flex>
    </div>
  );
};

export default EmailDisplay;

EmailDisplay.defaultProps = {
  expand: false,
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = { settlementDatacomplete: [], error: false, isLoading: true };

export const getChannelPartnerListSlice = createSlice({
  name: 'ChannelPartnerList',
  initialState,
  reducers: {
    GetChannelPartnerListSuccess: (state, action) => {
      state.settlementDatacomplete = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    GetChannelPartnerListFailure: (state, action) => {
      state.settlementDatacomplete = action.payload;
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { GetChannelPartnerListSuccess, GetChannelPartnerListFailure } = getChannelPartnerListSlice.actions;

export default getChannelPartnerListSlice.reducer;

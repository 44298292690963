import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteUserAccount, deleteUserConsent, getUserBankDetails, updateBankDetails } from '../../../actions/BasiqMultipleAccounts/basiqBankAccountApi';

const initialState = {
  error: false,
  isLoading: true,
  bankDetails: [],
};

export const getUserBankDetailsAsync = createAsyncThunk('BasiqBankAccount/getUserBankDetails', async (id) => {
  const response = await getUserBankDetails(id);
  return response;
});

export const updateBankDetailsAsync = createAsyncThunk('BasiqBankAccount/updateBankDetails', async (payload) => {
  const response = await updateBankDetails(payload);
  return response;
});

export const deleteUserConsentAsync = createAsyncThunk('BasiqBankAccount/deleteUserConsent', async ({ bu_id, id }) => {
  const response = await deleteUserConsent(bu_id, id);
  return response;
});

export const deleteUserAccountAsync = createAsyncThunk('BasiqBankAccount/deleteUserAccount', async ({ bu_id, id }) => {
  const response = await deleteUserAccount(bu_id, id);
  return response;
});

export const BasiqBankAccountsSlice = createSlice({
  name: 'BasiqBankAccount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserBankDetailsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserBankDetailsAsync.fulfilled, (state, action) => {
        state.bankDetails = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getUserBankDetailsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(deleteUserConsentAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteUserConsentAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(deleteUserConsentAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(deleteUserAccountAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteUserAccountAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(deleteUserAccountAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(updateBankDetailsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBankDetailsAsync.fulfilled, (state, action) => {
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(updateBankDetailsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectUserBankDetails = (state) => state.basiqBankAccount.bankDetails;
export const selectBankDetailsLoading = (state) => state.basiqBankAccount.isLoading;

export default BasiqBankAccountsSlice.reducer;
